(function() {
  
  const t = document.createElement("link").relList;
  if (t && t.supports && t.supports("modulepreload"))
      return;
  for (const s of document.querySelectorAll('link[rel="modulepreload"]'))
      i(s);
  new MutationObserver(s => {
      for (const n of s)
          if (n.type === "childList")
              for (const a of n.addedNodes)
                  a.tagName === "LINK" && a.rel === "modulepreload" && i(a)
  }
  ).observe(document, {
      childList: !0,
      subtree: !0
  });
  function e(s) {
      const n = {};
      return s.integrity && (n.integrity = s.integrity),
      s.referrerPolicy && (n.referrerPolicy = s.referrerPolicy),
      s.crossOrigin === "use-credentials" ? n.credentials = "include" : s.crossOrigin === "anonymous" ? n.credentials = "omit" : n.credentials = "same-origin",
      n
  }
  function i(s) {
      if (s.ep)
          return;
      s.ep = !0;
      const n = e(s);
      fetch(s.href, n)
  }
}
)();
/**
* lil-gui
* https://lil-gui.georgealways.com
* @version 0.17.0
* @author George Michael Brower
* @license MIT
*/
class pt {
  constructor(t, e, i, s, n="div") {
      this.parent = t,
      this.object = e,
      this.property = i,
      this._disabled = !1,
      this._hidden = !1,
      this.initialValue = this.getValue(),
      this.domElement = document.createElement("div"),
      this.domElement.classList.add("controller"),
      this.domElement.classList.add(s),
      this.$name = document.createElement("div"),
      this.$name.classList.add("name"),
      pt.nextNameID = pt.nextNameID || 0,
      this.$name.id = `lil-gui-name-${++pt.nextNameID}`,
      this.$widget = document.createElement(n),
      this.$widget.classList.add("widget"),
      this.$disable = this.$widget,
      this.domElement.appendChild(this.$name),
      this.domElement.appendChild(this.$widget),
      this.parent.children.push(this),
      this.parent.controllers.push(this),
      this.parent.$children.appendChild(this.domElement),
      this._listenCallback = this._listenCallback.bind(this),
      this.name(i)
  }
  name(t) {
      return this._name = t,
      this.$name.innerHTML = t,
      this
  }
  onChange(t) {
      return this._onChange = t,
      this
  }
  _callOnChange() {
      this.parent._callOnChange(this),
      this._onChange !== void 0 && this._onChange.call(this, this.getValue()),
      this._changed = !0
  }
  onFinishChange(t) {
      return this._onFinishChange = t,
      this
  }
  _callOnFinishChange() {
      this._changed && (this.parent._callOnFinishChange(this),
      this._onFinishChange !== void 0 && this._onFinishChange.call(this, this.getValue())),
      this._changed = !1
  }
  reset() {
      return this.setValue(this.initialValue),
      this._callOnFinishChange(),
      this
  }
  enable(t=!0) {
      return this.disable(!t)
  }
  disable(t=!0) {
      return t === this._disabled ? this : (this._disabled = t,
      this.domElement.classList.toggle("disabled", t),
      this.$disable.toggleAttribute("disabled", t),
      this)
  }
  show(t=!0) {
      return this._hidden = !t,
      this.domElement.style.display = this._hidden ? "none" : "",
      this
  }
  hide() {
      return this.show(!1)
  }
  options(t) {
      const e = this.parent.add(this.object, this.property, t);
      return e.name(this._name),
      this.destroy(),
      e
  }
  min(t) {
      return this
  }
  max(t) {
      return this
  }
  step(t) {
      return this
  }
  decimals(t) {
      return this
  }
  listen(t=!0) {
      return this._listening = t,
      this._listenCallbackID !== void 0 && (cancelAnimationFrame(this._listenCallbackID),
      this._listenCallbackID = void 0),
      this._listening && this._listenCallback(),
      this
  }
  _listenCallback() {
      this._listenCallbackID = requestAnimationFrame(this._listenCallback);
      const t = this.save();
      t !== this._listenPrevValue && this.updateDisplay(),
      this._listenPrevValue = t
  }
  getValue() {
      return this.object[this.property]
  }
  setValue(t) {
      return this.object[this.property] = t,
      this._callOnChange(),
      this.updateDisplay(),
      this
  }
  updateDisplay() {
      return this
  }
  load(t) {
      return this.setValue(t),
      this._callOnFinishChange(),
      this
  }
  save() {
      return this.getValue()
  }
  destroy() {
      this.listen(!1),
      this.parent.children.splice(this.parent.children.indexOf(this), 1),
      this.parent.controllers.splice(this.parent.controllers.indexOf(this), 1),
      this.parent.$children.removeChild(this.domElement)
  }
}
class xs extends pt {
  constructor(t, e, i) {
      super(t, e, i, "boolean", "label"),
      this.$input = document.createElement("input"),
      this.$input.setAttribute("type", "checkbox"),
      this.$input.setAttribute("aria-labelledby", this.$name.id),
      this.$widget.appendChild(this.$input),
      this.$input.addEventListener("change", () => {
          this.setValue(this.$input.checked),
          this._callOnFinishChange()
      }
      ),
      this.$disable = this.$input,
      this.updateDisplay()
  }
  updateDisplay() {
      return this.$input.checked = this.getValue(),
      this
  }
}
function He(r) {
  let t, e;
  return (t = r.match(/(#|0x)?([a-f0-9]{6})/i)) ? e = t[2] : (t = r.match(/rgb\(\s*(\d*)\s*,\s*(\d*)\s*,\s*(\d*)\s*\)/)) ? e = parseInt(t[1]).toString(16).padStart(2, 0) + parseInt(t[2]).toString(16).padStart(2, 0) + parseInt(t[3]).toString(16).padStart(2, 0) : (t = r.match(/^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i)) && (e = t[1] + t[1] + t[2] + t[2] + t[3] + t[3]),
  e ? "#" + e : !1
}
const bs = {
  isPrimitive: !0,
  match: r => typeof r == "string",
  fromHexString: He,
  toHexString: He
}
, le = {
  isPrimitive: !0,
  match: r => typeof r == "number",
  fromHexString: r => parseInt(r.substring(1), 16),
  toHexString: r => "#" + r.toString(16).padStart(6, 0)
}
, vs = {
  isPrimitive: !1,
  match: Array.isArray,
  fromHexString(r, t, e=1) {
      const i = le.fromHexString(r);
      t[0] = (i >> 16 & 255) / 255 * e,
      t[1] = (i >> 8 & 255) / 255 * e,
      t[2] = (i & 255) / 255 * e
  },
  toHexString([r,t,e], i=1) {
      i = 255 / i;
      const s = r * i << 16 ^ t * i << 8 ^ e * i << 0;
      return le.toHexString(s)
  }
}
, ws = {
  isPrimitive: !1,
  match: r => Object(r) === r,
  fromHexString(r, t, e=1) {
      const i = le.fromHexString(r);
      t.r = (i >> 16 & 255) / 255 * e,
      t.g = (i >> 8 & 255) / 255 * e,
      t.b = (i & 255) / 255 * e
  },
  toHexString({r, g: t, b: e}, i=1) {
      i = 255 / i;
      const s = r * i << 16 ^ t * i << 8 ^ e * i << 0;
      return le.toHexString(s)
  }
}
, As = [bs, le, vs, ws];
function Es(r) {
  return As.find(t => t.match(r))
}
class Ts extends pt {
  constructor(t, e, i, s) {
      super(t, e, i, "color"),
      this.$input = document.createElement("input"),
      this.$input.setAttribute("type", "color"),
      this.$input.setAttribute("tabindex", -1),
      this.$input.setAttribute("aria-labelledby", this.$name.id),
      this.$text = document.createElement("input"),
      this.$text.setAttribute("type", "text"),
      this.$text.setAttribute("spellcheck", "false"),
      this.$text.setAttribute("aria-labelledby", this.$name.id),
      this.$display = document.createElement("div"),
      this.$display.classList.add("display"),
      this.$display.appendChild(this.$input),
      this.$widget.appendChild(this.$display),
      this.$widget.appendChild(this.$text),
      this._format = Es(this.initialValue),
      this._rgbScale = s,
      this._initialValueHexString = this.save(),
      this._textFocused = !1,
      this.$input.addEventListener("input", () => {
          this._setValueFromHexString(this.$input.value)
      }
      ),
      this.$input.addEventListener("blur", () => {
          this._callOnFinishChange()
      }
      ),
      this.$text.addEventListener("input", () => {
          const n = He(this.$text.value);
          n && this._setValueFromHexString(n)
      }
      ),
      this.$text.addEventListener("focus", () => {
          this._textFocused = !0,
          this.$text.select()
      }
      ),
      this.$text.addEventListener("blur", () => {
          this._textFocused = !1,
          this.updateDisplay(),
          this._callOnFinishChange()
      }
      ),
      this.$disable = this.$text,
      this.updateDisplay()
  }
  reset() {
      return this._setValueFromHexString(this._initialValueHexString),
      this
  }
  _setValueFromHexString(t) {
      if (this._format.isPrimitive) {
          const e = this._format.fromHexString(t);
          this.setValue(e)
      } else
          this._format.fromHexString(t, this.getValue(), this._rgbScale),
          this._callOnChange(),
          this.updateDisplay()
  }
  save() {
      return this._format.toHexString(this.getValue(), this._rgbScale)
  }
  load(t) {
      return this._setValueFromHexString(t),
      this._callOnFinishChange(),
      this
  }
  updateDisplay() {
      return this.$input.value = this._format.toHexString(this.getValue(), this._rgbScale),
      this._textFocused || (this.$text.value = this.$input.value.substring(1)),
      this.$display.style.backgroundColor = this.$input.value,
      this
  }
}
class De extends pt {
  constructor(t, e, i) {
      super(t, e, i, "function"),
      this.$button = document.createElement("button"),
      this.$button.appendChild(this.$name),
      this.$widget.appendChild(this.$button),
      this.$button.addEventListener("click", s => {
          s.preventDefault(),
          this.getValue().call(this.object)
      }
      ),
      this.$button.addEventListener("touchstart", () => {}
      , {
          passive: !0
      }),
      this.$disable = this.$button
  }
}
class Ms extends pt {
  constructor(t, e, i, s, n, a) {
      super(t, e, i, "number"),
      this._initInput(),
      this.min(s),
      this.max(n);
      const h = a !== void 0;
      this.step(h ? a : this._getImplicitStep(), h),
      this.updateDisplay()
  }
  decimals(t) {
      return this._decimals = t,
      this.updateDisplay(),
      this
  }
  min(t) {
      return this._min = t,
      this._onUpdateMinMax(),
      this
  }
  max(t) {
      return this._max = t,
      this._onUpdateMinMax(),
      this
  }
  step(t, e=!0) {
      return this._step = t,
      this._stepExplicit = e,
      this
  }
  updateDisplay() {
      const t = this.getValue();
      if (this._hasSlider) {
          let e = (t - this._min) / (this._max - this._min);
          e = Math.max(0, Math.min(e, 1)),
          this.$fill.style.width = e * 100 + "%"
      }
      return this._inputFocused || (this.$input.value = this._decimals === void 0 ? t : t.toFixed(this._decimals)),
      this
  }
  _initInput() {
      this.$input = document.createElement("input"),
      this.$input.setAttribute("type", "number"),
      this.$input.setAttribute("step", "any"),
      this.$input.setAttribute("aria-labelledby", this.$name.id),
      this.$widget.appendChild(this.$input),
      this.$disable = this.$input;
      const t = () => {
          let _ = parseFloat(this.$input.value);
          isNaN(_) || (this._stepExplicit && (_ = this._snap(_)),
          this.setValue(this._clamp(_)))
      }
        , e = _ => {
          const y = parseFloat(this.$input.value);
          isNaN(y) || (this._snapClampSetValue(y + _),
          this.$input.value = this.getValue())
      }
        , i = _ => {
          _.code === "Enter" && this.$input.blur(),
          _.code === "ArrowUp" && (_.preventDefault(),
          e(this._step * this._arrowKeyMultiplier(_))),
          _.code === "ArrowDown" && (_.preventDefault(),
          e(this._step * this._arrowKeyMultiplier(_) * -1))
      }
        , s = _ => {
          this._inputFocused && (_.preventDefault(),
          e(this._step * this._normalizeMouseWheel(_)))
      }
      ;
      let n = !1, a, h, l, o, u;
      const c = 5
        , d = _ => {
          a = _.clientX,
          h = l = _.clientY,
          n = !0,
          o = this.getValue(),
          u = 0,
          window.addEventListener("mousemove", p),
          window.addEventListener("mouseup", g)
      }
        , p = _ => {
          if (n) {
              const y = _.clientX - a
                , x = _.clientY - h;
              Math.abs(x) > c ? (_.preventDefault(),
              this.$input.blur(),
              n = !1,
              this._setDraggingStyle(!0, "vertical")) : Math.abs(y) > c && g()
          }
          if (!n) {
              const y = _.clientY - l;
              u -= y * this._step * this._arrowKeyMultiplier(_),
              o + u > this._max ? u = this._max - o : o + u < this._min && (u = this._min - o),
              this._snapClampSetValue(o + u)
          }
          l = _.clientY
      }
        , g = () => {
          this._setDraggingStyle(!1, "vertical"),
          this._callOnFinishChange(),
          window.removeEventListener("mousemove", p),
          window.removeEventListener("mouseup", g)
      }
        , f = () => {
          this._inputFocused = !0
      }
        , m = () => {
          this._inputFocused = !1,
          this.updateDisplay(),
          this._callOnFinishChange()
      }
      ;
      this.$input.addEventListener("input", t),
      this.$input.addEventListener("keydown", i),
      this.$input.addEventListener("wheel", s, {
          passive: !1
      }),
      this.$input.addEventListener("mousedown", d),
      this.$input.addEventListener("focus", f),
      this.$input.addEventListener("blur", m)
  }
  _initSlider() {
      this._hasSlider = !0,
      this.$slider = document.createElement("div"),
      this.$slider.classList.add("slider"),
      this.$fill = document.createElement("div"),
      this.$fill.classList.add("fill"),
      this.$slider.appendChild(this.$fill),
      this.$widget.insertBefore(this.$slider, this.$input),
      this.domElement.classList.add("hasSlider");
      const t = (_, y, x, b, v) => (_ - y) / (x - y) * (v - b) + b
        , e = _ => {
          const y = this.$slider.getBoundingClientRect();
          let x = t(_, y.left, y.right, this._min, this._max);
          this._snapClampSetValue(x)
      }
        , i = _ => {
          this._setDraggingStyle(!0),
          e(_.clientX),
          window.addEventListener("mousemove", s),
          window.addEventListener("mouseup", n)
      }
        , s = _ => {
          e(_.clientX)
      }
        , n = () => {
          this._callOnFinishChange(),
          this._setDraggingStyle(!1),
          window.removeEventListener("mousemove", s),
          window.removeEventListener("mouseup", n)
      }
      ;
      let a = !1, h, l;
      const o = _ => {
          _.preventDefault(),
          this._setDraggingStyle(!0),
          e(_.touches[0].clientX),
          a = !1
      }
        , u = _ => {
          _.touches.length > 1 || (this._hasScrollBar ? (h = _.touches[0].clientX,
          l = _.touches[0].clientY,
          a = !0) : o(_),
          window.addEventListener("touchmove", c, {
              passive: !1
          }),
          window.addEventListener("touchend", d))
      }
        , c = _ => {
          if (a) {
              const y = _.touches[0].clientX - h
                , x = _.touches[0].clientY - l;
              Math.abs(y) > Math.abs(x) ? o(_) : (window.removeEventListener("touchmove", c),
              window.removeEventListener("touchend", d))
          } else
              _.preventDefault(),
              e(_.touches[0].clientX)
      }
        , d = () => {
          this._callOnFinishChange(),
          this._setDraggingStyle(!1),
          window.removeEventListener("touchmove", c),
          window.removeEventListener("touchend", d)
      }
        , p = this._callOnFinishChange.bind(this)
        , g = 400;
      let f;
      const m = _ => {
          if (Math.abs(_.deltaX) < Math.abs(_.deltaY) && this._hasScrollBar)
              return;
          _.preventDefault();
          const x = this._normalizeMouseWheel(_) * this._step;
          this._snapClampSetValue(this.getValue() + x),
          this.$input.value = this.getValue(),
          clearTimeout(f),
          f = setTimeout(p, g)
      }
      ;
      this.$slider.addEventListener("mousedown", i),
      this.$slider.addEventListener("touchstart", u, {
          passive: !1
      }),
      this.$slider.addEventListener("wheel", m, {
          passive: !1
      })
  }
  _setDraggingStyle(t, e="horizontal") {
      this.$slider && this.$slider.classList.toggle("active", t),
      document.body.classList.toggle("lil-gui-dragging", t),
      document.body.classList.toggle(`lil-gui-${e}`, t)
  }
  _getImplicitStep() {
      return this._hasMin && this._hasMax ? (this._max - this._min) / 1e3 : .1
  }
  _onUpdateMinMax() {
      !this._hasSlider && this._hasMin && this._hasMax && (this._stepExplicit || this.step(this._getImplicitStep(), !1),
      this._initSlider(),
      this.updateDisplay())
  }
  _normalizeMouseWheel(t) {
      let {deltaX: e, deltaY: i} = t;
      return Math.floor(t.deltaY) !== t.deltaY && t.wheelDelta && (e = 0,
      i = -t.wheelDelta / 120,
      i *= this._stepExplicit ? 1 : 10),
      e + -i
  }
  _arrowKeyMultiplier(t) {
      let e = this._stepExplicit ? 1 : 10;
      return t.shiftKey ? e *= 10 : t.altKey && (e /= 10),
      e
  }
  _snap(t) {
      const e = Math.round(t / this._step) * this._step;
      return parseFloat(e.toPrecision(15))
  }
  _clamp(t) {
      return t < this._min && (t = this._min),
      t > this._max && (t = this._max),
      t
  }
  _snapClampSetValue(t) {
      this.setValue(this._clamp(this._snap(t)))
  }
  get _hasScrollBar() {
      const t = this.parent.root.$children;
      return t.scrollHeight > t.clientHeight
  }
  get _hasMin() {
      return this._min !== void 0
  }
  get _hasMax() {
      return this._max !== void 0
  }
}
class Ss extends pt {
  constructor(t, e, i, s) {
      super(t, e, i, "option"),
      this.$select = document.createElement("select"),
      this.$select.setAttribute("aria-labelledby", this.$name.id),
      this.$display = document.createElement("div"),
      this.$display.classList.add("display"),
      this._values = Array.isArray(s) ? s : Object.values(s),
      this._names = Array.isArray(s) ? s : Object.keys(s),
      this._names.forEach(n => {
          const a = document.createElement("option");
          a.innerHTML = n,
          this.$select.appendChild(a)
      }
      ),
      this.$select.addEventListener("change", () => {
          this.setValue(this._values[this.$select.selectedIndex]),
          this._callOnFinishChange()
      }
      ),
      this.$select.addEventListener("focus", () => {
          this.$display.classList.add("focus")
      }
      ),
      this.$select.addEventListener("blur", () => {
          this.$display.classList.remove("focus")
      }
      ),
      this.$widget.appendChild(this.$select),
      this.$widget.appendChild(this.$display),
      this.$disable = this.$select,
      this.updateDisplay()
  }
  updateDisplay() {
      const t = this.getValue()
        , e = this._values.indexOf(t);
      return this.$select.selectedIndex = e,
      this.$display.innerHTML = e === -1 ? t : this._names[e],
      this
  }
}
class Cs extends pt {
  constructor(t, e, i) {
      super(t, e, i, "string"),
      this.$input = document.createElement("input"),
      this.$input.setAttribute("type", "text"),
      this.$input.setAttribute("aria-labelledby", this.$name.id),
      this.$input.addEventListener("input", () => {
          this.setValue(this.$input.value)
      }
      ),
      this.$input.addEventListener("keydown", s => {
          s.code === "Enter" && this.$input.blur()
      }
      ),
      this.$input.addEventListener("blur", () => {
          this._callOnFinishChange()
      }
      ),
      this.$widget.appendChild(this.$input),
      this.$disable = this.$input,
      this.updateDisplay()
  }
  updateDisplay() {
      return this.$input.value = this.getValue(),
      this
  }
}
const Os = `.lil-gui {
font-family: var(--font-family);
font-size: var(--font-size);
line-height: 1;
font-weight: normal;
font-style: normal;
text-align: left;
background-color: var(--background-color);
color: var(--text-color);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
--background-color: #1f1f1f;
--text-color: #ebebeb;
--title-background-color: #111111;
--title-text-color: #ebebeb;
--widget-color: #424242;
--hover-color: #4f4f4f;
--focus-color: #595959;
--number-color: #2cc9ff;
--string-color: #a2db3c;
--font-size: 11px;
--input-font-size: 11px;
--font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
--font-family-mono: Menlo, Monaco, Consolas, "Droid Sans Mono", monospace;
--padding: 4px;
--spacing: 4px;
--widget-height: 20px;
--name-width: 45%;
--slider-knob-width: 2px;
--slider-input-width: 27%;
--color-input-width: 27%;
--slider-input-min-width: 45px;
--color-input-min-width: 45px;
--folder-indent: 7px;
--widget-padding: 0 0 0 3px;
--widget-border-radius: 2px;
--checkbox-size: calc(0.75 * var(--widget-height));
--scrollbar-width: 5px;
}
.lil-gui, .lil-gui * {
box-sizing: border-box;
margin: 0;
padding: 0;
}
.lil-gui.root {
width: var(--width, 245px);
display: flex;
flex-direction: column;
}
.lil-gui.root > .title {
background: var(--title-background-color);
color: var(--title-text-color);
}
.lil-gui.root > .children {
overflow-x: hidden;
overflow-y: auto;
}
.lil-gui.root > .children::-webkit-scrollbar {
width: var(--scrollbar-width);
height: var(--scrollbar-width);
background: var(--background-color);
}
.lil-gui.root > .children::-webkit-scrollbar-thumb {
border-radius: var(--scrollbar-width);
background: var(--focus-color);
}
@media (pointer: coarse) {
.lil-gui.allow-touch-styles {
  --widget-height: 28px;
  --padding: 6px;
  --spacing: 6px;
  --font-size: 13px;
  --input-font-size: 16px;
  --folder-indent: 10px;
  --scrollbar-width: 7px;
  --slider-input-min-width: 50px;
  --color-input-min-width: 65px;
}
}
.lil-gui.force-touch-styles {
--widget-height: 28px;
--padding: 6px;
--spacing: 6px;
--font-size: 13px;
--input-font-size: 16px;
--folder-indent: 10px;
--scrollbar-width: 7px;
--slider-input-min-width: 50px;
--color-input-min-width: 65px;
}
.lil-gui.autoPlace {
max-height: 100%;
position: fixed;
top: 0;
right: 15px;
z-index: 1001;
}

.lil-gui .controller {
display: flex;
align-items: center;
padding: 0 var(--padding);
margin: var(--spacing) 0;
}
.lil-gui .controller.disabled {
opacity: 0.5;
}
.lil-gui .controller.disabled, .lil-gui .controller.disabled * {
pointer-events: none !important;
}
.lil-gui .controller > .name {
min-width: var(--name-width);
flex-shrink: 0;
white-space: pre;
padding-right: var(--spacing);
line-height: var(--widget-height);
}
.lil-gui .controller .widget {
position: relative;
display: flex;
align-items: center;
width: 100%;
min-height: var(--widget-height);
}
.lil-gui .controller.string input {
color: var(--string-color);
}
.lil-gui .controller.boolean .widget {
cursor: pointer;
}
.lil-gui .controller.color .display {
width: 100%;
height: var(--widget-height);
border-radius: var(--widget-border-radius);
position: relative;
}
@media (hover: hover) {
.lil-gui .controller.color .display:hover:before {
  content: " ";
  display: block;
  position: absolute;
  border-radius: var(--widget-border-radius);
  border: 1px solid #fff9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
}
.lil-gui .controller.color input[type=color] {
opacity: 0;
width: 100%;
height: 100%;
cursor: pointer;
}
.lil-gui .controller.color input[type=text] {
margin-left: var(--spacing);
font-family: var(--font-family-mono);
min-width: var(--color-input-min-width);
width: var(--color-input-width);
flex-shrink: 0;
}
.lil-gui .controller.option select {
opacity: 0;
position: absolute;
width: 100%;
max-width: 100%;
}
.lil-gui .controller.option .display {
position: relative;
pointer-events: none;
border-radius: var(--widget-border-radius);
height: var(--widget-height);
line-height: var(--widget-height);
max-width: 100%;
overflow: hidden;
word-break: break-all;
padding-left: 0.55em;
padding-right: 1.75em;
background: var(--widget-color);
}
@media (hover: hover) {
.lil-gui .controller.option .display.focus {
  background: var(--focus-color);
}
}
.lil-gui .controller.option .display.active {
background: var(--focus-color);
}
.lil-gui .controller.option .display:after {
font-family: "lil-gui";
content: "â†•";
position: absolute;
top: 0;
right: 0;
bottom: 0;
padding-right: 0.375em;
}
.lil-gui .controller.option .widget,
.lil-gui .controller.option select {
cursor: pointer;
}
@media (hover: hover) {
.lil-gui .controller.option .widget:hover .display {
  background: var(--hover-color);
}
}
.lil-gui .controller.number input {
color: var(--number-color);
}
.lil-gui .controller.number.hasSlider input {
margin-left: var(--spacing);
width: var(--slider-input-width);
min-width: var(--slider-input-min-width);
flex-shrink: 0;
}
.lil-gui .controller.number .slider {
width: 100%;
height: var(--widget-height);
background-color: var(--widget-color);
border-radius: var(--widget-border-radius);
padding-right: var(--slider-knob-width);
overflow: hidden;
cursor: ew-resize;
touch-action: pan-y;
}
@media (hover: hover) {
.lil-gui .controller.number .slider:hover {
  background-color: var(--hover-color);
}
}
.lil-gui .controller.number .slider.active {
background-color: var(--focus-color);
}
.lil-gui .controller.number .slider.active .fill {
opacity: 0.95;
}
.lil-gui .controller.number .fill {
height: 100%;
border-right: var(--slider-knob-width) solid var(--number-color);
box-sizing: content-box;
}

.lil-gui-dragging .lil-gui {
--hover-color: var(--widget-color);
}
.lil-gui-dragging * {
cursor: ew-resize !important;
}

.lil-gui-dragging.lil-gui-vertical * {
cursor: ns-resize !important;
}

.lil-gui .title {
--title-height: calc(var(--widget-height) + var(--spacing) * 1.25);
height: var(--title-height);
line-height: calc(var(--title-height) - 4px);
font-weight: 600;
padding: 0 var(--padding);
-webkit-tap-highlight-color: transparent;
cursor: pointer;
outline: none;
text-decoration-skip: objects;
}
.lil-gui .title:before {
font-family: "lil-gui";
content: "â–¾";
padding-right: 2px;
display: inline-block;
}
.lil-gui .title:active {
background: var(--title-background-color);
opacity: 0.75;
}
@media (hover: hover) {
body:not(.lil-gui-dragging) .lil-gui .title:hover {
  background: var(--title-background-color);
  opacity: 0.85;
}
.lil-gui .title:focus {
  text-decoration: underline var(--focus-color);
}
}
.lil-gui.root > .title:focus {
text-decoration: none !important;
}
.lil-gui.closed > .title:before {
content: "â–¸";
}
.lil-gui.closed > .children {
transform: translateY(-7px);
opacity: 0;
}
.lil-gui.closed:not(.transition) > .children {
display: none;
}
.lil-gui.transition > .children {
transition-duration: 300ms;
transition-property: height, opacity, transform;
transition-timing-function: cubic-bezier(0.2, 0.6, 0.35, 1);
overflow: hidden;
pointer-events: none;
}
.lil-gui .children:empty:before {
content: "Empty";
padding: 0 var(--padding);
margin: var(--spacing) 0;
display: block;
height: var(--widget-height);
font-style: italic;
line-height: var(--widget-height);
opacity: 0.5;
}
.lil-gui.root > .children > .lil-gui > .title {
border: 0 solid var(--widget-color);
border-width: 1px 0;
transition: border-color 300ms;
}
.lil-gui.root > .children > .lil-gui.closed > .title {
border-bottom-color: transparent;
}
.lil-gui + .controller {
border-top: 1px solid var(--widget-color);
margin-top: 0;
padding-top: var(--spacing);
}
.lil-gui .lil-gui .lil-gui > .title {
border: none;
}
.lil-gui .lil-gui .lil-gui > .children {
border: none;
margin-left: var(--folder-indent);
border-left: 2px solid var(--widget-color);
}
.lil-gui .lil-gui .controller {
border: none;
}

.lil-gui input {
-webkit-tap-highlight-color: transparent;
border: 0;
outline: none;
font-family: var(--font-family);
font-size: var(--input-font-size);
border-radius: var(--widget-border-radius);
height: var(--widget-height);
background: var(--widget-color);
color: var(--text-color);
width: 100%;
}
@media (hover: hover) {
.lil-gui input:hover {
  background: var(--hover-color);
}
.lil-gui input:active {
  background: var(--focus-color);
}
}
.lil-gui input:disabled {
opacity: 1;
}
.lil-gui input[type=text],
.lil-gui input[type=number] {
padding: var(--widget-padding);
}
.lil-gui input[type=text]:focus,
.lil-gui input[type=number]:focus {
background: var(--focus-color);
}
.lil-gui input::-webkit-outer-spin-button,
.lil-gui input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.lil-gui input[type=number] {
-moz-appearance: textfield;
}
.lil-gui input[type=checkbox] {
appearance: none;
-webkit-appearance: none;
height: var(--checkbox-size);
width: var(--checkbox-size);
border-radius: var(--widget-border-radius);
text-align: center;
cursor: pointer;
}
.lil-gui input[type=checkbox]:checked:before {
font-family: "lil-gui";
content: "âœ“";
font-size: var(--checkbox-size);
line-height: var(--checkbox-size);
}
@media (hover: hover) {
.lil-gui input[type=checkbox]:focus {
  box-shadow: inset 0 0 0 1px var(--focus-color);
}
}
.lil-gui button {
-webkit-tap-highlight-color: transparent;
outline: none;
cursor: pointer;
font-family: var(--font-family);
font-size: var(--font-size);
color: var(--text-color);
width: 100%;
height: var(--widget-height);
text-transform: none;
background: var(--widget-color);
border-radius: var(--widget-border-radius);
border: 1px solid var(--widget-color);
text-align: center;
line-height: calc(var(--widget-height) - 4px);
}
@media (hover: hover) {
.lil-gui button:hover {
  background: var(--hover-color);
  border-color: var(--hover-color);
}
.lil-gui button:focus {
  border-color: var(--focus-color);
}
}
.lil-gui button:active {
background: var(--focus-color);
}

@font-face {
font-family: "lil-gui";
src: url("data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAUsAAsAAAAACJwAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAAH4AAADAImwmYE9TLzIAAAGIAAAAPwAAAGBKqH5SY21hcAAAAcgAAAD0AAACrukyyJBnbHlmAAACvAAAAF8AAACEIZpWH2hlYWQAAAMcAAAAJwAAADZfcj2zaGhlYQAAA0QAAAAYAAAAJAC5AHhobXR4AAADXAAAABAAAABMAZAAAGxvY2EAAANsAAAAFAAAACgCEgIybWF4cAAAA4AAAAAeAAAAIAEfABJuYW1lAAADoAAAASIAAAIK9SUU/XBvc3QAAATEAAAAZgAAAJCTcMc2eJxVjbEOgjAURU+hFRBK1dGRL+ALnAiToyMLEzFpnPz/eAshwSa97517c/MwwJmeB9kwPl+0cf5+uGPZXsqPu4nvZabcSZldZ6kfyWnomFY/eScKqZNWupKJO6kXN3K9uCVoL7iInPr1X5baXs3tjuMqCtzEuagm/AAlzQgPAAB4nGNgYRBlnMDAysDAYM/gBiT5oLQBAwuDJAMDEwMrMwNWEJDmmsJwgCFeXZghBcjlZMgFCzOiKOIFAB71Bb8AeJy1kjFuwkAQRZ+DwRAwBtNQRUGKQ8OdKCAWUhAgKLhIuAsVSpWz5Bbkj3dEgYiUIszqWdpZe+Z7/wB1oCYmIoboiwiLT2WjKl/jscrHfGg/pKdMkyklC5Zs2LEfHYpjcRoPzme9MWWmk3dWbK9ObkWkikOetJ554fWyoEsmdSlt+uR0pCJR34b6t/TVg1SY3sYvdf8vuiKrpyaDXDISiegp17p7579Gp3p++y7HPAiY9pmTibljrr85qSidtlg4+l25GLCaS8e6rRxNBmsnERunKbaOObRz7N72ju5vdAjYpBXHgJylOAVsMseDAPEP8LYoUHicY2BiAAEfhiAGJgZWBgZ7RnFRdnVJELCQlBSRlATJMoLV2DK4glSYs6ubq5vbKrJLSbGrgEmovDuDJVhe3VzcXFwNLCOILB/C4IuQ1xTn5FPilBTj5FPmBAB4WwoqAHicY2BkYGAA4sk1sR/j+W2+MnAzpDBgAyEMQUCSg4EJxAEAwUgFHgB4nGNgZGBgSGFggJMhDIwMqEAYAByHATJ4nGNgAIIUNEwmAABl3AGReJxjYAACIQYlBiMGJ3wQAEcQBEV4nGNgZGBgEGZgY2BiAAEQyQWEDAz/wXwGAAsPATIAAHicXdBNSsNAHAXwl35iA0UQXYnMShfS9GPZA7T7LgIu03SSpkwzYTIt1BN4Ak/gKTyAeCxfw39jZkjymzcvAwmAW/wgwHUEGDb36+jQQ3GXGot79L24jxCP4gHzF/EIr4jEIe7wxhOC3g2TMYy4Q7+Lu/SHuEd/ivt4wJd4wPxbPEKMX3GI5+DJFGaSn4qNzk8mcbKSR6xdXdhSzaOZJGtdapd4vVPbi6rP+cL7TGXOHtXKll4bY1Xl7EGnPtp7Xy2n00zyKLVHfkHBa4IcJ2oD3cgggWvt/V/FbDrUlEUJhTn/0azVWbNTNr0Ens8de1tceK9xZmfB1CPjOmPH4kitmvOubcNpmVTN3oFJyjzCvnmrwhJTzqzVj9jiSX911FjeAAB4nG3HMRKCMBBA0f0giiKi4DU8k0V2GWbIZDOh4PoWWvq6J5V8If9NVNQcaDhyouXMhY4rPTcG7jwYmXhKq8Wz+p762aNaeYXom2n3m2dLTVgsrCgFJ7OTmIkYbwIbC6vIB7WmFfAAAA==") format("woff");
}`;
function Ps(r) {
  const t = document.createElement("style");
  t.innerHTML = r;
  const e = document.querySelector("head link[rel=stylesheet], head style");
  e ? document.head.insertBefore(t, e) : document.head.appendChild(t)
}
let Pi = !1;
class gr {
  constructor({parent: t, autoPlace: e=t === void 0, container: i, width: s, title: n="Controls", injectStyles: a=!0, touchStyles: h=!0}={}) {
      if (this.parent = t,
      this.root = t ? t.root : this,
      this.children = [],
      this.controllers = [],
      this.folders = [],
      this._closed = !1,
      this._hidden = !1,
      this.domElement = document.createElement("div"),
      this.domElement.classList.add("lil-gui"),
      this.$title = document.createElement("div"),
      this.$title.classList.add("title"),
      this.$title.setAttribute("role", "button"),
      this.$title.setAttribute("aria-expanded", !0),
      this.$title.setAttribute("tabindex", 0),
      this.$title.addEventListener("click", () => this.openAnimated(this._closed)),
      this.$title.addEventListener("keydown", l => {
          (l.code === "Enter" || l.code === "Space") && (l.preventDefault(),
          this.$title.click())
      }
      ),
      this.$title.addEventListener("touchstart", () => {}
      , {
          passive: !0
      }),
      this.$children = document.createElement("div"),
      this.$children.classList.add("children"),
      this.domElement.appendChild(this.$title),
      this.domElement.appendChild(this.$children),
      this.title(n),
      h && this.domElement.classList.add("allow-touch-styles"),
      this.parent) {
          this.parent.children.push(this),
          this.parent.folders.push(this),
          this.parent.$children.appendChild(this.domElement);
          return
      }
      this.domElement.classList.add("root"),
      !Pi && a && (Ps(Os),
      Pi = !0),
      i ? i.appendChild(this.domElement) : e && (this.domElement.classList.add("autoPlace"),
      document.body.appendChild(this.domElement)),
      s && this.domElement.style.setProperty("--width", s + "px"),
      this.domElement.addEventListener("keydown", l => l.stopPropagation()),
      this.domElement.addEventListener("keyup", l => l.stopPropagation())
  }
  add(t, e, i, s, n) {
      if (Object(i) === i)
          return new Ss(this,t,e,i);
      const a = t[e];
      switch (typeof a) {
      case "number":
          return new Ms(this,t,e,i,s,n);
      case "boolean":
          return new xs(this,t,e);
      case "string":
          return new Cs(this,t,e);
      case "function":
          return new De(this,t,e)
      }
      console.error(`gui.add failed
property:`, e, `
object:`, t, `
value:`, a)
  }
  addColor(t, e, i=1) {
      return new Ts(this,t,e,i)
  }
  addFolder(t) {
      return new gr({
          parent: this,
          title: t
      })
  }
  load(t, e=!0) {
      return t.controllers && this.controllers.forEach(i => {
          i instanceof De || i._name in t.controllers && i.load(t.controllers[i._name])
      }
      ),
      e && t.folders && this.folders.forEach(i => {
          i._title in t.folders && i.load(t.folders[i._title])
      }
      ),
      this
  }
  save(t=!0) {
      const e = {
          controllers: {},
          folders: {}
      };
      return this.controllers.forEach(i => {
          if (!(i instanceof De)) {
              if (i._name in e.controllers)
                  throw new Error(`Cannot save GUI with duplicate property "${i._name}"`);
              e.controllers[i._name] = i.save()
          }
      }
      ),
      t && this.folders.forEach(i => {
          if (i._title in e.folders)
              throw new Error(`Cannot save GUI with duplicate folder "${i._title}"`);
          e.folders[i._title] = i.save()
      }
      ),
      e
  }
  open(t=!0) {
      return this._closed = !t,
      this.$title.setAttribute("aria-expanded", !this._closed),
      this.domElement.classList.toggle("closed", this._closed),
      this
  }
  close() {
      return this.open(!1)
  }
  show(t=!0) {
      return this._hidden = !t,
      this.domElement.style.display = this._hidden ? "none" : "",
      this
  }
  hide() {
      return this.show(!1)
  }
  openAnimated(t=!0) {
      return this._closed = !t,
      this.$title.setAttribute("aria-expanded", !this._closed),
      requestAnimationFrame( () => {
          const e = this.$children.clientHeight;
          this.$children.style.height = e + "px",
          this.domElement.classList.add("transition");
          const i = n => {
              n.target === this.$children && (this.$children.style.height = "",
              this.domElement.classList.remove("transition"),
              this.$children.removeEventListener("transitionend", i))
          }
          ;
          this.$children.addEventListener("transitionend", i);
          const s = t ? this.$children.scrollHeight : 0;
          this.domElement.classList.toggle("closed", !t),
          requestAnimationFrame( () => {
              this.$children.style.height = s + "px"
          }
          )
      }
      ),
      this
  }
  title(t) {
      return this._title = t,
      this.$title.innerHTML = t,
      this
  }
  reset(t=!0) {
      return (t ? this.controllersRecursive() : this.controllers).forEach(i => i.reset()),
      this
  }
  onChange(t) {
      return this._onChange = t,
      this
  }
  _callOnChange(t) {
      this.parent && this.parent._callOnChange(t),
      this._onChange !== void 0 && this._onChange.call(this, {
          object: t.object,
          property: t.property,
          value: t.getValue(),
          controller: t
      })
  }
  onFinishChange(t) {
      return this._onFinishChange = t,
      this
  }
  _callOnFinishChange(t) {
      this.parent && this.parent._callOnFinishChange(t),
      this._onFinishChange !== void 0 && this._onFinishChange.call(this, {
          object: t.object,
          property: t.property,
          value: t.getValue(),
          controller: t
      })
  }
  destroy() {
      this.parent && (this.parent.children.splice(this.parent.children.indexOf(this), 1),
      this.parent.folders.splice(this.parent.folders.indexOf(this), 1)),
      this.domElement.parentElement && this.domElement.parentElement.removeChild(this.domElement),
      Array.from(this.children).forEach(t => t.destroy())
  }
  controllersRecursive() {
      let t = Array.from(this.controllers);
      return this.folders.forEach(e => {
          t = t.concat(e.controllersRecursive())
      }
      ),
      t
  }
  foldersRecursive() {
      let t = Array.from(this.folders);
      return this.folders.forEach(e => {
          t = t.concat(e.foldersRecursive())
      }
      ),
      t
  }
}
function ki(r) {
  let t = r[0]
    , e = r[1]
    , i = r[2];
  return Math.sqrt(t * t + e * e + i * i)
}
function je(r, t) {
  return r[0] = t[0],
  r[1] = t[1],
  r[2] = t[2],
  r
}
function ks(r, t, e, i) {
  return r[0] = t,
  r[1] = e,
  r[2] = i,
  r
}
function Fi(r, t, e) {
  return r[0] = t[0] + e[0],
  r[1] = t[1] + e[1],
  r[2] = t[2] + e[2],
  r
}
function Di(r, t, e) {
  return r[0] = t[0] - e[0],
  r[1] = t[1] - e[1],
  r[2] = t[2] - e[2],
  r
}
function Fs(r, t, e) {
  return r[0] = t[0] * e[0],
  r[1] = t[1] * e[1],
  r[2] = t[2] * e[2],
  r
}
function Ds(r, t, e) {
  return r[0] = t[0] / e[0],
  r[1] = t[1] / e[1],
  r[2] = t[2] / e[2],
  r
}
function Le(r, t, e) {
  return r[0] = t[0] * e,
  r[1] = t[1] * e,
  r[2] = t[2] * e,
  r
}
function Ls(r, t) {
  let e = t[0] - r[0]
    , i = t[1] - r[1]
    , s = t[2] - r[2];
  return Math.sqrt(e * e + i * i + s * s)
}
function Rs(r, t) {
  let e = t[0] - r[0]
    , i = t[1] - r[1]
    , s = t[2] - r[2];
  return e * e + i * i + s * s
}
function Li(r) {
  let t = r[0]
    , e = r[1]
    , i = r[2];
  return t * t + e * e + i * i
}
function $s(r, t) {
  return r[0] = -t[0],
  r[1] = -t[1],
  r[2] = -t[2],
  r
}
function zs(r, t) {
  return r[0] = 1 / t[0],
  r[1] = 1 / t[1],
  r[2] = 1 / t[2],
  r
}
function Ze(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = e * e + i * i + s * s;
  return n > 0 && (n = 1 / Math.sqrt(n)),
  r[0] = t[0] * n,
  r[1] = t[1] * n,
  r[2] = t[2] * n,
  r
}
function mr(r, t) {
  return r[0] * t[0] + r[1] * t[1] + r[2] * t[2]
}
function Ri(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = e[0]
    , h = e[1]
    , l = e[2];
  return r[0] = s * l - n * h,
  r[1] = n * a - i * l,
  r[2] = i * h - s * a,
  r
}
function Is(r, t, e, i) {
  let s = t[0]
    , n = t[1]
    , a = t[2];
  return r[0] = s + i * (e[0] - s),
  r[1] = n + i * (e[1] - n),
  r[2] = a + i * (e[2] - a),
  r
}
function Bs(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = e[3] * i + e[7] * s + e[11] * n + e[15];
  return a = a || 1,
  r[0] = (e[0] * i + e[4] * s + e[8] * n + e[12]) / a,
  r[1] = (e[1] * i + e[5] * s + e[9] * n + e[13]) / a,
  r[2] = (e[2] * i + e[6] * s + e[10] * n + e[14]) / a,
  r
}
function Vs(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = e[3] * i + e[7] * s + e[11] * n + e[15];
  return a = a || 1,
  r[0] = (e[0] * i + e[4] * s + e[8] * n) / a,
  r[1] = (e[1] * i + e[5] * s + e[9] * n) / a,
  r[2] = (e[2] * i + e[6] * s + e[10] * n) / a,
  r
}
function Ns(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2];
  return r[0] = i * e[0] + s * e[3] + n * e[6],
  r[1] = i * e[1] + s * e[4] + n * e[7],
  r[2] = i * e[2] + s * e[5] + n * e[8],
  r
}
function Us(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = e[0]
    , h = e[1]
    , l = e[2]
    , o = e[3]
    , u = h * n - l * s
    , c = l * i - a * n
    , d = a * s - h * i
    , p = h * d - l * c
    , g = l * u - a * d
    , f = a * c - h * u
    , m = o * 2;
  return u *= m,
  c *= m,
  d *= m,
  p *= 2,
  g *= 2,
  f *= 2,
  r[0] = i + u + p,
  r[1] = s + c + g,
  r[2] = n + d + f,
  r
}
const Ys = function() {
  const r = [0, 0, 0]
    , t = [0, 0, 0];
  return function(e, i) {
      je(r, e),
      je(t, i),
      Ze(r, r),
      Ze(t, t);
      let s = mr(r, t);
      return s > 1 ? 0 : s < -1 ? Math.PI : Math.acos(s)
  }
}();
function Xs(r, t) {
  return r[0] === t[0] && r[1] === t[1] && r[2] === t[2]
}
class ut extends Array {
  constructor(t=0, e=t, i=t) {
      return super(t, e, i),
      this
  }
  get x() {
      return this[0]
  }
  get y() {
      return this[1]
  }
  get z() {
      return this[2]
  }
  set x(t) {
      this[0] = t
  }
  set y(t) {
      this[1] = t
  }
  set z(t) {
      this[2] = t
  }
  set(t, e=t, i=t) {
      return t.length ? this.copy(t) : (ks(this, t, e, i),
      this)
  }
  copy(t) {
      return je(this, t),
      this
  }
  add(t, e) {
      return e ? Fi(this, t, e) : Fi(this, this, t),
      this
  }
  sub(t, e) {
      return e ? Di(this, t, e) : Di(this, this, t),
      this
  }
  multiply(t) {
      return t.length ? Fs(this, this, t) : Le(this, this, t),
      this
  }
  divide(t) {
      return t.length ? Ds(this, this, t) : Le(this, this, 1 / t),
      this
  }
  inverse(t=this) {
      return zs(this, t),
      this
  }
  len() {
      return ki(this)
  }
  distance(t) {
      return t ? Ls(this, t) : ki(this)
  }
  squaredLen() {
      return Li(this)
  }
  squaredDistance(t) {
      return t ? Rs(this, t) : Li(this)
  }
  negate(t=this) {
      return $s(this, t),
      this
  }
  cross(t, e) {
      return e ? Ri(this, t, e) : Ri(this, this, t),
      this
  }
  scale(t) {
      return Le(this, this, t),
      this
  }
  normalize() {
      return Ze(this, this),
      this
  }
  dot(t) {
      return mr(this, t)
  }
  equals(t) {
      return Xs(this, t)
  }
  applyMatrix3(t) {
      return Ns(this, this, t),
      this
  }
  applyMatrix4(t) {
      return Bs(this, this, t),
      this
  }
  scaleRotateMatrix4(t) {
      return Vs(this, this, t),
      this
  }
  applyQuaternion(t) {
      return Us(this, this, t),
      this
  }
  angle(t) {
      return Ys(this, t)
  }
  lerp(t, e) {
      return Is(this, this, t, e),
      this
  }
  clone() {
      return new ut(this[0],this[1],this[2])
  }
  fromArray(t, e=0) {
      return this[0] = t[e],
      this[1] = t[e + 1],
      this[2] = t[e + 2],
      this
  }
  toArray(t=[], e=0) {
      return t[e] = this[0],
      t[e + 1] = this[1],
      t[e + 2] = this[2],
      t
  }
  transformDirection(t) {
      const e = this[0]
        , i = this[1]
        , s = this[2];
      return this[0] = t[0] * e + t[4] * i + t[8] * s,
      this[1] = t[1] * e + t[5] * i + t[9] * s,
      this[2] = t[2] * e + t[6] * i + t[10] * s,
      this.normalize()
  }
}
const $i = new ut;
let Gs = 1
, qs = 1
, zi = !1;
class Ws {
  constructor(t, e={}) {
      t.canvas || console.error("gl not passed as first argument to Geometry"),
      this.gl = t,
      this.attributes = e,
      this.id = Gs++,
      this.VAOs = {},
      this.drawRange = {
          start: 0,
          count: 0
      },
      this.instancedCount = 0,
      this.gl.renderer.bindVertexArray(null),
      this.gl.renderer.currentGeometry = null,
      this.glState = this.gl.renderer.state;
      for (let i in e)
          this.addAttribute(i, e[i])
  }
  addAttribute(t, e) {
      if (this.attributes[t] = e,
      e.id = qs++,
      e.size = e.size || 1,
      e.type = e.type || (e.data.constructor === Float32Array ? this.gl.FLOAT : e.data.constructor === Uint16Array ? this.gl.UNSIGNED_SHORT : this.gl.UNSIGNED_INT),
      e.target = t === "index" ? this.gl.ELEMENT_ARRAY_BUFFER : this.gl.ARRAY_BUFFER,
      e.normalized = e.normalized || !1,
      e.stride = e.stride || 0,
      e.offset = e.offset || 0,
      e.count = e.count || (e.stride ? e.data.byteLength / e.stride : e.data.length / e.size),
      e.divisor = e.instanced || 0,
      e.needsUpdate = !1,
      e.usage = e.usage || this.gl.STATIC_DRAW,
      e.buffer || this.updateAttribute(e),
      e.divisor) {
          if (this.isInstanced = !0,
          this.instancedCount && this.instancedCount !== e.count * e.divisor)
              return console.warn("geometry has multiple instanced buffers of different length"),
              this.instancedCount = Math.min(this.instancedCount, e.count * e.divisor);
          this.instancedCount = e.count * e.divisor
      } else
          t === "index" ? this.drawRange.count = e.count : this.attributes.index || (this.drawRange.count = Math.max(this.drawRange.count, e.count))
  }
  updateAttribute(t) {
      const e = !t.buffer;
      e && (t.buffer = this.gl.createBuffer()),
      this.glState.boundBuffer !== t.buffer && (this.gl.bindBuffer(t.target, t.buffer),
      this.glState.boundBuffer = t.buffer),
      e ? this.gl.bufferData(t.target, t.data, t.usage) : this.gl.bufferSubData(t.target, 0, t.data),
      t.needsUpdate = !1
  }
  setIndex(t) {
      this.addAttribute("index", t)
  }
  setDrawRange(t, e) {
      this.drawRange.start = t,
      this.drawRange.count = e
  }
  setInstancedCount(t) {
      this.instancedCount = t
  }
  createVAO(t) {
      this.VAOs[t.attributeOrder] = this.gl.renderer.createVertexArray(),
      this.gl.renderer.bindVertexArray(this.VAOs[t.attributeOrder]),
      this.bindAttributes(t)
  }
  bindAttributes(t) {
      t.attributeLocations.forEach( (e, {name: i, type: s}) => {
          if (!this.attributes[i]) {
              console.warn(`active attribute ${i} not being supplied`);
              return
          }
          const n = this.attributes[i];
          this.gl.bindBuffer(n.target, n.buffer),
          this.glState.boundBuffer = n.buffer;
          let a = 1;
          s === 35674 && (a = 2),
          s === 35675 && (a = 3),
          s === 35676 && (a = 4);
          const h = n.size / a
            , l = a === 1 ? 0 : a * a * a
            , o = a === 1 ? 0 : a * a;
          for (let u = 0; u < a; u++)
              this.gl.vertexAttribPointer(e + u, h, n.type, n.normalized, n.stride + l, n.offset + u * o),
              this.gl.enableVertexAttribArray(e + u),
              this.gl.renderer.vertexAttribDivisor(e + u, n.divisor)
      }
      ),
      this.attributes.index && this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.attributes.index.buffer)
  }
  draw({program: t, mode: e=this.gl.TRIANGLES}) {
      this.gl.renderer.currentGeometry !== `${this.id}_${t.attributeOrder}` && (this.VAOs[t.attributeOrder] || this.createVAO(t),
      this.gl.renderer.bindVertexArray(this.VAOs[t.attributeOrder]),
      this.gl.renderer.currentGeometry = `${this.id}_${t.attributeOrder}`),
      t.attributeLocations.forEach( (i, {name: s}) => {
          const n = this.attributes[s];
          n.needsUpdate && this.updateAttribute(n)
      }
      ),
      this.isInstanced ? this.attributes.index ? this.gl.renderer.drawElementsInstanced(e, this.drawRange.count, this.attributes.index.type, this.attributes.index.offset + this.drawRange.start * 2, this.instancedCount) : this.gl.renderer.drawArraysInstanced(e, this.drawRange.start, this.drawRange.count, this.instancedCount) : this.attributes.index ? this.gl.drawElements(e, this.drawRange.count, this.attributes.index.type, this.attributes.index.offset + this.drawRange.start * 2) : this.gl.drawArrays(e, this.drawRange.start, this.drawRange.count)
  }
  getPosition() {
      const t = this.attributes.position;
      if (t.data)
          return t;
      if (!zi)
          return console.warn("No position buffer data found to compute bounds"),
          zi = !0
  }
  computeBoundingBox(t) {
      t || (t = this.getPosition());
      const e = t.data
        , i = t.stride ? t.stride / e.BYTES_PER_ELEMENT : t.size;
      this.bounds || (this.bounds = {
          min: new ut,
          max: new ut,
          center: new ut,
          scale: new ut,
          radius: 1 / 0
      });
      const s = this.bounds.min
        , n = this.bounds.max
        , a = this.bounds.center
        , h = this.bounds.scale;
      s.set(1 / 0),
      n.set(-1 / 0);
      for (let l = 0, o = e.length; l < o; l += i) {
          const u = e[l]
            , c = e[l + 1]
            , d = e[l + 2];
          s.x = Math.min(u, s.x),
          s.y = Math.min(c, s.y),
          s.z = Math.min(d, s.z),
          n.x = Math.max(u, n.x),
          n.y = Math.max(c, n.y),
          n.z = Math.max(d, n.z)
      }
      h.sub(n, s),
      a.add(s, n).divide(2)
  }
  computeBoundingSphere(t) {
      t || (t = this.getPosition());
      const e = t.data
        , i = t.stride ? t.stride / e.BYTES_PER_ELEMENT : t.size;
      this.bounds || this.computeBoundingBox(t);
      let s = 0;
      for (let n = 0, a = e.length; n < a; n += i)
          $i.fromArray(e, n),
          s = Math.max(s, this.bounds.center.squaredDistance($i));
      this.bounds.radius = Math.sqrt(s)
  }
  remove() {
      for (let t in this.VAOs)
          this.gl.renderer.deleteVertexArray(this.VAOs[t]),
          delete this.VAOs[t];
      for (let t in this.attributes)
          this.gl.deleteBuffer(this.attributes[t].buffer),
          delete this.attributes[t]
  }
}
let Hs = 1;
const Ii = {};
class Oh {
  constructor(t, {vertex: e, fragment: i, uniforms: s={}, transparent: n=!1, cullFace: a=t.BACK, frontFace: h=t.CCW, depthTest: l=!0, depthWrite: o=!0, depthFunc: u=t.LESS}={}) {
      t.canvas || console.error("gl not passed as first argument to Program"),
      this.gl = t,
      this.uniforms = s,
      this.id = Hs++,
      e || console.warn("vertex shader not supplied"),
      i || console.warn("fragment shader not supplied"),
      this.transparent = n,
      this.cullFace = a,
      this.frontFace = h,
      this.depthTest = l,
      this.depthWrite = o,
      this.depthFunc = u,
      this.blendFunc = {},
      this.blendEquation = {},
      this.transparent && !this.blendFunc.src && (this.gl.renderer.premultipliedAlpha ? this.setBlendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA) : this.setBlendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA));
      const c = t.createShader(t.VERTEX_SHADER);
      t.shaderSource(c, e),
      t.compileShader(c),
      t.getShaderInfoLog(c) !== "" && console.warn(`${t.getShaderInfoLog(c)}
Vertex Shader
${Bi(e)}`);
      const d = t.createShader(t.FRAGMENT_SHADER);
      if (t.shaderSource(d, i),
      t.compileShader(d),
      t.getShaderInfoLog(d) !== "" && console.warn(`${t.getShaderInfoLog(d)}
Fragment Shader
${Bi(i)}`),
      this.program = t.createProgram(),
      t.attachShader(this.program, c),
      t.attachShader(this.program, d),
      t.linkProgram(this.program),
      !t.getProgramParameter(this.program, t.LINK_STATUS))
          return console.warn(t.getProgramInfoLog(this.program));
      t.deleteShader(c),
      t.deleteShader(d),
      this.uniformLocations = new Map;
      let p = t.getProgramParameter(this.program, t.ACTIVE_UNIFORMS);
      for (let m = 0; m < p; m++) {
          let _ = t.getActiveUniform(this.program, m);
          this.uniformLocations.set(_, t.getUniformLocation(this.program, _.name));
          const y = _.name.match(/(\w+)/g);
          _.uniformName = y[0],
          y.length === 3 ? (_.isStructArray = !0,
          _.structIndex = Number(y[1]),
          _.structProperty = y[2]) : y.length === 2 && isNaN(Number(y[1])) && (_.isStruct = !0,
          _.structProperty = y[1])
      }
      this.attributeLocations = new Map;
      const g = []
        , f = t.getProgramParameter(this.program, t.ACTIVE_ATTRIBUTES);
      for (let m = 0; m < f; m++) {
          const _ = t.getActiveAttrib(this.program, m)
            , y = t.getAttribLocation(this.program, _.name);
          y !== -1 && (g[y] = _.name,
          this.attributeLocations.set(_, y))
      }
      this.attributeOrder = g.join("")
  }
  setBlendFunc(t, e, i, s) {
      this.blendFunc.src = t,
      this.blendFunc.dst = e,
      this.blendFunc.srcAlpha = i,
      this.blendFunc.dstAlpha = s,
      t && (this.transparent = !0)
  }
  setBlendEquation(t, e) {
      this.blendEquation.modeRGB = t,
      this.blendEquation.modeAlpha = e
  }
  applyState() {
      this.depthTest ? this.gl.renderer.enable(this.gl.DEPTH_TEST) : this.gl.renderer.disable(this.gl.DEPTH_TEST),
      this.cullFace ? this.gl.renderer.enable(this.gl.CULL_FACE) : this.gl.renderer.disable(this.gl.CULL_FACE),
      this.blendFunc.src ? this.gl.renderer.enable(this.gl.BLEND) : this.gl.renderer.disable(this.gl.BLEND),
      this.cullFace && this.gl.renderer.setCullFace(this.cullFace),
      this.gl.renderer.setFrontFace(this.frontFace),
      this.gl.renderer.setDepthMask(this.depthWrite),
      this.gl.renderer.setDepthFunc(this.depthFunc),
      this.blendFunc.src && this.gl.renderer.setBlendFunc(this.blendFunc.src, this.blendFunc.dst, this.blendFunc.srcAlpha, this.blendFunc.dstAlpha),
      this.gl.renderer.setBlendEquation(this.blendEquation.modeRGB, this.blendEquation.modeAlpha)
  }
  use({flipFaces: t=!1}={}) {
      let e = -1;
      this.gl.renderer.state.currentProgram === this.id || (this.gl.useProgram(this.program),
      this.gl.renderer.state.currentProgram = this.id),
      this.uniformLocations.forEach( (s, n) => {
          let a = n.uniformName
            , h = this.uniforms[a];
          if (n.isStruct && (h = h[n.structProperty],
          a += `.${n.structProperty}`),
          n.isStructArray && (h = h[n.structIndex][n.structProperty],
          a += `[${n.structIndex}].${n.structProperty}`),
          !h)
              return Vi(`Active uniform ${a} has not been supplied`);
          if (h && h.value === void 0)
              return Vi(`${a} uniform is missing a value parameter`);
          if (h.value.texture)
              return e = e + 1,
              h.value.update(e),
              Re(this.gl, n.type, s, e);
          if (h.value.length && h.value[0].texture) {
              const l = [];
              return h.value.forEach(o => {
                  e = e + 1,
                  o.update(e),
                  l.push(e)
              }
              ),
              Re(this.gl, n.type, s, l)
          }
          Re(this.gl, n.type, s, h.value)
      }
      ),
      this.applyState(),
      t && this.gl.renderer.setFrontFace(this.frontFace === this.gl.CCW ? this.gl.CW : this.gl.CCW)
  }
  remove() {
      this.gl.deleteProgram(this.program)
  }
}
function Re(r, t, e, i) {
  i = i.length ? js(i) : i;
  const s = r.renderer.state.uniformLocations.get(e);
  if (i.length)
      if (s === void 0 || s.length !== i.length)
          r.renderer.state.uniformLocations.set(e, i.slice(0));
      else {
          if (Zs(s, i))
              return;
          s.set ? s.set(i) : Ks(s, i),
          r.renderer.state.uniformLocations.set(e, s)
      }
  else {
      if (s === i)
          return;
      r.renderer.state.uniformLocations.set(e, i)
  }
  switch (t) {
  case 5126:
      return i.length ? r.uniform1fv(e, i) : r.uniform1f(e, i);
  case 35664:
      return r.uniform2fv(e, i);
  case 35665:
      return r.uniform3fv(e, i);
  case 35666:
      return r.uniform4fv(e, i);
  case 35670:
  case 5124:
  case 35678:
  case 35680:
      return i.length ? r.uniform1iv(e, i) : r.uniform1i(e, i);
  case 35671:
  case 35667:
      return r.uniform2iv(e, i);
  case 35672:
  case 35668:
      return r.uniform3iv(e, i);
  case 35673:
  case 35669:
      return r.uniform4iv(e, i);
  case 35674:
      return r.uniformMatrix2fv(e, !1, i);
  case 35675:
      return r.uniformMatrix3fv(e, !1, i);
  case 35676:
      return r.uniformMatrix4fv(e, !1, i)
  }
}
function Bi(r) {
  let t = r.split(`
`);
  for (let e = 0; e < t.length; e++)
      t[e] = e + 1 + ": " + t[e];
  return t.join(`
`)
}
function js(r) {
  const t = r.length
    , e = r[0].length;
  if (e === void 0)
      return r;
  const i = t * e;
  let s = Ii[i];
  s || (Ii[i] = s = new Float32Array(i));
  for (let n = 0; n < t; n++)
      s.set(r[n], n * e);
  return s
}
function Zs(r, t) {
  if (r.length !== t.length)
      return !1;
  for (let e = 0, i = r.length; e < i; e++)
      if (r[e] !== t[e])
          return !1;
  return !0
}
function Ks(r, t) {
  for (let e = 0, i = r.length; e < i; e++)
      r[e] = t[e]
}
let $e = 0;
function Vi(r) {
  $e > 100 || (console.warn(r),
  $e++,
  $e > 100 && console.warn("More than 100 program warnings - stopping logs."))
}
const ze = new ut;
let Qs = 1;
class Ph {
  constructor({canvas: t=document.createElement("canvas"), width: e=300, height: i=150, dpr: s=1, alpha: n=!1, depth: a=!0, stencil: h=!1, antialias: l=!1, premultipliedAlpha: o=!1, preserveDrawingBuffer: u=!1, powerPreference: c="default", autoClear: d=!0, webgl: p=2}={}) {
      const g = {
          alpha: n,
          depth: a,
          stencil: h,
          antialias: l,
          premultipliedAlpha: o,
          preserveDrawingBuffer: u,
          powerPreference: c
      };
      this.dpr = s,
      this.alpha = n,
      this.color = !0,
      this.depth = a,
      this.stencil = h,
      this.premultipliedAlpha = o,
      this.autoClear = d,
      this.id = Qs++,
      p === 2 && (this.gl = t.getContext("webgl2", g)),
      this.isWebgl2 = !!this.gl,
      this.gl || (this.gl = t.getContext("webgl", g)),
      this.gl || console.error("unable to create webgl context"),
      this.gl.renderer = this,
      this.setSize(e, i),
      this.state = {},
      this.state.blendFunc = {
          src: this.gl.ONE,
          dst: this.gl.ZERO
      },
      this.state.blendEquation = {
          modeRGB: this.gl.FUNC_ADD
      },
      this.state.cullFace = null,
      this.state.frontFace = this.gl.CCW,
      this.state.depthMask = !0,
      this.state.depthFunc = this.gl.LESS,
      this.state.premultiplyAlpha = !1,
      this.state.flipY = !1,
      this.state.unpackAlignment = 4,
      this.state.framebuffer = null,
      this.state.viewport = {
          x: 0,
          y: 0,
          width: null,
          height: null
      },
      this.state.textureUnits = [],
      this.state.activeTextureUnit = 0,
      this.state.boundBuffer = null,
      this.state.uniformLocations = new Map,
      this.state.currentProgram = null,
      this.extensions = {},
      this.isWebgl2 ? (this.getExtension("EXT_color_buffer_float"),
      this.getExtension("OES_texture_float_linear")) : (this.getExtension("OES_texture_float"),
      this.getExtension("OES_texture_float_linear"),
      this.getExtension("OES_texture_half_float"),
      this.getExtension("OES_texture_half_float_linear"),
      this.getExtension("OES_element_index_uint"),
      this.getExtension("OES_standard_derivatives"),
      this.getExtension("EXT_sRGB"),
      this.getExtension("WEBGL_depth_texture"),
      this.getExtension("WEBGL_draw_buffers")),
      this.getExtension("WEBGL_compressed_texture_astc"),
      this.getExtension("EXT_texture_compression_bptc"),
      this.getExtension("WEBGL_compressed_texture_s3tc"),
      this.getExtension("WEBGL_compressed_texture_etc1"),
      this.getExtension("WEBGL_compressed_texture_pvrtc"),
      this.getExtension("WEBKIT_WEBGL_compressed_texture_pvrtc"),
      this.vertexAttribDivisor = this.getExtension("ANGLE_instanced_arrays", "vertexAttribDivisor", "vertexAttribDivisorANGLE"),
      this.drawArraysInstanced = this.getExtension("ANGLE_instanced_arrays", "drawArraysInstanced", "drawArraysInstancedANGLE"),
      this.drawElementsInstanced = this.getExtension("ANGLE_instanced_arrays", "drawElementsInstanced", "drawElementsInstancedANGLE"),
      this.createVertexArray = this.getExtension("OES_vertex_array_object", "createVertexArray", "createVertexArrayOES"),
      this.bindVertexArray = this.getExtension("OES_vertex_array_object", "bindVertexArray", "bindVertexArrayOES"),
      this.deleteVertexArray = this.getExtension("OES_vertex_array_object", "deleteVertexArray", "deleteVertexArrayOES"),
      this.drawBuffers = this.getExtension("WEBGL_draw_buffers", "drawBuffers", "drawBuffersWEBGL"),
      this.parameters = {},
      this.parameters.maxTextureUnits = this.gl.getParameter(this.gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS),
      this.parameters.maxAnisotropy = this.getExtension("EXT_texture_filter_anisotropic") ? this.gl.getParameter(this.getExtension("EXT_texture_filter_anisotropic").MAX_TEXTURE_MAX_ANISOTROPY_EXT) : 0
  }
  setSize(t, e) {
      this.width = t,
      this.height = e,
      this.gl.canvas.width = t * this.dpr,
      this.gl.canvas.height = e * this.dpr,
      Object.assign(this.gl.canvas.style, {
          width: t + "px",
          height: e + "px"
      })
  }
  setViewport(t, e, i=0, s=0) {
      this.state.viewport.width === t && this.state.viewport.height === e || (this.state.viewport.width = t,
      this.state.viewport.height = e,
      this.state.viewport.x = i,
      this.state.viewport.y = s,
      this.gl.viewport(i, s, t, e))
  }
  setScissor(t, e, i=0, s=0) {
      this.gl.scissor(i, s, t, e)
  }
  enable(t) {
      this.state[t] !== !0 && (this.gl.enable(t),
      this.state[t] = !0)
  }
  disable(t) {
      this.state[t] !== !1 && (this.gl.disable(t),
      this.state[t] = !1)
  }
  setBlendFunc(t, e, i, s) {
      this.state.blendFunc.src === t && this.state.blendFunc.dst === e && this.state.blendFunc.srcAlpha === i && this.state.blendFunc.dstAlpha === s || (this.state.blendFunc.src = t,
      this.state.blendFunc.dst = e,
      this.state.blendFunc.srcAlpha = i,
      this.state.blendFunc.dstAlpha = s,
      i !== void 0 ? this.gl.blendFuncSeparate(t, e, i, s) : this.gl.blendFunc(t, e))
  }
  setBlendEquation(t, e) {
      t = t || this.gl.FUNC_ADD,
      !(this.state.blendEquation.modeRGB === t && this.state.blendEquation.modeAlpha === e) && (this.state.blendEquation.modeRGB = t,
      this.state.blendEquation.modeAlpha = e,
      e !== void 0 ? this.gl.blendEquationSeparate(t, e) : this.gl.blendEquation(t))
  }
  setCullFace(t) {
      this.state.cullFace !== t && (this.state.cullFace = t,
      this.gl.cullFace(t))
  }
  setFrontFace(t) {
      this.state.frontFace !== t && (this.state.frontFace = t,
      this.gl.frontFace(t))
  }
  setDepthMask(t) {
      this.state.depthMask !== t && (this.state.depthMask = t,
      this.gl.depthMask(t))
  }
  setDepthFunc(t) {
      this.state.depthFunc !== t && (this.state.depthFunc = t,
      this.gl.depthFunc(t))
  }
  activeTexture(t) {
      this.state.activeTextureUnit !== t && (this.state.activeTextureUnit = t,
      this.gl.activeTexture(this.gl.TEXTURE0 + t))
  }
  bindFramebuffer({target: t=this.gl.FRAMEBUFFER, buffer: e=null}={}) {
      this.state.framebuffer !== e && (this.state.framebuffer = e,
      this.gl.bindFramebuffer(t, e))
  }
  getExtension(t, e, i) {
      return e && this.gl[e] ? this.gl[e].bind(this.gl) : (this.extensions[t] || (this.extensions[t] = this.gl.getExtension(t)),
      e ? this.extensions[t] ? this.extensions[t][i].bind(this.extensions[t]) : null : this.extensions[t])
  }
  sortOpaque(t, e) {
      return t.renderOrder !== e.renderOrder ? t.renderOrder - e.renderOrder : t.program.id !== e.program.id ? t.program.id - e.program.id : t.zDepth !== e.zDepth ? t.zDepth - e.zDepth : e.id - t.id
  }
  sortTransparent(t, e) {
      return t.renderOrder !== e.renderOrder ? t.renderOrder - e.renderOrder : t.zDepth !== e.zDepth ? e.zDepth - t.zDepth : e.id - t.id
  }
  sortUI(t, e) {
      return t.renderOrder !== e.renderOrder ? t.renderOrder - e.renderOrder : t.program.id !== e.program.id ? t.program.id - e.program.id : e.id - t.id
  }
  getRenderList({scene: t, camera: e, frustumCull: i, sort: s}) {
      let n = [];
      if (e && i && e.updateFrustum(),
      t.traverse(a => {
          if (!a.visible)
              return !0;
          a.draw && (i && a.frustumCulled && e && !e.frustumIntersectsMesh(a) || n.push(a))
      }
      ),
      s) {
          const a = []
            , h = []
            , l = [];
          n.forEach(o => {
              o.program.transparent ? o.program.depthTest ? h.push(o) : l.push(o) : a.push(o),
              o.zDepth = 0,
              !(o.renderOrder !== 0 || !o.program.depthTest || !e) && (o.worldMatrix.getTranslation(ze),
              ze.applyMatrix4(e.projectionViewMatrix),
              o.zDepth = ze.z)
          }
          ),
          a.sort(this.sortOpaque),
          h.sort(this.sortTransparent),
          l.sort(this.sortUI),
          n = a.concat(h, l)
      }
      return n
  }
  render({scene: t, camera: e, target: i=null, update: s=!0, sort: n=!0, frustumCull: a=!0, clear: h}) {
      i === null ? (this.bindFramebuffer(),
      this.setViewport(this.width * this.dpr, this.height * this.dpr)) : (this.bindFramebuffer(i),
      this.setViewport(i.width, i.height)),
      (h || this.autoClear && h !== !1) && (this.depth && (!i || i.depth) && (this.enable(this.gl.DEPTH_TEST),
      this.setDepthMask(!0)),
      this.gl.clear((this.color ? this.gl.COLOR_BUFFER_BIT : 0) | (this.depth ? this.gl.DEPTH_BUFFER_BIT : 0) | (this.stencil ? this.gl.STENCIL_BUFFER_BIT : 0))),
      s && t.updateMatrixWorld(),
      e && e.updateMatrixWorld(),
      this.getRenderList({
          scene: t,
          camera: e,
          frustumCull: a,
          sort: n
      }).forEach(o => {
          o.draw({
              camera: e
          })
      }
      )
  }
}
function Js(r, t) {
  return r[0] = t[0],
  r[1] = t[1],
  r[2] = t[2],
  r[3] = t[3],
  r
}
function tn(r, t, e, i, s) {
  return r[0] = t,
  r[1] = e,
  r[2] = i,
  r[3] = s,
  r
}
function en(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = e * e + i * i + s * s + n * n;
  return a > 0 && (a = 1 / Math.sqrt(a)),
  r[0] = e * a,
  r[1] = i * a,
  r[2] = s * a,
  r[3] = n * a,
  r
}
function rn(r, t) {
  return r[0] * t[0] + r[1] * t[1] + r[2] * t[2] + r[3] * t[3]
}
function sn(r) {
  return r[0] = 0,
  r[1] = 0,
  r[2] = 0,
  r[3] = 1,
  r
}
function nn(r, t, e) {
  e = e * .5;
  let i = Math.sin(e);
  return r[0] = i * t[0],
  r[1] = i * t[1],
  r[2] = i * t[2],
  r[3] = Math.cos(e),
  r
}
function Ni(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = e[0]
    , l = e[1]
    , o = e[2]
    , u = e[3];
  return r[0] = i * u + a * h + s * o - n * l,
  r[1] = s * u + a * l + n * h - i * o,
  r[2] = n * u + a * o + i * l - s * h,
  r[3] = a * u - i * h - s * l - n * o,
  r
}
function an(r, t, e) {
  e *= .5;
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = Math.sin(e)
    , l = Math.cos(e);
  return r[0] = i * l + a * h,
  r[1] = s * l + n * h,
  r[2] = n * l - s * h,
  r[3] = a * l - i * h,
  r
}
function hn(r, t, e) {
  e *= .5;
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = Math.sin(e)
    , l = Math.cos(e);
  return r[0] = i * l - n * h,
  r[1] = s * l + a * h,
  r[2] = n * l + i * h,
  r[3] = a * l - s * h,
  r
}
function ln(r, t, e) {
  e *= .5;
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = Math.sin(e)
    , l = Math.cos(e);
  return r[0] = i * l + s * h,
  r[1] = s * l - i * h,
  r[2] = n * l + a * h,
  r[3] = a * l - n * h,
  r
}
function on(r, t, e, i) {
  let s = t[0], n = t[1], a = t[2], h = t[3], l = e[0], o = e[1], u = e[2], c = e[3], d, p, g, f, m;
  return p = s * l + n * o + a * u + h * c,
  p < 0 && (p = -p,
  l = -l,
  o = -o,
  u = -u,
  c = -c),
  1 - p > 1e-6 ? (d = Math.acos(p),
  g = Math.sin(d),
  f = Math.sin((1 - i) * d) / g,
  m = Math.sin(i * d) / g) : (f = 1 - i,
  m = i),
  r[0] = f * s + m * l,
  r[1] = f * n + m * o,
  r[2] = f * a + m * u,
  r[3] = f * h + m * c,
  r
}
function un(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = e * e + i * i + s * s + n * n
    , h = a ? 1 / a : 0;
  return r[0] = -e * h,
  r[1] = -i * h,
  r[2] = -s * h,
  r[3] = n * h,
  r
}
function fn(r, t) {
  return r[0] = -t[0],
  r[1] = -t[1],
  r[2] = -t[2],
  r[3] = t[3],
  r
}
function dn(r, t) {
  let e = t[0] + t[4] + t[8], i;
  if (e > 0)
      i = Math.sqrt(e + 1),
      r[3] = .5 * i,
      i = .5 / i,
      r[0] = (t[5] - t[7]) * i,
      r[1] = (t[6] - t[2]) * i,
      r[2] = (t[1] - t[3]) * i;
  else {
      let s = 0;
      t[4] > t[0] && (s = 1),
      t[8] > t[s * 3 + s] && (s = 2);
      let n = (s + 1) % 3
        , a = (s + 2) % 3;
      i = Math.sqrt(t[s * 3 + s] - t[n * 3 + n] - t[a * 3 + a] + 1),
      r[s] = .5 * i,
      i = .5 / i,
      r[3] = (t[n * 3 + a] - t[a * 3 + n]) * i,
      r[n] = (t[n * 3 + s] + t[s * 3 + n]) * i,
      r[a] = (t[a * 3 + s] + t[s * 3 + a]) * i
  }
  return r
}
function cn(r, t, e="YXZ") {
  let i = Math.sin(t[0] * .5)
    , s = Math.cos(t[0] * .5)
    , n = Math.sin(t[1] * .5)
    , a = Math.cos(t[1] * .5)
    , h = Math.sin(t[2] * .5)
    , l = Math.cos(t[2] * .5);
  return e === "XYZ" ? (r[0] = i * a * l + s * n * h,
  r[1] = s * n * l - i * a * h,
  r[2] = s * a * h + i * n * l,
  r[3] = s * a * l - i * n * h) : e === "YXZ" ? (r[0] = i * a * l + s * n * h,
  r[1] = s * n * l - i * a * h,
  r[2] = s * a * h - i * n * l,
  r[3] = s * a * l + i * n * h) : e === "ZXY" ? (r[0] = i * a * l - s * n * h,
  r[1] = s * n * l + i * a * h,
  r[2] = s * a * h + i * n * l,
  r[3] = s * a * l - i * n * h) : e === "ZYX" ? (r[0] = i * a * l - s * n * h,
  r[1] = s * n * l + i * a * h,
  r[2] = s * a * h - i * n * l,
  r[3] = s * a * l + i * n * h) : e === "YZX" ? (r[0] = i * a * l + s * n * h,
  r[1] = s * n * l + i * a * h,
  r[2] = s * a * h - i * n * l,
  r[3] = s * a * l - i * n * h) : e === "XZY" && (r[0] = i * a * l - s * n * h,
  r[1] = s * n * l - i * a * h,
  r[2] = s * a * h + i * n * l,
  r[3] = s * a * l + i * n * h),
  r
}
const pn = Js
, _n = tn
, gn = rn
, mn = en;
class yn extends Array {
  constructor(t=0, e=0, i=0, s=1) {
      return super(t, e, i, s),
      this.onChange = () => {}
      ,
      this
  }
  get x() {
      return this[0]
  }
  get y() {
      return this[1]
  }
  get z() {
      return this[2]
  }
  get w() {
      return this[3]
  }
  set x(t) {
      this[0] = t,
      this.onChange()
  }
  set y(t) {
      this[1] = t,
      this.onChange()
  }
  set z(t) {
      this[2] = t,
      this.onChange()
  }
  set w(t) {
      this[3] = t,
      this.onChange()
  }
  identity() {
      return sn(this),
      this.onChange(),
      this
  }
  set(t, e, i, s) {
      return t.length ? this.copy(t) : (_n(this, t, e, i, s),
      this.onChange(),
      this)
  }
  rotateX(t) {
      return an(this, this, t),
      this.onChange(),
      this
  }
  rotateY(t) {
      return hn(this, this, t),
      this.onChange(),
      this
  }
  rotateZ(t) {
      return ln(this, this, t),
      this.onChange(),
      this
  }
  inverse(t=this) {
      return un(this, t),
      this.onChange(),
      this
  }
  conjugate(t=this) {
      return fn(this, t),
      this.onChange(),
      this
  }
  copy(t) {
      return pn(this, t),
      this.onChange(),
      this
  }
  normalize(t=this) {
      return mn(this, t),
      this.onChange(),
      this
  }
  multiply(t, e) {
      return e ? Ni(this, t, e) : Ni(this, this, t),
      this.onChange(),
      this
  }
  dot(t) {
      return gn(this, t)
  }
  fromMatrix3(t) {
      return dn(this, t),
      this.onChange(),
      this
  }
  fromEuler(t) {
      return cn(this, t, t.order),
      this
  }
  fromAxisAngle(t, e) {
      return nn(this, t, e),
      this.onChange(),
      this
  }
  slerp(t, e) {
      return on(this, this, t, e),
      this.onChange(),
      this
  }
  fromArray(t, e=0) {
      return this[0] = t[e],
      this[1] = t[e + 1],
      this[2] = t[e + 2],
      this[3] = t[e + 3],
      this.onChange(),
      this
  }
  toArray(t=[], e=0) {
      return t[e] = this[0],
      t[e + 1] = this[1],
      t[e + 2] = this[2],
      t[e + 3] = this[3],
      t
  }
}
const xn = 1e-6;
function bn(r, t) {
  return r[0] = t[0],
  r[1] = t[1],
  r[2] = t[2],
  r[3] = t[3],
  r[4] = t[4],
  r[5] = t[5],
  r[6] = t[6],
  r[7] = t[7],
  r[8] = t[8],
  r[9] = t[9],
  r[10] = t[10],
  r[11] = t[11],
  r[12] = t[12],
  r[13] = t[13],
  r[14] = t[14],
  r[15] = t[15],
  r
}
function vn(r, t, e, i, s, n, a, h, l, o, u, c, d, p, g, f, m) {
  return r[0] = t,
  r[1] = e,
  r[2] = i,
  r[3] = s,
  r[4] = n,
  r[5] = a,
  r[6] = h,
  r[7] = l,
  r[8] = o,
  r[9] = u,
  r[10] = c,
  r[11] = d,
  r[12] = p,
  r[13] = g,
  r[14] = f,
  r[15] = m,
  r
}
function wn(r) {
  return r[0] = 1,
  r[1] = 0,
  r[2] = 0,
  r[3] = 0,
  r[4] = 0,
  r[5] = 1,
  r[6] = 0,
  r[7] = 0,
  r[8] = 0,
  r[9] = 0,
  r[10] = 1,
  r[11] = 0,
  r[12] = 0,
  r[13] = 0,
  r[14] = 0,
  r[15] = 1,
  r
}
function An(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = t[4]
    , h = t[5]
    , l = t[6]
    , o = t[7]
    , u = t[8]
    , c = t[9]
    , d = t[10]
    , p = t[11]
    , g = t[12]
    , f = t[13]
    , m = t[14]
    , _ = t[15]
    , y = e * h - i * a
    , x = e * l - s * a
    , b = e * o - n * a
    , v = i * l - s * h
    , w = i * o - n * h
    , T = s * o - n * l
    , E = u * f - c * g
    , A = u * m - d * g
    , S = u * _ - p * g
    , C = c * m - d * f
    , O = c * _ - p * f
    , P = d * _ - p * m
    , M = y * P - x * O + b * C + v * S - w * A + T * E;
  return M ? (M = 1 / M,
  r[0] = (h * P - l * O + o * C) * M,
  r[1] = (s * O - i * P - n * C) * M,
  r[2] = (f * T - m * w + _ * v) * M,
  r[3] = (d * w - c * T - p * v) * M,
  r[4] = (l * S - a * P - o * A) * M,
  r[5] = (e * P - s * S + n * A) * M,
  r[6] = (m * b - g * T - _ * x) * M,
  r[7] = (u * T - d * b + p * x) * M,
  r[8] = (a * O - h * S + o * E) * M,
  r[9] = (i * S - e * O - n * E) * M,
  r[10] = (g * w - f * b + _ * y) * M,
  r[11] = (c * b - u * w - p * y) * M,
  r[12] = (h * A - a * C - l * E) * M,
  r[13] = (e * C - i * A + s * E) * M,
  r[14] = (f * x - g * v - m * y) * M,
  r[15] = (u * v - c * x + d * y) * M,
  r) : null
}
function En(r) {
  let t = r[0]
    , e = r[1]
    , i = r[2]
    , s = r[3]
    , n = r[4]
    , a = r[5]
    , h = r[6]
    , l = r[7]
    , o = r[8]
    , u = r[9]
    , c = r[10]
    , d = r[11]
    , p = r[12]
    , g = r[13]
    , f = r[14]
    , m = r[15]
    , _ = t * a - e * n
    , y = t * h - i * n
    , x = t * l - s * n
    , b = e * h - i * a
    , v = e * l - s * a
    , w = i * l - s * h
    , T = o * g - u * p
    , E = o * f - c * p
    , A = o * m - d * p
    , S = u * f - c * g
    , C = u * m - d * g
    , O = c * m - d * f;
  return _ * O - y * C + x * S + b * A - v * E + w * T
}
function Ui(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = t[4]
    , l = t[5]
    , o = t[6]
    , u = t[7]
    , c = t[8]
    , d = t[9]
    , p = t[10]
    , g = t[11]
    , f = t[12]
    , m = t[13]
    , _ = t[14]
    , y = t[15]
    , x = e[0]
    , b = e[1]
    , v = e[2]
    , w = e[3];
  return r[0] = x * i + b * h + v * c + w * f,
  r[1] = x * s + b * l + v * d + w * m,
  r[2] = x * n + b * o + v * p + w * _,
  r[3] = x * a + b * u + v * g + w * y,
  x = e[4],
  b = e[5],
  v = e[6],
  w = e[7],
  r[4] = x * i + b * h + v * c + w * f,
  r[5] = x * s + b * l + v * d + w * m,
  r[6] = x * n + b * o + v * p + w * _,
  r[7] = x * a + b * u + v * g + w * y,
  x = e[8],
  b = e[9],
  v = e[10],
  w = e[11],
  r[8] = x * i + b * h + v * c + w * f,
  r[9] = x * s + b * l + v * d + w * m,
  r[10] = x * n + b * o + v * p + w * _,
  r[11] = x * a + b * u + v * g + w * y,
  x = e[12],
  b = e[13],
  v = e[14],
  w = e[15],
  r[12] = x * i + b * h + v * c + w * f,
  r[13] = x * s + b * l + v * d + w * m,
  r[14] = x * n + b * o + v * p + w * _,
  r[15] = x * a + b * u + v * g + w * y,
  r
}
function Tn(r, t, e) {
  let i = e[0], s = e[1], n = e[2], a, h, l, o, u, c, d, p, g, f, m, _;
  return t === r ? (r[12] = t[0] * i + t[4] * s + t[8] * n + t[12],
  r[13] = t[1] * i + t[5] * s + t[9] * n + t[13],
  r[14] = t[2] * i + t[6] * s + t[10] * n + t[14],
  r[15] = t[3] * i + t[7] * s + t[11] * n + t[15]) : (a = t[0],
  h = t[1],
  l = t[2],
  o = t[3],
  u = t[4],
  c = t[5],
  d = t[6],
  p = t[7],
  g = t[8],
  f = t[9],
  m = t[10],
  _ = t[11],
  r[0] = a,
  r[1] = h,
  r[2] = l,
  r[3] = o,
  r[4] = u,
  r[5] = c,
  r[6] = d,
  r[7] = p,
  r[8] = g,
  r[9] = f,
  r[10] = m,
  r[11] = _,
  r[12] = a * i + u * s + g * n + t[12],
  r[13] = h * i + c * s + f * n + t[13],
  r[14] = l * i + d * s + m * n + t[14],
  r[15] = o * i + p * s + _ * n + t[15]),
  r
}
function Mn(r, t, e) {
  let i = e[0]
    , s = e[1]
    , n = e[2];
  return r[0] = t[0] * i,
  r[1] = t[1] * i,
  r[2] = t[2] * i,
  r[3] = t[3] * i,
  r[4] = t[4] * s,
  r[5] = t[5] * s,
  r[6] = t[6] * s,
  r[7] = t[7] * s,
  r[8] = t[8] * n,
  r[9] = t[9] * n,
  r[10] = t[10] * n,
  r[11] = t[11] * n,
  r[12] = t[12],
  r[13] = t[13],
  r[14] = t[14],
  r[15] = t[15],
  r
}
function Sn(r, t, e, i) {
  let s = i[0], n = i[1], a = i[2], h = Math.hypot(s, n, a), l, o, u, c, d, p, g, f, m, _, y, x, b, v, w, T, E, A, S, C, O, P, M, F;
  return Math.abs(h) < xn ? null : (h = 1 / h,
  s *= h,
  n *= h,
  a *= h,
  l = Math.sin(e),
  o = Math.cos(e),
  u = 1 - o,
  c = t[0],
  d = t[1],
  p = t[2],
  g = t[3],
  f = t[4],
  m = t[5],
  _ = t[6],
  y = t[7],
  x = t[8],
  b = t[9],
  v = t[10],
  w = t[11],
  T = s * s * u + o,
  E = n * s * u + a * l,
  A = a * s * u - n * l,
  S = s * n * u - a * l,
  C = n * n * u + o,
  O = a * n * u + s * l,
  P = s * a * u + n * l,
  M = n * a * u - s * l,
  F = a * a * u + o,
  r[0] = c * T + f * E + x * A,
  r[1] = d * T + m * E + b * A,
  r[2] = p * T + _ * E + v * A,
  r[3] = g * T + y * E + w * A,
  r[4] = c * S + f * C + x * O,
  r[5] = d * S + m * C + b * O,
  r[6] = p * S + _ * C + v * O,
  r[7] = g * S + y * C + w * O,
  r[8] = c * P + f * M + x * F,
  r[9] = d * P + m * M + b * F,
  r[10] = p * P + _ * M + v * F,
  r[11] = g * P + y * M + w * F,
  t !== r && (r[12] = t[12],
  r[13] = t[13],
  r[14] = t[14],
  r[15] = t[15]),
  r)
}
function Cn(r, t) {
  return r[0] = t[12],
  r[1] = t[13],
  r[2] = t[14],
  r
}
function yr(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[4]
    , a = t[5]
    , h = t[6]
    , l = t[8]
    , o = t[9]
    , u = t[10];
  return r[0] = Math.hypot(e, i, s),
  r[1] = Math.hypot(n, a, h),
  r[2] = Math.hypot(l, o, u),
  r
}
function On(r) {
  let t = r[0]
    , e = r[1]
    , i = r[2]
    , s = r[4]
    , n = r[5]
    , a = r[6]
    , h = r[8]
    , l = r[9]
    , o = r[10];
  const u = t * t + e * e + i * i
    , c = s * s + n * n + a * a
    , d = h * h + l * l + o * o;
  return Math.sqrt(Math.max(u, c, d))
}
const Pn = function() {
  const r = [0, 0, 0];
  return function(t, e) {
      let i = r;
      yr(i, e);
      let s = 1 / i[0]
        , n = 1 / i[1]
        , a = 1 / i[2]
        , h = e[0] * s
        , l = e[1] * n
        , o = e[2] * a
        , u = e[4] * s
        , c = e[5] * n
        , d = e[6] * a
        , p = e[8] * s
        , g = e[9] * n
        , f = e[10] * a
        , m = h + c + f
        , _ = 0;
      return m > 0 ? (_ = Math.sqrt(m + 1) * 2,
      t[3] = .25 * _,
      t[0] = (d - g) / _,
      t[1] = (p - o) / _,
      t[2] = (l - u) / _) : h > c && h > f ? (_ = Math.sqrt(1 + h - c - f) * 2,
      t[3] = (d - g) / _,
      t[0] = .25 * _,
      t[1] = (l + u) / _,
      t[2] = (p + o) / _) : c > f ? (_ = Math.sqrt(1 + c - h - f) * 2,
      t[3] = (p - o) / _,
      t[0] = (l + u) / _,
      t[1] = .25 * _,
      t[2] = (d + g) / _) : (_ = Math.sqrt(1 + f - h - c) * 2,
      t[3] = (l - u) / _,
      t[0] = (p + o) / _,
      t[1] = (d + g) / _,
      t[2] = .25 * _),
      t
  }
}();
function kn(r, t, e, i) {
  let s = t[0]
    , n = t[1]
    , a = t[2]
    , h = t[3]
    , l = s + s
    , o = n + n
    , u = a + a
    , c = s * l
    , d = s * o
    , p = s * u
    , g = n * o
    , f = n * u
    , m = a * u
    , _ = h * l
    , y = h * o
    , x = h * u
    , b = i[0]
    , v = i[1]
    , w = i[2];
  return r[0] = (1 - (g + m)) * b,
  r[1] = (d + x) * b,
  r[2] = (p - y) * b,
  r[3] = 0,
  r[4] = (d - x) * v,
  r[5] = (1 - (c + m)) * v,
  r[6] = (f + _) * v,
  r[7] = 0,
  r[8] = (p + y) * w,
  r[9] = (f - _) * w,
  r[10] = (1 - (c + g)) * w,
  r[11] = 0,
  r[12] = e[0],
  r[13] = e[1],
  r[14] = e[2],
  r[15] = 1,
  r
}
function Fn(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = e + e
    , h = i + i
    , l = s + s
    , o = e * a
    , u = i * a
    , c = i * h
    , d = s * a
    , p = s * h
    , g = s * l
    , f = n * a
    , m = n * h
    , _ = n * l;
  return r[0] = 1 - c - g,
  r[1] = u + _,
  r[2] = d - m,
  r[3] = 0,
  r[4] = u - _,
  r[5] = 1 - o - g,
  r[6] = p + f,
  r[7] = 0,
  r[8] = d + m,
  r[9] = p - f,
  r[10] = 1 - o - c,
  r[11] = 0,
  r[12] = 0,
  r[13] = 0,
  r[14] = 0,
  r[15] = 1,
  r
}
function Dn(r, t, e, i, s) {
  let n = 1 / Math.tan(t / 2)
    , a = 1 / (i - s);
  return r[0] = n / e,
  r[1] = 0,
  r[2] = 0,
  r[3] = 0,
  r[4] = 0,
  r[5] = n,
  r[6] = 0,
  r[7] = 0,
  r[8] = 0,
  r[9] = 0,
  r[10] = (s + i) * a,
  r[11] = -1,
  r[12] = 0,
  r[13] = 0,
  r[14] = 2 * s * i * a,
  r[15] = 0,
  r
}
function Ln(r, t, e, i, s, n, a) {
  let h = 1 / (t - e)
    , l = 1 / (i - s)
    , o = 1 / (n - a);
  return r[0] = -2 * h,
  r[1] = 0,
  r[2] = 0,
  r[3] = 0,
  r[4] = 0,
  r[5] = -2 * l,
  r[6] = 0,
  r[7] = 0,
  r[8] = 0,
  r[9] = 0,
  r[10] = 2 * o,
  r[11] = 0,
  r[12] = (t + e) * h,
  r[13] = (s + i) * l,
  r[14] = (a + n) * o,
  r[15] = 1,
  r
}
function Rn(r, t, e, i) {
  let s = t[0]
    , n = t[1]
    , a = t[2]
    , h = i[0]
    , l = i[1]
    , o = i[2]
    , u = s - e[0]
    , c = n - e[1]
    , d = a - e[2]
    , p = u * u + c * c + d * d;
  p === 0 ? d = 1 : (p = 1 / Math.sqrt(p),
  u *= p,
  c *= p,
  d *= p);
  let g = l * d - o * c
    , f = o * u - h * d
    , m = h * c - l * u;
  return p = g * g + f * f + m * m,
  p === 0 && (o ? h += 1e-6 : l ? o += 1e-6 : l += 1e-6,
  g = l * d - o * c,
  f = o * u - h * d,
  m = h * c - l * u,
  p = g * g + f * f + m * m),
  p = 1 / Math.sqrt(p),
  g *= p,
  f *= p,
  m *= p,
  r[0] = g,
  r[1] = f,
  r[2] = m,
  r[3] = 0,
  r[4] = c * m - d * f,
  r[5] = d * g - u * m,
  r[6] = u * f - c * g,
  r[7] = 0,
  r[8] = u,
  r[9] = c,
  r[10] = d,
  r[11] = 0,
  r[12] = s,
  r[13] = n,
  r[14] = a,
  r[15] = 1,
  r
}
class ve extends Array {
  constructor(t=1, e=0, i=0, s=0, n=0, a=1, h=0, l=0, o=0, u=0, c=1, d=0, p=0, g=0, f=0, m=1) {
      return super(t, e, i, s, n, a, h, l, o, u, c, d, p, g, f, m),
      this
  }
  get x() {
      return this[12]
  }
  get y() {
      return this[13]
  }
  get z() {
      return this[14]
  }
  get w() {
      return this[15]
  }
  set x(t) {
      this[12] = t
  }
  set y(t) {
      this[13] = t
  }
  set z(t) {
      this[14] = t
  }
  set w(t) {
      this[15] = t
  }
  set(t, e, i, s, n, a, h, l, o, u, c, d, p, g, f, m) {
      return t.length ? this.copy(t) : (vn(this, t, e, i, s, n, a, h, l, o, u, c, d, p, g, f, m),
      this)
  }
  translate(t, e=this) {
      return Tn(this, e, t),
      this
  }
  rotate(t, e, i=this) {
      return Sn(this, i, t, e),
      this
  }
  scale(t, e=this) {
      return Mn(this, e, typeof t == "number" ? [t, t, t] : t),
      this
  }
  multiply(t, e) {
      return e ? Ui(this, t, e) : Ui(this, this, t),
      this
  }
  identity() {
      return wn(this),
      this
  }
  copy(t) {
      return bn(this, t),
      this
  }
  fromPerspective({fov: t, aspect: e, near: i, far: s}={}) {
      return Dn(this, t, e, i, s),
      this
  }
  fromOrthogonal({left: t, right: e, bottom: i, top: s, near: n, far: a}) {
      return Ln(this, t, e, i, s, n, a),
      this
  }
  fromQuaternion(t) {
      return Fn(this, t),
      this
  }
  setPosition(t) {
      return this.x = t[0],
      this.y = t[1],
      this.z = t[2],
      this
  }
  inverse(t=this) {
      return An(this, t),
      this
  }
  compose(t, e, i) {
      return kn(this, t, e, i),
      this
  }
  getRotation(t) {
      return Pn(t, this),
      this
  }
  getTranslation(t) {
      return Cn(t, this),
      this
  }
  getScaling(t) {
      return yr(t, this),
      this
  }
  getMaxScaleOnAxis() {
      return On(this)
  }
  lookAt(t, e, i) {
      return Rn(this, t, e, i),
      this
  }
  determinant() {
      return En(this)
  }
  fromArray(t, e=0) {
      return this[0] = t[e],
      this[1] = t[e + 1],
      this[2] = t[e + 2],
      this[3] = t[e + 3],
      this[4] = t[e + 4],
      this[5] = t[e + 5],
      this[6] = t[e + 6],
      this[7] = t[e + 7],
      this[8] = t[e + 8],
      this[9] = t[e + 9],
      this[10] = t[e + 10],
      this[11] = t[e + 11],
      this[12] = t[e + 12],
      this[13] = t[e + 13],
      this[14] = t[e + 14],
      this[15] = t[e + 15],
      this
  }
  toArray(t=[], e=0) {
      return t[e] = this[0],
      t[e + 1] = this[1],
      t[e + 2] = this[2],
      t[e + 3] = this[3],
      t[e + 4] = this[4],
      t[e + 5] = this[5],
      t[e + 6] = this[6],
      t[e + 7] = this[7],
      t[e + 8] = this[8],
      t[e + 9] = this[9],
      t[e + 10] = this[10],
      t[e + 11] = this[11],
      t[e + 12] = this[12],
      t[e + 13] = this[13],
      t[e + 14] = this[14],
      t[e + 15] = this[15],
      t
  }
}
function $n(r, t, e="YXZ") {
  return e === "XYZ" ? (r[1] = Math.asin(Math.min(Math.max(t[8], -1), 1)),
  Math.abs(t[8]) < .99999 ? (r[0] = Math.atan2(-t[9], t[10]),
  r[2] = Math.atan2(-t[4], t[0])) : (r[0] = Math.atan2(t[6], t[5]),
  r[2] = 0)) : e === "YXZ" ? (r[0] = Math.asin(-Math.min(Math.max(t[9], -1), 1)),
  Math.abs(t[9]) < .99999 ? (r[1] = Math.atan2(t[8], t[10]),
  r[2] = Math.atan2(t[1], t[5])) : (r[1] = Math.atan2(-t[2], t[0]),
  r[2] = 0)) : e === "ZXY" ? (r[0] = Math.asin(Math.min(Math.max(t[6], -1), 1)),
  Math.abs(t[6]) < .99999 ? (r[1] = Math.atan2(-t[2], t[10]),
  r[2] = Math.atan2(-t[4], t[5])) : (r[1] = 0,
  r[2] = Math.atan2(t[1], t[0]))) : e === "ZYX" ? (r[1] = Math.asin(-Math.min(Math.max(t[2], -1), 1)),
  Math.abs(t[2]) < .99999 ? (r[0] = Math.atan2(t[6], t[10]),
  r[2] = Math.atan2(t[1], t[0])) : (r[0] = 0,
  r[2] = Math.atan2(-t[4], t[5]))) : e === "YZX" ? (r[2] = Math.asin(Math.min(Math.max(t[1], -1), 1)),
  Math.abs(t[1]) < .99999 ? (r[0] = Math.atan2(-t[9], t[5]),
  r[1] = Math.atan2(-t[2], t[0])) : (r[0] = 0,
  r[1] = Math.atan2(t[8], t[10]))) : e === "XZY" && (r[2] = Math.asin(-Math.min(Math.max(t[4], -1), 1)),
  Math.abs(t[4]) < .99999 ? (r[0] = Math.atan2(t[6], t[5]),
  r[1] = Math.atan2(t[8], t[0])) : (r[0] = Math.atan2(-t[9], t[10]),
  r[1] = 0)),
  r
}
const Yi = new ve;
class zn extends Array {
  constructor(t=0, e=t, i=t, s="YXZ") {
      return super(t, e, i),
      this.order = s,
      this.onChange = () => {}
      ,
      this
  }
  get x() {
      return this[0]
  }
  get y() {
      return this[1]
  }
  get z() {
      return this[2]
  }
  set x(t) {
      this[0] = t,
      this.onChange()
  }
  set y(t) {
      this[1] = t,
      this.onChange()
  }
  set z(t) {
      this[2] = t,
      this.onChange()
  }
  set(t, e=t, i=t) {
      return t.length ? this.copy(t) : (this[0] = t,
      this[1] = e,
      this[2] = i,
      this.onChange(),
      this)
  }
  copy(t) {
      return this[0] = t[0],
      this[1] = t[1],
      this[2] = t[2],
      this.onChange(),
      this
  }
  reorder(t) {
      return this.order = t,
      this.onChange(),
      this
  }
  fromRotationMatrix(t, e=this.order) {
      return $n(this, t, e),
      this.onChange(),
      this
  }
  fromQuaternion(t, e=this.order) {
      return Yi.fromQuaternion(t),
      this.fromRotationMatrix(Yi, e)
  }
  toArray(t=[], e=0) {
      return t[e] = this[0],
      t[e + 1] = this[1],
      t[e + 2] = this[2],
      t
  }
}
class In {
  constructor() {
      this.parent = null,
      this.children = [],
      this.visible = !0,
      this.matrix = new ve,
      this.worldMatrix = new ve,
      this.matrixAutoUpdate = !0,
      this.position = new ut,
      this.quaternion = new yn,
      this.scale = new ut(1),
      this.rotation = new zn,
      this.up = new ut(0,1,0),
      this.rotation.onChange = () => this.quaternion.fromEuler(this.rotation),
      this.quaternion.onChange = () => this.rotation.fromQuaternion(this.quaternion)
  }
  setParent(t, e=!0) {
      this.parent && t !== this.parent && this.parent.removeChild(this, !1),
      this.parent = t,
      e && t && t.addChild(this, !1)
  }
  addChild(t, e=!0) {
      ~this.children.indexOf(t) || this.children.push(t),
      e && t.setParent(this, !1)
  }
  removeChild(t, e=!0) {
      ~this.children.indexOf(t) && this.children.splice(this.children.indexOf(t), 1),
      e && t.setParent(null, !1)
  }
  updateMatrixWorld(t) {
      this.matrixAutoUpdate && this.updateMatrix(),
      (this.worldMatrixNeedsUpdate || t) && (this.parent === null ? this.worldMatrix.copy(this.matrix) : this.worldMatrix.multiply(this.parent.worldMatrix, this.matrix),
      this.worldMatrixNeedsUpdate = !1,
      t = !0);
      for (let e = 0, i = this.children.length; e < i; e++)
          this.children[e].updateMatrixWorld(t)
  }
  updateMatrix() {
      this.matrix.compose(this.quaternion, this.position, this.scale),
      this.worldMatrixNeedsUpdate = !0
  }
  traverse(t) {
      if (!t(this))
          for (let e = 0, i = this.children.length; e < i; e++)
              this.children[e].traverse(t)
  }
  decompose() {
      this.matrix.getTranslation(this.position),
      this.matrix.getRotation(this.quaternion),
      this.matrix.getScaling(this.scale),
      this.rotation.fromQuaternion(this.quaternion)
  }
  lookAt(t, e=!1) {
      e ? this.matrix.lookAt(this.position, t, this.up) : this.matrix.lookAt(t, this.position, this.up),
      this.matrix.getRotation(this.quaternion),
      this.rotation.fromQuaternion(this.quaternion)
  }
}
function Bn(r, t) {
  return r[0] = t[0],
  r[1] = t[1],
  r[2] = t[2],
  r[3] = t[4],
  r[4] = t[5],
  r[5] = t[6],
  r[6] = t[8],
  r[7] = t[9],
  r[8] = t[10],
  r
}
function Vn(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = e + e
    , h = i + i
    , l = s + s
    , o = e * a
    , u = i * a
    , c = i * h
    , d = s * a
    , p = s * h
    , g = s * l
    , f = n * a
    , m = n * h
    , _ = n * l;
  return r[0] = 1 - c - g,
  r[3] = u - _,
  r[6] = d + m,
  r[1] = u + _,
  r[4] = 1 - o - g,
  r[7] = p - f,
  r[2] = d - m,
  r[5] = p + f,
  r[8] = 1 - o - c,
  r
}
function Nn(r, t) {
  return r[0] = t[0],
  r[1] = t[1],
  r[2] = t[2],
  r[3] = t[3],
  r[4] = t[4],
  r[5] = t[5],
  r[6] = t[6],
  r[7] = t[7],
  r[8] = t[8],
  r
}
function Un(r, t, e, i, s, n, a, h, l, o) {
  return r[0] = t,
  r[1] = e,
  r[2] = i,
  r[3] = s,
  r[4] = n,
  r[5] = a,
  r[6] = h,
  r[7] = l,
  r[8] = o,
  r
}
function Yn(r) {
  return r[0] = 1,
  r[1] = 0,
  r[2] = 0,
  r[3] = 0,
  r[4] = 1,
  r[5] = 0,
  r[6] = 0,
  r[7] = 0,
  r[8] = 1,
  r
}
function Xn(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = t[4]
    , h = t[5]
    , l = t[6]
    , o = t[7]
    , u = t[8]
    , c = u * a - h * o
    , d = -u * n + h * l
    , p = o * n - a * l
    , g = e * c + i * d + s * p;
  return g ? (g = 1 / g,
  r[0] = c * g,
  r[1] = (-u * i + s * o) * g,
  r[2] = (h * i - s * a) * g,
  r[3] = d * g,
  r[4] = (u * e - s * l) * g,
  r[5] = (-h * e + s * n) * g,
  r[6] = p * g,
  r[7] = (-o * e + i * l) * g,
  r[8] = (a * e - i * n) * g,
  r) : null
}
function Xi(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = t[4]
    , l = t[5]
    , o = t[6]
    , u = t[7]
    , c = t[8]
    , d = e[0]
    , p = e[1]
    , g = e[2]
    , f = e[3]
    , m = e[4]
    , _ = e[5]
    , y = e[6]
    , x = e[7]
    , b = e[8];
  return r[0] = d * i + p * a + g * o,
  r[1] = d * s + p * h + g * u,
  r[2] = d * n + p * l + g * c,
  r[3] = f * i + m * a + _ * o,
  r[4] = f * s + m * h + _ * u,
  r[5] = f * n + m * l + _ * c,
  r[6] = y * i + x * a + b * o,
  r[7] = y * s + x * h + b * u,
  r[8] = y * n + x * l + b * c,
  r
}
function Gn(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = t[4]
    , l = t[5]
    , o = t[6]
    , u = t[7]
    , c = t[8]
    , d = e[0]
    , p = e[1];
  return r[0] = i,
  r[1] = s,
  r[2] = n,
  r[3] = a,
  r[4] = h,
  r[5] = l,
  r[6] = d * i + p * a + o,
  r[7] = d * s + p * h + u,
  r[8] = d * n + p * l + c,
  r
}
function qn(r, t, e) {
  let i = t[0]
    , s = t[1]
    , n = t[2]
    , a = t[3]
    , h = t[4]
    , l = t[5]
    , o = t[6]
    , u = t[7]
    , c = t[8]
    , d = Math.sin(e)
    , p = Math.cos(e);
  return r[0] = p * i + d * a,
  r[1] = p * s + d * h,
  r[2] = p * n + d * l,
  r[3] = p * a - d * i,
  r[4] = p * h - d * s,
  r[5] = p * l - d * n,
  r[6] = o,
  r[7] = u,
  r[8] = c,
  r
}
function Wn(r, t, e) {
  let i = e[0]
    , s = e[1];
  return r[0] = i * t[0],
  r[1] = i * t[1],
  r[2] = i * t[2],
  r[3] = s * t[3],
  r[4] = s * t[4],
  r[5] = s * t[5],
  r[6] = t[6],
  r[7] = t[7],
  r[8] = t[8],
  r
}
function Hn(r, t) {
  let e = t[0]
    , i = t[1]
    , s = t[2]
    , n = t[3]
    , a = t[4]
    , h = t[5]
    , l = t[6]
    , o = t[7]
    , u = t[8]
    , c = t[9]
    , d = t[10]
    , p = t[11]
    , g = t[12]
    , f = t[13]
    , m = t[14]
    , _ = t[15]
    , y = e * h - i * a
    , x = e * l - s * a
    , b = e * o - n * a
    , v = i * l - s * h
    , w = i * o - n * h
    , T = s * o - n * l
    , E = u * f - c * g
    , A = u * m - d * g
    , S = u * _ - p * g
    , C = c * m - d * f
    , O = c * _ - p * f
    , P = d * _ - p * m
    , M = y * P - x * O + b * C + v * S - w * A + T * E;
  return M ? (M = 1 / M,
  r[0] = (h * P - l * O + o * C) * M,
  r[1] = (l * S - a * P - o * A) * M,
  r[2] = (a * O - h * S + o * E) * M,
  r[3] = (s * O - i * P - n * C) * M,
  r[4] = (e * P - s * S + n * A) * M,
  r[5] = (i * S - e * O - n * E) * M,
  r[6] = (f * T - m * w + _ * v) * M,
  r[7] = (m * b - g * T - _ * x) * M,
  r[8] = (g * w - f * b + _ * y) * M,
  r) : null
}
class jn extends Array {
  constructor(t=1, e=0, i=0, s=0, n=1, a=0, h=0, l=0, o=1) {
      return super(t, e, i, s, n, a, h, l, o),
      this
  }
  set(t, e, i, s, n, a, h, l, o) {
      return t.length ? this.copy(t) : (Un(this, t, e, i, s, n, a, h, l, o),
      this)
  }
  translate(t, e=this) {
      return Gn(this, e, t),
      this
  }
  rotate(t, e=this) {
      return qn(this, e, t),
      this
  }
  scale(t, e=this) {
      return Wn(this, e, t),
      this
  }
  multiply(t, e) {
      return e ? Xi(this, t, e) : Xi(this, this, t),
      this
  }
  identity() {
      return Yn(this),
      this
  }
  copy(t) {
      return Nn(this, t),
      this
  }
  fromMatrix4(t) {
      return Bn(this, t),
      this
  }
  fromQuaternion(t) {
      return Vn(this, t),
      this
  }
  fromBasis(t, e, i) {
      return this.set(t[0], t[1], t[2], e[0], e[1], e[2], i[0], i[1], i[2]),
      this
  }
  inverse(t=this) {
      return Xn(this, t),
      this
  }
  getNormalMatrix(t) {
      return Hn(this, t),
      this
  }
}
let Zn = 0;
class kh extends In {
  constructor(t, {geometry: e, program: i, mode: s=t.TRIANGLES, frustumCulled: n=!0, renderOrder: a=0}={}) {
      super(),
      t.canvas || console.error("gl not passed as first argument to Mesh"),
      this.gl = t,
      this.id = Zn++,
      this.geometry = e,
      this.program = i,
      this.mode = s,
      this.frustumCulled = n,
      this.renderOrder = a,
      this.modelViewMatrix = new ve,
      this.normalMatrix = new jn,
      this.beforeRenderCallbacks = [],
      this.afterRenderCallbacks = []
  }
  onBeforeRender(t) {
      return this.beforeRenderCallbacks.push(t),
      this
  }
  onAfterRender(t) {
      return this.afterRenderCallbacks.push(t),
      this
  }
  draw({camera: t}={}) {
      this.beforeRenderCallbacks.forEach(i => i && i({
          mesh: this,
          camera: t
      })),
      t && (this.program.uniforms.modelMatrix || Object.assign(this.program.uniforms, {
          modelMatrix: {
              value: null
          },
          viewMatrix: {
              value: null
          },
          modelViewMatrix: {
              value: null
          },
          normalMatrix: {
              value: null
          },
          projectionMatrix: {
              value: null
          },
          cameraPosition: {
              value: null
          }
      }),
      this.program.uniforms.projectionMatrix.value = t.projectionMatrix,
      this.program.uniforms.cameraPosition.value = t.worldPosition,
      this.program.uniforms.viewMatrix.value = t.viewMatrix,
      this.modelViewMatrix.multiply(t.viewMatrix, this.worldMatrix),
      this.normalMatrix.getNormalMatrix(this.modelViewMatrix),
      this.program.uniforms.modelMatrix.value = this.worldMatrix,
      this.program.uniforms.modelViewMatrix.value = this.modelViewMatrix,
      this.program.uniforms.normalMatrix.value = this.normalMatrix);
      let e = this.program.cullFace && this.worldMatrix.determinant() < 0;
      this.program.use({
          flipFaces: e
      }),
      this.geometry.draw({
          mode: this.mode,
          program: this.program
      }),
      this.afterRenderCallbacks.forEach(i => i && i({
          mesh: this,
          camera: t
      }))
  }
}
const Gi = new Uint8Array(4);
function qi(r) {
  return (r & r - 1) === 0
}
let Kn = 1;
class Fh {
  constructor(t, {image: e, target: i=t.TEXTURE_2D, type: s=t.UNSIGNED_BYTE, format: n=t.RGBA, internalFormat: a=n, wrapS: h=t.CLAMP_TO_EDGE, wrapT: l=t.CLAMP_TO_EDGE, generateMipmaps: o=!0, minFilter: u=o ? t.NEAREST_MIPMAP_LINEAR : t.LINEAR, magFilter: c=t.LINEAR, premultiplyAlpha: d=!1, unpackAlignment: p=4, flipY: g=i == t.TEXTURE_2D, anisotropy: f=0, level: m=0, width: _, height: y=_}={}) {
      this.gl = t,
      this.id = Kn++,
      this.image = e,
      this.target = i,
      this.type = s,
      this.format = n,
      this.internalFormat = a,
      this.minFilter = u,
      this.magFilter = c,
      this.wrapS = h,
      this.wrapT = l,
      this.generateMipmaps = o,
      this.premultiplyAlpha = d,
      this.unpackAlignment = p,
      this.flipY = g,
      this.anisotropy = Math.min(f, this.gl.renderer.parameters.maxAnisotropy),
      this.level = m,
      this.width = _,
      this.height = y,
      this.texture = this.gl.createTexture(),
      this.store = {
          image: null
      },
      this.glState = this.gl.renderer.state,
      this.state = {},
      this.state.minFilter = this.gl.NEAREST_MIPMAP_LINEAR,
      this.state.magFilter = this.gl.LINEAR,
      this.state.wrapS = this.gl.REPEAT,
      this.state.wrapT = this.gl.REPEAT,
      this.state.anisotropy = 0
  }
  bind() {
      this.glState.textureUnits[this.glState.activeTextureUnit] !== this.id && (this.gl.bindTexture(this.target, this.texture),
      this.glState.textureUnits[this.glState.activeTextureUnit] = this.id)
  }
  update(t=0) {
      const e = !(this.image === this.store.image && !this.needsUpdate);
      if ((e || this.glState.textureUnits[t] !== this.id) && (this.gl.renderer.activeTexture(t),
      this.bind()),
      !!e) {
          if (this.needsUpdate = !1,
          this.flipY !== this.glState.flipY && (this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, this.flipY),
          this.glState.flipY = this.flipY),
          this.premultiplyAlpha !== this.glState.premultiplyAlpha && (this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, this.premultiplyAlpha),
          this.glState.premultiplyAlpha = this.premultiplyAlpha),
          this.unpackAlignment !== this.glState.unpackAlignment && (this.gl.pixelStorei(this.gl.UNPACK_ALIGNMENT, this.unpackAlignment),
          this.glState.unpackAlignment = this.unpackAlignment),
          this.minFilter !== this.state.minFilter && (this.gl.texParameteri(this.target, this.gl.TEXTURE_MIN_FILTER, this.minFilter),
          this.state.minFilter = this.minFilter),
          this.magFilter !== this.state.magFilter && (this.gl.texParameteri(this.target, this.gl.TEXTURE_MAG_FILTER, this.magFilter),
          this.state.magFilter = this.magFilter),
          this.wrapS !== this.state.wrapS && (this.gl.texParameteri(this.target, this.gl.TEXTURE_WRAP_S, this.wrapS),
          this.state.wrapS = this.wrapS),
          this.wrapT !== this.state.wrapT && (this.gl.texParameteri(this.target, this.gl.TEXTURE_WRAP_T, this.wrapT),
          this.state.wrapT = this.wrapT),
          this.anisotropy && this.anisotropy !== this.state.anisotropy && (this.gl.texParameterf(this.target, this.gl.renderer.getExtension("EXT_texture_filter_anisotropic").TEXTURE_MAX_ANISOTROPY_EXT, this.anisotropy),
          this.state.anisotropy = this.anisotropy),
          this.image) {
              if (this.image.width && (this.width = this.image.width,
              this.height = this.image.height),
              this.target === this.gl.TEXTURE_CUBE_MAP)
                  for (let i = 0; i < 6; i++)
                      this.gl.texImage2D(this.gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, this.level, this.internalFormat, this.format, this.type, this.image[i]);
              else if (ArrayBuffer.isView(this.image))
                  this.gl.texImage2D(this.target, this.level, this.internalFormat, this.width, this.height, 0, this.format, this.type, this.image);
              else if (this.image.isCompressedTexture)
                  for (let i = 0; i < this.image.length; i++)
                      this.gl.compressedTexImage2D(this.target, i, this.internalFormat, this.image[i].width, this.image[i].height, 0, this.image[i].data);
              else
                  this.gl.texImage2D(this.target, this.level, this.internalFormat, this.format, this.type, this.image);
              this.generateMipmaps && (!this.gl.renderer.isWebgl2 && (!qi(this.image.width) || !qi(this.image.height)) ? (this.generateMipmaps = !1,
              this.wrapS = this.wrapT = this.gl.CLAMP_TO_EDGE,
              this.minFilter = this.gl.LINEAR) : this.gl.generateMipmap(this.target)),
              this.onUpdate && this.onUpdate()
          } else if (this.target === this.gl.TEXTURE_CUBE_MAP)
              for (let i = 0; i < 6; i++)
                  this.gl.texImage2D(this.gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, Gi);
          else
              this.width ? this.gl.texImage2D(this.target, this.level, this.internalFormat, this.width, this.height, 0, this.format, this.type, null) : this.gl.texImage2D(this.target, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, Gi);
          this.store.image = this.image
      }
  }
}
const Wi = {
  black: "#000000",
  white: "#ffffff",
  red: "#ff0000",
  green: "#00ff00",
  blue: "#0000ff",
  fuchsia: "#ff00ff",
  cyan: "#00ffff",
  yellow: "#ffff00",
  orange: "#ff8000"
};
function Hi(r) {
  r.length === 4 && (r = r[0] + r[1] + r[1] + r[2] + r[2] + r[3] + r[3]);
  const t = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(r);
  return t || console.warn(`Unable to convert hex string ${r} to rgb values`),
  [parseInt(t[1], 16) / 255, parseInt(t[2], 16) / 255, parseInt(t[3], 16) / 255]
}
function Qn(r) {
  return r = parseInt(r),
  [(r >> 16 & 255) / 255, (r >> 8 & 255) / 255, (r & 255) / 255]
}
function ji(r) {
  return r === void 0 ? [0, 0, 0] : arguments.length === 3 ? arguments : isNaN(r) ? r[0] === "#" ? Hi(r) : Wi[r.toLowerCase()] ? Hi(Wi[r.toLowerCase()]) : (console.warn("Color format not recognised"),
  [0, 0, 0]) : Qn(r)
}
class Dh extends Array {
  constructor(t) {
      return Array.isArray(t) ? super(...t) : super(...ji(...arguments))
  }
  get r() {
      return this[0]
  }
  get g() {
      return this[1]
  }
  get b() {
      return this[2]
  }
  set r(t) {
      this[0] = t
  }
  set g(t) {
      this[1] = t
  }
  set b(t) {
      this[2] = t
  }
  set(t) {
      return Array.isArray(t) ? this.copy(t) : this.copy(ji(...arguments))
  }
  copy(t) {
      return this[0] = t[0],
      this[1] = t[1],
      this[2] = t[2],
      this
  }
}
function Jn(r, t) {
  return r[0] = t[0],
  r[1] = t[1],
  r
}
function ta(r, t, e) {
  return r[0] = t,
  r[1] = e,
  r
}
function Zi(r, t, e) {
  return r[0] = t[0] + e[0],
  r[1] = t[1] + e[1],
  r
}
function Ki(r, t, e) {
  return r[0] = t[0] - e[0],
  r[1] = t[1] - e[1],
  r
}
function ea(r, t, e) {
  return r[0] = t[0] * e[0],
  r[1] = t[1] * e[1],
  r
}
function ia(r, t, e) {
  return r[0] = t[0] / e[0],
  r[1] = t[1] / e[1],
  r
}
function Ie(r, t, e) {
  return r[0] = t[0] * e,
  r[1] = t[1] * e,
  r
}
function ra(r, t) {
  var e = t[0] - r[0]
    , i = t[1] - r[1];
  return Math.sqrt(e * e + i * i)
}
function sa(r, t) {
  var e = t[0] - r[0]
    , i = t[1] - r[1];
  return e * e + i * i
}
function Qi(r) {
  var t = r[0]
    , e = r[1];
  return Math.sqrt(t * t + e * e)
}
function na(r) {
  var t = r[0]
    , e = r[1];
  return t * t + e * e
}
function aa(r, t) {
  return r[0] = -t[0],
  r[1] = -t[1],
  r
}
function ha(r, t) {
  return r[0] = 1 / t[0],
  r[1] = 1 / t[1],
  r
}
function la(r, t) {
  var e = t[0]
    , i = t[1]
    , s = e * e + i * i;
  return s > 0 && (s = 1 / Math.sqrt(s)),
  r[0] = t[0] * s,
  r[1] = t[1] * s,
  r
}
function oa(r, t) {
  return r[0] * t[0] + r[1] * t[1]
}
function Ji(r, t) {
  return r[0] * t[1] - r[1] * t[0]
}
function ua(r, t, e, i) {
  var s = t[0]
    , n = t[1];
  return r[0] = s + i * (e[0] - s),
  r[1] = n + i * (e[1] - n),
  r
}
function fa(r, t, e) {
  var i = t[0]
    , s = t[1];
  return r[0] = e[0] * i + e[3] * s + e[6],
  r[1] = e[1] * i + e[4] * s + e[7],
  r
}
function da(r, t, e) {
  let i = t[0]
    , s = t[1];
  return r[0] = e[0] * i + e[4] * s + e[12],
  r[1] = e[1] * i + e[5] * s + e[13],
  r
}
function ca(r, t) {
  return r[0] === t[0] && r[1] === t[1]
}
class xr extends Array {
  constructor(t=0, e=t) {
      return super(t, e),
      this
  }
  get x() {
      return this[0]
  }
  get y() {
      return this[1]
  }
  set x(t) {
      this[0] = t
  }
  set y(t) {
      this[1] = t
  }
  set(t, e=t) {
      return t.length ? this.copy(t) : (ta(this, t, e),
      this)
  }
  copy(t) {
      return Jn(this, t),
      this
  }
  add(t, e) {
      return e ? Zi(this, t, e) : Zi(this, this, t),
      this
  }
  sub(t, e) {
      return e ? Ki(this, t, e) : Ki(this, this, t),
      this
  }
  multiply(t) {
      return t.length ? ea(this, this, t) : Ie(this, this, t),
      this
  }
  divide(t) {
      return t.length ? ia(this, this, t) : Ie(this, this, 1 / t),
      this
  }
  inverse(t=this) {
      return ha(this, t),
      this
  }
  len() {
      return Qi(this)
  }
  distance(t) {
      return t ? ra(this, t) : Qi(this)
  }
  squaredLen() {
      return this.squaredDistance()
  }
  squaredDistance(t) {
      return t ? sa(this, t) : na(this)
  }
  negate(t=this) {
      return aa(this, t),
      this
  }
  cross(t, e) {
      return e ? Ji(t, e) : Ji(this, t)
  }
  scale(t) {
      return Ie(this, this, t),
      this
  }
  normalize() {
      return la(this, this),
      this
  }
  dot(t) {
      return oa(this, t)
  }
  equals(t) {
      return ca(this, t)
  }
  applyMatrix3(t) {
      return fa(this, this, t),
      this
  }
  applyMatrix4(t) {
      return da(this, this, t),
      this
  }
  lerp(t, e) {
      return ua(this, this, t, e),
      this
  }
  clone() {
      return new xr(this[0],this[1])
  }
  fromArray(t, e=0) {
      return this[0] = t[e],
      this[1] = t[e + 1],
      this
  }
  toArray(t=[], e=0) {
      return t[e] = this[0],
      t[e + 1] = this[1],
      t
  }
}
class Lh extends Ws {
  constructor(t, {attributes: e={}}={}) {
      Object.assign(e, {
          position: {
              size: 2,
              data: new Float32Array([-1, -1, 3, -1, -1, 3])
          },
          uv: {
              size: 2,
              data: new Float32Array([0, 0, 2, 0, 0, 2])
          }
      }),
      super(t, e)
  }
}
function yt(r) {
  if (r === void 0)
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return r
}
function br(r, t) {
  r.prototype = Object.create(t.prototype),
  r.prototype.constructor = r,
  r.__proto__ = t
}
/*!
* GSAP 3.12.1
* https://greensock.com
*
* @license Copyright 2008-2023, GreenSock. All rights reserved.
* Subject to the terms at https://greensock.com/standard-license or for
* Club GreenSock members, the agreement issued with that membership.
* @author: Jack Doyle, jack@greensock.com
*/
var it = {
  autoSleep: 120,
  force3D: "auto",
  nullTargetWarn: 1,
  units: {
      lineHeight: ""
  }
}, Zt = {
  duration: .5,
  overwrite: !1,
  delay: 0
}, di, q, z, at = 1e8, L = 1 / at, Ke = Math.PI * 2, pa = Ke / 4, _a = 0, vr = Math.sqrt, ga = Math.cos, ma = Math.sin, Y = function(t) {
  return typeof t == "string"
}, I = function(t) {
  return typeof t == "function"
}, bt = function(t) {
  return typeof t == "number"
}, ci = function(t) {
  return typeof t > "u"
}, gt = function(t) {
  return typeof t == "object"
}, Z = function(t) {
  return t !== !1
}, pi = function() {
  return typeof window < "u"
}, me = function(t) {
  return I(t) || Y(t)
}, wr = typeof ArrayBuffer == "function" && ArrayBuffer.isView || function() {}
, W = Array.isArray, Qe = /(?:-?\.?\d|\.)+/gi, Ar = /[-+=.]*\d+[.e\-+]*\d*[e\-+]*\d*/g, Gt = /[-+=.]*\d+[.e-]*\d*[a-z%]*/g, Be = /[-+=.]*\d+\.?\d*(?:e-|e\+)?\d*/gi, Er = /[+-]=-?[.\d]+/, Tr = /[^,'"\[\]\s]+/gi, ya = /^[+\-=e\s\d]*\d+[.\d]*([a-z]*|%)\s*$/i, R, nt, Je, _i, rt = {}, we = {}, Mr, Sr = function(t) {
  return (we = Ut(t, rt)) && J
}, gi = function(t, e) {
  return console.warn("Invalid property", t, "set to", e, "Missing plugin? gsap.registerPlugin()")
}, Ae = function(t, e) {
  return !e && console.warn(t)
}, Cr = function(t, e) {
  return t && (rt[t] = e) && we && (we[t] = e) || rt
}, oe = function() {
  return 0
}, xa = {
  suppressEvents: !0,
  isStart: !0,
  kill: !1
}, ye = {
  suppressEvents: !0,
  kill: !1
}, ba = {
  suppressEvents: !0
}, mi = {}, Tt = [], ti = {}, Or, tt = {}, Ve = {}, tr = 30, xe = [], yi = "", xi = function(t) {
  var e = t[0], i, s;
  if (gt(e) || I(e) || (t = [t]),
  !(i = (e._gsap || {}).harness)) {
      for (s = xe.length; s-- && !xe[s].targetTest(e); )
          ;
      i = xe[s]
  }
  for (s = t.length; s--; )
      t[s] && (t[s]._gsap || (t[s]._gsap = new Jr(t[s],i))) || t.splice(s, 1);
  return t
}, It = function(t) {
  return t._gsap || xi(ht(t))[0]._gsap
}, Pr = function(t, e, i) {
  return (i = t[e]) && I(i) ? t[e]() : ci(i) && t.getAttribute && t.getAttribute(e) || i
}, K = function(t, e) {
  return (t = t.split(",")).forEach(e) || t
}, B = function(t) {
  return Math.round(t * 1e5) / 1e5 || 0
}, X = function(t) {
  return Math.round(t * 1e7) / 1e7 || 0
}, Wt = function(t, e) {
  var i = e.charAt(0)
    , s = parseFloat(e.substr(2));
  return t = parseFloat(t),
  i === "+" ? t + s : i === "-" ? t - s : i === "*" ? t * s : t / s
}, va = function(t, e) {
  for (var i = e.length, s = 0; t.indexOf(e[s]) < 0 && ++s < i; )
      ;
  return s < i
}, Ee = function() {
  var t = Tt.length, e = Tt.slice(0), i, s;
  for (ti = {},
  Tt.length = 0,
  i = 0; i < t; i++)
      s = e[i],
      s && s._lazy && (s.render(s._lazy[0], s._lazy[1], !0)._lazy = 0)
}, kr = function(t, e, i, s) {
  Tt.length && !q && Ee(),
  t.render(e, i, s || q && e < 0 && (t._initted || t._startAt)),
  Tt.length && !q && Ee()
}, Fr = function(t) {
  var e = parseFloat(t);
  return (e || e === 0) && (t + "").match(Tr).length < 2 ? e : Y(t) ? t.trim() : t
}, Dr = function(t) {
  return t
}, ot = function(t, e) {
  for (var i in e)
      i in t || (t[i] = e[i]);
  return t
}, wa = function(t) {
  return function(e, i) {
      for (var s in i)
          s in e || s === "duration" && t || s === "ease" || (e[s] = i[s])
  }
}, Ut = function(t, e) {
  for (var i in e)
      t[i] = e[i];
  return t
}, er = function r(t, e) {
  for (var i in e)
      i !== "__proto__" && i !== "constructor" && i !== "prototype" && (t[i] = gt(e[i]) ? r(t[i] || (t[i] = {}), e[i]) : e[i]);
  return t
}, Te = function(t, e) {
  var i = {}, s;
  for (s in t)
      s in e || (i[s] = t[s]);
  return i
}, ne = function(t) {
  var e = t.parent || R
    , i = t.keyframes ? wa(W(t.keyframes)) : ot;
  if (Z(t.inherit))
      for (; e; )
          i(t, e.vars.defaults),
          e = e.parent || e._dp;
  return t
}, Aa = function(t, e) {
  for (var i = t.length, s = i === e.length; s && i-- && t[i] === e[i]; )
      ;
  return i < 0
}, Lr = function(t, e, i, s, n) {
  i === void 0 && (i = "_first"),
  s === void 0 && (s = "_last");
  var a = t[s], h;
  if (n)
      for (h = e[n]; a && a[n] > h; )
          a = a._prev;
  return a ? (e._next = a._next,
  a._next = e) : (e._next = t[i],
  t[i] = e),
  e._next ? e._next._prev = e : t[s] = e,
  e._prev = a,
  e.parent = e._dp = t,
  e
}, Pe = function(t, e, i, s) {
  i === void 0 && (i = "_first"),
  s === void 0 && (s = "_last");
  var n = e._prev
    , a = e._next;
  n ? n._next = a : t[i] === e && (t[i] = a),
  a ? a._prev = n : t[s] === e && (t[s] = n),
  e._next = e._prev = e.parent = null
}, St = function(t, e) {
  t.parent && (!e || t.parent.autoRemoveChildren) && t.parent.remove && t.parent.remove(t),
  t._act = 0
}, Bt = function(t, e) {
  if (t && (!e || e._end > t._dur || e._start < 0))
      for (var i = t; i; )
          i._dirty = 1,
          i = i.parent;
  return t
}, Ea = function(t) {
  for (var e = t.parent; e && e.parent; )
      e._dirty = 1,
      e.totalDuration(),
      e = e.parent;
  return t
}, ei = function(t, e, i, s) {
  return t._startAt && (q ? t._startAt.revert(ye) : t.vars.immediateRender && !t.vars.autoRevert || t._startAt.render(e, !0, s))
}, Ta = function r(t) {
  return !t || t._ts && r(t.parent)
}, ir = function(t) {
  return t._repeat ? Kt(t._tTime, t = t.duration() + t._rDelay) * t : 0
}, Kt = function(t, e) {
  var i = Math.floor(t /= e);
  return t && i === t ? i - 1 : i
}, Me = function(t, e) {
  return (t - e._start) * e._ts + (e._ts >= 0 ? 0 : e._dirty ? e.totalDuration() : e._tDur)
}, ke = function(t) {
  return t._end = X(t._start + (t._tDur / Math.abs(t._ts || t._rts || L) || 0))
}, Fe = function(t, e) {
  var i = t._dp;
  return i && i.smoothChildTiming && t._ts && (t._start = X(i._time - (t._ts > 0 ? e / t._ts : ((t._dirty ? t.totalDuration() : t._tDur) - e) / -t._ts)),
  ke(t),
  i._dirty || Bt(i, t)),
  t
}, Rr = function(t, e) {
  var i;
  if ((e._time || e._initted && !e._dur) && (i = Me(t.rawTime(), e),
  (!e._dur || ge(0, e.totalDuration(), i) - e._tTime > L) && e.render(i, !0)),
  Bt(t, e)._dp && t._initted && t._time >= t._dur && t._ts) {
      if (t._dur < t.duration())
          for (i = t; i._dp; )
              i.rawTime() >= 0 && i.totalTime(i._tTime),
              i = i._dp;
      t._zTime = -L
  }
}, dt = function(t, e, i, s) {
  return e.parent && St(e),
  e._start = X((bt(i) ? i : i || t !== R ? st(t, i, e) : t._time) + e._delay),
  e._end = X(e._start + (e.totalDuration() / Math.abs(e.timeScale()) || 0)),
  Lr(t, e, "_first", "_last", t._sort ? "_start" : 0),
  ii(e) || (t._recent = e),
  s || Rr(t, e),
  t._ts < 0 && Fe(t, t._tTime),
  t
}, $r = function(t, e) {
  return (rt.ScrollTrigger || gi("scrollTrigger", e)) && rt.ScrollTrigger.create(e, t)
}, zr = function(t, e, i, s, n) {
  if (vi(t, e, n),
  !t._initted)
      return 1;
  if (!i && t._pt && !q && (t._dur && t.vars.lazy !== !1 || !t._dur && t.vars.lazy) && Or !== et.frame)
      return Tt.push(t),
      t._lazy = [n, s],
      1
}, Ma = function r(t) {
  var e = t.parent;
  return e && e._ts && e._initted && !e._lock && (e.rawTime() < 0 || r(e))
}, ii = function(t) {
  var e = t.data;
  return e === "isFromStart" || e === "isStart"
}, Sa = function(t, e, i, s) {
  var n = t.ratio, a = e < 0 || !e && (!t._start && Ma(t) && !(!t._initted && ii(t)) || (t._ts < 0 || t._dp._ts < 0) && !ii(t)) ? 0 : 1, h = t._rDelay, l = 0, o, u, c;
  if (h && t._repeat && (l = ge(0, t._tDur, e),
  u = Kt(l, h),
  t._yoyo && u & 1 && (a = 1 - a),
  u !== Kt(t._tTime, h) && (n = 1 - a,
  t.vars.repeatRefresh && t._initted && t.invalidate())),
  a !== n || q || s || t._zTime === L || !e && t._zTime) {
      if (!t._initted && zr(t, e, s, i, l))
          return;
      for (c = t._zTime,
      t._zTime = e || (i ? L : 0),
      i || (i = e && !c),
      t.ratio = a,
      t._from && (a = 1 - a),
      t._time = 0,
      t._tTime = l,
      o = t._pt; o; )
          o.r(a, o.d),
          o = o._next;
      e < 0 && ei(t, e, i, !0),
      t._onUpdate && !i && lt(t, "onUpdate"),
      l && t._repeat && !i && t.parent && lt(t, "onRepeat"),
      (e >= t._tDur || e < 0) && t.ratio === a && (a && St(t, 1),
      !i && !q && (lt(t, a ? "onComplete" : "onReverseComplete", !0),
      t._prom && t._prom()))
  } else
      t._zTime || (t._zTime = e)
}, Ca = function(t, e, i) {
  var s;
  if (i > e)
      for (s = t._first; s && s._start <= i; ) {
          if (s.data === "isPause" && s._start > e)
              return s;
          s = s._next
      }
  else
      for (s = t._last; s && s._start >= i; ) {
          if (s.data === "isPause" && s._start < e)
              return s;
          s = s._prev
      }
}, Qt = function(t, e, i, s) {
  var n = t._repeat
    , a = X(e) || 0
    , h = t._tTime / t._tDur;
  return h && !s && (t._time *= a / t._dur),
  t._dur = a,
  t._tDur = n ? n < 0 ? 1e10 : X(a * (n + 1) + t._rDelay * n) : a,
  h > 0 && !s && Fe(t, t._tTime = t._tDur * h),
  t.parent && ke(t),
  i || Bt(t.parent, t),
  t
}, rr = function(t) {
  return t instanceof j ? Bt(t) : Qt(t, t._dur)
}, Oa = {
  _start: 0,
  endTime: oe,
  totalDuration: oe
}, st = function r(t, e, i) {
  var s = t.labels, n = t._recent || Oa, a = t.duration() >= at ? n.endTime(!1) : t._dur, h, l, o;
  return Y(e) && (isNaN(e) || e in s) ? (l = e.charAt(0),
  o = e.substr(-1) === "%",
  h = e.indexOf("="),
  l === "<" || l === ">" ? (h >= 0 && (e = e.replace(/=/, "")),
  (l === "<" ? n._start : n.endTime(n._repeat >= 0)) + (parseFloat(e.substr(1)) || 0) * (o ? (h < 0 ? n : i).totalDuration() / 100 : 1)) : h < 0 ? (e in s || (s[e] = a),
  s[e]) : (l = parseFloat(e.charAt(h - 1) + e.substr(h + 1)),
  o && i && (l = l / 100 * (W(i) ? i[0] : i).totalDuration()),
  h > 1 ? r(t, e.substr(0, h - 1), i) + l : a + l)) : e == null ? a : +e
}, ae = function(t, e, i) {
  var s = bt(e[1]), n = (s ? 2 : 1) + (t < 2 ? 0 : 1), a = e[n], h, l;
  if (s && (a.duration = e[1]),
  a.parent = i,
  t) {
      for (h = a,
      l = i; l && !("immediateRender"in h); )
          h = l.vars.defaults || {},
          l = Z(l.vars.inherit) && l.parent;
      a.immediateRender = Z(h.immediateRender),
      t < 2 ? a.runBackwards = 1 : a.startAt = e[n - 1]
  }
  return new N(e[0],a,e[n + 1])
}, Ot = function(t, e) {
  return t || t === 0 ? e(t) : e
}, ge = function(t, e, i) {
  return i < t ? t : i > e ? e : i
}, G = function(t, e) {
  return !Y(t) || !(e = ya.exec(t)) ? "" : e[1]
}, Pa = function(t, e, i) {
  return Ot(i, function(s) {
      return ge(t, e, s)
  })
}, ri = [].slice, Ir = function(t, e) {
  return t && gt(t) && "length"in t && (!e && !t.length || t.length - 1 in t && gt(t[0])) && !t.nodeType && t !== nt
}, ka = function(t, e, i) {
  return i === void 0 && (i = []),
  t.forEach(function(s) {
      var n;
      return Y(s) && !e || Ir(s, 1) ? (n = i).push.apply(n, ht(s)) : i.push(s)
  }) || i
}, ht = function(t, e, i) {
  return z && !e && z.selector ? z.selector(t) : Y(t) && !i && (Je || !Jt()) ? ri.call((e || _i).querySelectorAll(t), 0) : W(t) ? ka(t, i) : Ir(t) ? ri.call(t, 0) : t ? [t] : []
}, si = function(t) {
  return t = ht(t)[0] || Ae("Invalid scope") || {},
  function(e) {
      var i = t.current || t.nativeElement || t;
      return ht(e, i.querySelectorAll ? i : i === t ? Ae("Invalid scope") || _i.createElement("div") : t)
  }
}, Br = function(t) {
  return t.sort(function() {
      return .5 - Math.random()
  })
}, Vr = function(t) {
  if (I(t))
      return t;
  var e = gt(t) ? t : {
      each: t
  }
    , i = Vt(e.ease)
    , s = e.from || 0
    , n = parseFloat(e.base) || 0
    , a = {}
    , h = s > 0 && s < 1
    , l = isNaN(s) || h
    , o = e.axis
    , u = s
    , c = s;
  return Y(s) ? u = c = {
      center: .5,
      edges: .5,
      end: 1
  }[s] || 0 : !h && l && (u = s[0],
  c = s[1]),
  function(d, p, g) {
      var f = (g || e).length, m = a[f], _, y, x, b, v, w, T, E, A;
      if (!m) {
          if (A = e.grid === "auto" ? 0 : (e.grid || [1, at])[1],
          !A) {
              for (T = -at; T < (T = g[A++].getBoundingClientRect().left) && A < f; )
                  ;
              A--
          }
          for (m = a[f] = [],
          _ = l ? Math.min(A, f) * u - .5 : s % A,
          y = A === at ? 0 : l ? f * c / A - .5 : s / A | 0,
          T = 0,
          E = at,
          w = 0; w < f; w++)
              x = w % A - _,
              b = y - (w / A | 0),
              m[w] = v = o ? Math.abs(o === "y" ? b : x) : vr(x * x + b * b),
              v > T && (T = v),
              v < E && (E = v);
          s === "random" && Br(m),
          m.max = T - E,
          m.min = E,
          m.v = f = (parseFloat(e.amount) || parseFloat(e.each) * (A > f ? f - 1 : o ? o === "y" ? f / A : A : Math.max(A, f / A)) || 0) * (s === "edges" ? -1 : 1),
          m.b = f < 0 ? n - f : n,
          m.u = G(e.amount || e.each) || 0,
          i = i && f < 0 ? Zr(i) : i
      }
      return f = (m[d] - m.min) / m.max || 0,
      X(m.b + (i ? i(f) : f) * m.v) + m.u
  }
}, ni = function(t) {
  var e = Math.pow(10, ((t + "").split(".")[1] || "").length);
  return function(i) {
      var s = X(Math.round(parseFloat(i) / t) * t * e);
      return (s - s % 1) / e + (bt(i) ? 0 : G(i))
  }
}, Nr = function(t, e) {
  var i = W(t), s, n;
  return !i && gt(t) && (s = i = t.radius || at,
  t.values ? (t = ht(t.values),
  (n = !bt(t[0])) && (s *= s)) : t = ni(t.increment)),
  Ot(e, i ? I(t) ? function(a) {
      return n = t(a),
      Math.abs(n - a) <= s ? n : a
  }
  : function(a) {
      for (var h = parseFloat(n ? a.x : a), l = parseFloat(n ? a.y : 0), o = at, u = 0, c = t.length, d, p; c--; )
          n ? (d = t[c].x - h,
          p = t[c].y - l,
          d = d * d + p * p) : d = Math.abs(t[c] - h),
          d < o && (o = d,
          u = c);
      return u = !s || o <= s ? t[u] : a,
      n || u === a || bt(a) ? u : u + G(a)
  }
  : ni(t))
}, Ur = function(t, e, i, s) {
  return Ot(W(t) ? !e : i === !0 ? !!(i = 0) : !s, function() {
      return W(t) ? t[~~(Math.random() * t.length)] : (i = i || 1e-5) && (s = i < 1 ? Math.pow(10, (i + "").length - 2) : 1) && Math.floor(Math.round((t - i / 2 + Math.random() * (e - t + i * .99)) / i) * i * s) / s
  })
}, Fa = function() {
  for (var t = arguments.length, e = new Array(t), i = 0; i < t; i++)
      e[i] = arguments[i];
  return function(s) {
      return e.reduce(function(n, a) {
          return a(n)
      }, s)
  }
}, Da = function(t, e) {
  return function(i) {
      return t(parseFloat(i)) + (e || G(i))
  }
}, La = function(t, e, i) {
  return Xr(t, e, 0, 1, i)
}, Yr = function(t, e, i) {
  return Ot(i, function(s) {
      return t[~~e(s)]
  })
}, Ra = function r(t, e, i) {
  var s = e - t;
  return W(t) ? Yr(t, r(0, t.length), e) : Ot(i, function(n) {
      return (s + (n - t) % s) % s + t
  })
}, $a = function r(t, e, i) {
  var s = e - t
    , n = s * 2;
  return W(t) ? Yr(t, r(0, t.length - 1), e) : Ot(i, function(a) {
      return a = (n + (a - t) % n) % n || 0,
      t + (a > s ? n - a : a)
  })
}, ue = function(t) {
  for (var e = 0, i = "", s, n, a, h; ~(s = t.indexOf("random(", e)); )
      a = t.indexOf(")", s),
      h = t.charAt(s + 7) === "[",
      n = t.substr(s + 7, a - s - 7).match(h ? Tr : Qe),
      i += t.substr(e, s - e) + Ur(h ? n : +n[0], h ? 0 : +n[1], +n[2] || 1e-5),
      e = a + 1;
  return i + t.substr(e, t.length - e)
}, Xr = function(t, e, i, s, n) {
  var a = e - t
    , h = s - i;
  return Ot(n, function(l) {
      return i + ((l - t) / a * h || 0)
  })
}, za = function r(t, e, i, s) {
  var n = isNaN(t + e) ? 0 : function(p) {
      return (1 - p) * t + p * e
  }
  ;
  if (!n) {
      var a = Y(t), h = {}, l, o, u, c, d;
      if (i === !0 && (s = 1) && (i = null),
      a)
          t = {
              p: t
          },
          e = {
              p: e
          };
      else if (W(t) && !W(e)) {
          for (u = [],
          c = t.length,
          d = c - 2,
          o = 1; o < c; o++)
              u.push(r(t[o - 1], t[o]));
          c--,
          n = function(g) {
              g *= c;
              var f = Math.min(d, ~~g);
              return u[f](g - f)
          }
          ,
          i = e
      } else
          s || (t = Ut(W(t) ? [] : {}, t));
      if (!u) {
          for (l in e)
              bi.call(h, t, l, "get", e[l]);
          n = function(g) {
              return Ei(g, h) || (a ? t.p : t)
          }
      }
  }
  return Ot(i, n)
}, sr = function(t, e, i) {
  var s = t.labels, n = at, a, h, l;
  for (a in s)
      h = s[a] - e,
      h < 0 == !!i && h && n > (h = Math.abs(h)) && (l = a,
      n = h);
  return l
}, lt = function(t, e, i) {
  var s = t.vars, n = s[e], a = z, h = t._ctx, l, o, u;
  if (n)
      return l = s[e + "Params"],
      o = s.callbackScope || t,
      i && Tt.length && Ee(),
      h && (z = h),
      u = l ? n.apply(o, l) : n.call(o),
      z = a,
      u
}, re = function(t) {
  return St(t),
  t.scrollTrigger && t.scrollTrigger.kill(!!q),
  t.progress() < 1 && lt(t, "onInterrupt"),
  t
}, qt, Gr = [], qr = function(t) {
  if (pi() && t) {
      t = !t.name && t.default || t;
      var e = t.name
        , i = I(t)
        , s = e && !i && t.init ? function() {
          this._props = []
      }
      : t
        , n = {
          init: oe,
          render: Ei,
          add: bi,
          kill: Ja,
          modifier: Qa,
          rawVars: 0
      }
        , a = {
          targetTest: 0,
          get: 0,
          getSetter: Ai,
          aliases: {},
          register: 0
      };
      if (Jt(),
      t !== s) {
          if (tt[e])
              return;
          ot(s, ot(Te(t, n), a)),
          Ut(s.prototype, Ut(n, Te(t, a))),
          tt[s.prop = e] = s,
          t.targetTest && (xe.push(s),
          mi[e] = 1),
          e = (e === "css" ? "CSS" : e.charAt(0).toUpperCase() + e.substr(1)) + "Plugin"
      }
      Cr(e, s),
      t.register && t.register(J, s, Q)
  } else
      t && Gr.push(t)
}, D = 255, se = {
  aqua: [0, D, D],
  lime: [0, D, 0],
  silver: [192, 192, 192],
  black: [0, 0, 0],
  maroon: [128, 0, 0],
  teal: [0, 128, 128],
  blue: [0, 0, D],
  navy: [0, 0, 128],
  white: [D, D, D],
  olive: [128, 128, 0],
  yellow: [D, D, 0],
  orange: [D, 165, 0],
  gray: [128, 128, 128],
  purple: [128, 0, 128],
  green: [0, 128, 0],
  red: [D, 0, 0],
  pink: [D, 192, 203],
  cyan: [0, D, D],
  transparent: [D, D, D, 0]
}, Ne = function(t, e, i) {
  return t += t < 0 ? 1 : t > 1 ? -1 : 0,
  (t * 6 < 1 ? e + (i - e) * t * 6 : t < .5 ? i : t * 3 < 2 ? e + (i - e) * (2 / 3 - t) * 6 : e) * D + .5 | 0
}, Wr = function(t, e, i) {
  var s = t ? bt(t) ? [t >> 16, t >> 8 & D, t & D] : 0 : se.black, n, a, h, l, o, u, c, d, p, g;
  if (!s) {
      if (t.substr(-1) === "," && (t = t.substr(0, t.length - 1)),
      se[t])
          s = se[t];
      else if (t.charAt(0) === "#") {
          if (t.length < 6 && (n = t.charAt(1),
          a = t.charAt(2),
          h = t.charAt(3),
          t = "#" + n + n + a + a + h + h + (t.length === 5 ? t.charAt(4) + t.charAt(4) : "")),
          t.length === 9)
              return s = parseInt(t.substr(1, 6), 16),
              [s >> 16, s >> 8 & D, s & D, parseInt(t.substr(7), 16) / 255];
          t = parseInt(t.substr(1), 16),
          s = [t >> 16, t >> 8 & D, t & D]
      } else if (t.substr(0, 3) === "hsl") {
          if (s = g = t.match(Qe),
          !e)
              l = +s[0] % 360 / 360,
              o = +s[1] / 100,
              u = +s[2] / 100,
              a = u <= .5 ? u * (o + 1) : u + o - u * o,
              n = u * 2 - a,
              s.length > 3 && (s[3] *= 1),
              s[0] = Ne(l + 1 / 3, n, a),
              s[1] = Ne(l, n, a),
              s[2] = Ne(l - 1 / 3, n, a);
          else if (~t.indexOf("="))
              return s = t.match(Ar),
              i && s.length < 4 && (s[3] = 1),
              s
      } else
          s = t.match(Qe) || se.transparent;
      s = s.map(Number)
  }
  return e && !g && (n = s[0] / D,
  a = s[1] / D,
  h = s[2] / D,
  c = Math.max(n, a, h),
  d = Math.min(n, a, h),
  u = (c + d) / 2,
  c === d ? l = o = 0 : (p = c - d,
  o = u > .5 ? p / (2 - c - d) : p / (c + d),
  l = c === n ? (a - h) / p + (a < h ? 6 : 0) : c === a ? (h - n) / p + 2 : (n - a) / p + 4,
  l *= 60),
  s[0] = ~~(l + .5),
  s[1] = ~~(o * 100 + .5),
  s[2] = ~~(u * 100 + .5)),
  i && s.length < 4 && (s[3] = 1),
  s
}, Hr = function(t) {
  var e = []
    , i = []
    , s = -1;
  return t.split(Mt).forEach(function(n) {
      var a = n.match(Gt) || [];
      e.push.apply(e, a),
      i.push(s += a.length + 1)
  }),
  e.c = i,
  e
}, nr = function(t, e, i) {
  var s = "", n = (t + s).match(Mt), a = e ? "hsla(" : "rgba(", h = 0, l, o, u, c;
  if (!n)
      return t;
  if (n = n.map(function(d) {
      return (d = Wr(d, e, 1)) && a + (e ? d[0] + "," + d[1] + "%," + d[2] + "%," + d[3] : d.join(",")) + ")"
  }),
  i && (u = Hr(t),
  l = i.c,
  l.join(s) !== u.c.join(s)))
      for (o = t.replace(Mt, "1").split(Gt),
      c = o.length - 1; h < c; h++)
          s += o[h] + (~l.indexOf(h) ? n.shift() || a + "0,0,0,0)" : (u.length ? u : n.length ? n : i).shift());
  if (!o)
      for (o = t.split(Mt),
      c = o.length - 1; h < c; h++)
          s += o[h] + n[h];
  return s + o[c]
}, Mt = function() {
  var r = "(?:\\b(?:(?:rgb|rgba|hsl|hsla)\\(.+?\\))|\\B#(?:[0-9a-f]{3,4}){1,2}\\b", t;
  for (t in se)
      r += "|" + t + "\\b";
  return new RegExp(r + ")","gi")
}(), Ia = /hsl[a]?\(/, jr = function(t) {
  var e = t.join(" "), i;
  if (Mt.lastIndex = 0,
  Mt.test(e))
      return i = Ia.test(e),
      t[1] = nr(t[1], i),
      t[0] = nr(t[0], i, Hr(t[1])),
      !0
}, fe, et = function() {
  var r = Date.now, t = 500, e = 33, i = r(), s = i, n = 1e3 / 240, a = n, h = [], l, o, u, c, d, p, g = function f(m) {
      var _ = r() - s, y = m === !0, x, b, v, w;
      if (_ > t && (i += _ - e),
      s += _,
      v = s - i,
      x = v - a,
      (x > 0 || y) && (w = ++c.frame,
      d = v - c.time * 1e3,
      c.time = v = v / 1e3,
      a += x + (x >= n ? 4 : n - x),
      b = 1),
      y || (l = o(f)),
      b)
          for (p = 0; p < h.length; p++)
              h[p](v, d, w, m)
  };
  return c = {
      time: 0,
      frame: 0,
      tick: function() {
          g(!0)
      },
      deltaRatio: function(m) {
          return d / (1e3 / (m || 60))
      },
      wake: function() {
          Mr && (!Je && pi() && (nt = Je = window,
          _i = nt.document || {},
          rt.gsap = J,
          (nt.gsapVersions || (nt.gsapVersions = [])).push(J.version),
          Sr(we || nt.GreenSockGlobals || !nt.gsap && nt || {}),
          u = nt.requestAnimationFrame,
          Gr.forEach(qr)),
          l && c.sleep(),
          o = u || function(m) {
              return setTimeout(m, a - c.time * 1e3 + 1 | 0)
          }
          ,
          fe = 1,
          g(2))
      },
      sleep: function() {
          (u ? nt.cancelAnimationFrame : clearTimeout)(l),
          fe = 0,
          o = oe
      },
      lagSmoothing: function(m, _) {
          t = m || 1 / 0,
          e = Math.min(_ || 33, t)
      },
      fps: function(m) {
          n = 1e3 / (m || 240),
          a = c.time * 1e3 + n
      },
      add: function(m, _, y) {
          var x = _ ? function(b, v, w, T) {
              m(b, v, w, T),
              c.remove(x)
          }
          : m;
          return c.remove(m),
          h[y ? "unshift" : "push"](x),
          Jt(),
          x
      },
      remove: function(m, _) {
          ~(_ = h.indexOf(m)) && h.splice(_, 1) && p >= _ && p--
      },
      _listeners: h
  },
  c
}(), Jt = function() {
  return !fe && et.wake()
}, k = {}, Ba = /^[\d.\-M][\d.\-,\s]/, Va = /["']/g, Na = function(t) {
  for (var e = {}, i = t.substr(1, t.length - 3).split(":"), s = i[0], n = 1, a = i.length, h, l, o; n < a; n++)
      l = i[n],
      h = n !== a - 1 ? l.lastIndexOf(",") : l.length,
      o = l.substr(0, h),
      e[s] = isNaN(o) ? o.replace(Va, "").trim() : +o,
      s = l.substr(h + 1).trim();
  return e
}, Ua = function(t) {
  var e = t.indexOf("(") + 1
    , i = t.indexOf(")")
    , s = t.indexOf("(", e);
  return t.substring(e, ~s && s < i ? t.indexOf(")", i + 1) : i)
}, Ya = function(t) {
  var e = (t + "").split("(")
    , i = k[e[0]];
  return i && e.length > 1 && i.config ? i.config.apply(null, ~t.indexOf("{") ? [Na(e[1])] : Ua(t).split(",").map(Fr)) : k._CE && Ba.test(t) ? k._CE("", t) : i
}, Zr = function(t) {
  return function(e) {
      return 1 - t(1 - e)
  }
}, Kr = function r(t, e) {
  for (var i = t._first, s; i; )
      i instanceof j ? r(i, e) : i.vars.yoyoEase && (!i._yoyo || !i._repeat) && i._yoyo !== e && (i.timeline ? r(i.timeline, e) : (s = i._ease,
      i._ease = i._yEase,
      i._yEase = s,
      i._yoyo = e)),
      i = i._next
}, Vt = function(t, e) {
  return t && (I(t) ? t : k[t] || Ya(t)) || e
}, Yt = function(t, e, i, s) {
  i === void 0 && (i = function(l) {
      return 1 - e(1 - l)
  }
  ),
  s === void 0 && (s = function(l) {
      return l < .5 ? e(l * 2) / 2 : 1 - e((1 - l) * 2) / 2
  }
  );
  var n = {
      easeIn: e,
      easeOut: i,
      easeInOut: s
  }, a;
  return K(t, function(h) {
      k[h] = rt[h] = n,
      k[a = h.toLowerCase()] = i;
      for (var l in n)
          k[a + (l === "easeIn" ? ".in" : l === "easeOut" ? ".out" : ".inOut")] = k[h + "." + l] = n[l]
  }),
  n
}, Qr = function(t) {
  return function(e) {
      return e < .5 ? (1 - t(1 - e * 2)) / 2 : .5 + t((e - .5) * 2) / 2
  }
}, Ue = function r(t, e, i) {
  var s = e >= 1 ? e : 1
    , n = (i || (t ? .3 : .45)) / (e < 1 ? e : 1)
    , a = n / Ke * (Math.asin(1 / s) || 0)
    , h = function(u) {
      return u === 1 ? 1 : s * Math.pow(2, -10 * u) * ma((u - a) * n) + 1
  }
    , l = t === "out" ? h : t === "in" ? function(o) {
      return 1 - h(1 - o)
  }
  : Qr(h);
  return n = Ke / n,
  l.config = function(o, u) {
      return r(t, o, u)
  }
  ,
  l
}, Ye = function r(t, e) {
  e === void 0 && (e = 1.70158);
  var i = function(a) {
      return a ? --a * a * ((e + 1) * a + e) + 1 : 0
  }
    , s = t === "out" ? i : t === "in" ? function(n) {
      return 1 - i(1 - n)
  }
  : Qr(i);
  return s.config = function(n) {
      return r(t, n)
  }
  ,
  s
};
K("Linear,Quad,Cubic,Quart,Quint,Strong", function(r, t) {
  var e = t < 5 ? t + 1 : t;
  Yt(r + ",Power" + (e - 1), t ? function(i) {
      return Math.pow(i, e)
  }
  : function(i) {
      return i
  }
  , function(i) {
      return 1 - Math.pow(1 - i, e)
  }, function(i) {
      return i < .5 ? Math.pow(i * 2, e) / 2 : 1 - Math.pow((1 - i) * 2, e) / 2
  })
});
k.Linear.easeNone = k.none = k.Linear.easeIn;
Yt("Elastic", Ue("in"), Ue("out"), Ue());
(function(r, t) {
  var e = 1 / t
    , i = 2 * e
    , s = 2.5 * e
    , n = function(h) {
      return h < e ? r * h * h : h < i ? r * Math.pow(h - 1.5 / t, 2) + .75 : h < s ? r * (h -= 2.25 / t) * h + .9375 : r * Math.pow(h - 2.625 / t, 2) + .984375
  };
  Yt("Bounce", function(a) {
      return 1 - n(1 - a)
  }, n)
}
)(7.5625, 2.75);
Yt("Expo", function(r) {
  return r ? Math.pow(2, 10 * (r - 1)) : 0
});
Yt("Circ", function(r) {
  return -(vr(1 - r * r) - 1)
});
Yt("Sine", function(r) {
  return r === 1 ? 1 : -ga(r * pa) + 1
});
Yt("Back", Ye("in"), Ye("out"), Ye());
k.SteppedEase = k.steps = rt.SteppedEase = {
  config: function(t, e) {
      t === void 0 && (t = 1);
      var i = 1 / t
        , s = t + (e ? 0 : 1)
        , n = e ? 1 : 0
        , a = 1 - L;
      return function(h) {
          return ((s * ge(0, a, h) | 0) + n) * i
      }
  }
};
Zt.ease = k["quad.out"];
K("onComplete,onUpdate,onStart,onRepeat,onReverseComplete,onInterrupt", function(r) {
  return yi += r + "," + r + "Params,"
});
var Jr = function(t, e) {
  this.id = _a++,
  t._gsap = this,
  this.target = t,
  this.harness = e,
  this.get = e ? e.get : Pr,
  this.set = e ? e.getSetter : Ai
}
, de = function() {
  function r(e) {
      this.vars = e,
      this._delay = +e.delay || 0,
      (this._repeat = e.repeat === 1 / 0 ? -2 : e.repeat || 0) && (this._rDelay = e.repeatDelay || 0,
      this._yoyo = !!e.yoyo || !!e.yoyoEase),
      this._ts = 1,
      Qt(this, +e.duration, 1, 1),
      this.data = e.data,
      z && (this._ctx = z,
      z.data.push(this)),
      fe || et.wake()
  }
  var t = r.prototype;
  return t.delay = function(i) {
      return i || i === 0 ? (this.parent && this.parent.smoothChildTiming && this.startTime(this._start + i - this._delay),
      this._delay = i,
      this) : this._delay
  }
  ,
  t.duration = function(i) {
      return arguments.length ? this.totalDuration(this._repeat > 0 ? i + (i + this._rDelay) * this._repeat : i) : this.totalDuration() && this._dur
  }
  ,
  t.totalDuration = function(i) {
      return arguments.length ? (this._dirty = 0,
      Qt(this, this._repeat < 0 ? i : (i - this._repeat * this._rDelay) / (this._repeat + 1))) : this._tDur
  }
  ,
  t.totalTime = function(i, s) {
      if (Jt(),
      !arguments.length)
          return this._tTime;
      var n = this._dp;
      if (n && n.smoothChildTiming && this._ts) {
          for (Fe(this, i),
          !n._dp || n.parent || Rr(n, this); n && n.parent; )
              n.parent._time !== n._start + (n._ts >= 0 ? n._tTime / n._ts : (n.totalDuration() - n._tTime) / -n._ts) && n.totalTime(n._tTime, !0),
              n = n.parent;
          !this.parent && this._dp.autoRemoveChildren && (this._ts > 0 && i < this._tDur || this._ts < 0 && i > 0 || !this._tDur && !i) && dt(this._dp, this, this._start - this._delay)
      }
      return (this._tTime !== i || !this._dur && !s || this._initted && Math.abs(this._zTime) === L || !i && !this._initted && (this.add || this._ptLookup)) && (this._ts || (this._pTime = i),
      kr(this, i, s)),
      this
  }
  ,
  t.time = function(i, s) {
      return arguments.length ? this.totalTime(Math.min(this.totalDuration(), i + ir(this)) % (this._dur + this._rDelay) || (i ? this._dur : 0), s) : this._time
  }
  ,
  t.totalProgress = function(i, s) {
      return arguments.length ? this.totalTime(this.totalDuration() * i, s) : this.totalDuration() ? Math.min(1, this._tTime / this._tDur) : this.ratio
  }
  ,
  t.progress = function(i, s) {
      return arguments.length ? this.totalTime(this.duration() * (this._yoyo && !(this.iteration() & 1) ? 1 - i : i) + ir(this), s) : this.duration() ? Math.min(1, this._time / this._dur) : this.ratio
  }
  ,
  t.iteration = function(i, s) {
      var n = this.duration() + this._rDelay;
      return arguments.length ? this.totalTime(this._time + (i - 1) * n, s) : this._repeat ? Kt(this._tTime, n) + 1 : 1
  }
  ,
  t.timeScale = function(i) {
      if (!arguments.length)
          return this._rts === -L ? 0 : this._rts;
      if (this._rts === i)
          return this;
      var s = this.parent && this._ts ? Me(this.parent._time, this) : this._tTime;
      return this._rts = +i || 0,
      this._ts = this._ps || i === -L ? 0 : this._rts,
      this.totalTime(ge(-Math.abs(this._delay), this._tDur, s), !0),
      ke(this),
      Ea(this)
  }
  ,
  t.paused = function(i) {
      return arguments.length ? (this._ps !== i && (this._ps = i,
      i ? (this._pTime = this._tTime || Math.max(-this._delay, this.rawTime()),
      this._ts = this._act = 0) : (Jt(),
      this._ts = this._rts,
      this.totalTime(this.parent && !this.parent.smoothChildTiming ? this.rawTime() : this._tTime || this._pTime, this.progress() === 1 && Math.abs(this._zTime) !== L && (this._tTime -= L)))),
      this) : this._ps
  }
  ,
  t.startTime = function(i) {
      if (arguments.length) {
          this._start = i;
          var s = this.parent || this._dp;
          return s && (s._sort || !this.parent) && dt(s, this, i - this._delay),
          this
      }
      return this._start
  }
  ,
  t.endTime = function(i) {
      return this._start + (Z(i) ? this.totalDuration() : this.duration()) / Math.abs(this._ts || 1)
  }
  ,
  t.rawTime = function(i) {
      var s = this.parent || this._dp;
      return s ? i && (!this._ts || this._repeat && this._time && this.totalProgress() < 1) ? this._tTime % (this._dur + this._rDelay) : this._ts ? Me(s.rawTime(i), this) : this._tTime : this._tTime
  }
  ,
  t.revert = function(i) {
      i === void 0 && (i = ba);
      var s = q;
      return q = i,
      (this._initted || this._startAt) && (this.timeline && this.timeline.revert(i),
      this.totalTime(-.01, i.suppressEvents)),
      this.data !== "nested" && i.kill !== !1 && this.kill(),
      q = s,
      this
  }
  ,
  t.globalTime = function(i) {
      for (var s = this, n = arguments.length ? i : s.rawTime(); s; )
          n = s._start + n / (s._ts || 1),
          s = s._dp;
      return !this.parent && this._sat ? this._sat.vars.immediateRender ? -1 : this._sat.globalTime(i) : n
  }
  ,
  t.repeat = function(i) {
      return arguments.length ? (this._repeat = i === 1 / 0 ? -2 : i,
      rr(this)) : this._repeat === -2 ? 1 / 0 : this._repeat
  }
  ,
  t.repeatDelay = function(i) {
      if (arguments.length) {
          var s = this._time;
          return this._rDelay = i,
          rr(this),
          s ? this.time(s) : this
      }
      return this._rDelay
  }
  ,
  t.yoyo = function(i) {
      return arguments.length ? (this._yoyo = i,
      this) : this._yoyo
  }
  ,
  t.seek = function(i, s) {
      return this.totalTime(st(this, i), Z(s))
  }
  ,
  t.restart = function(i, s) {
      return this.play().totalTime(i ? -this._delay : 0, Z(s))
  }
  ,
  t.play = function(i, s) {
      return i != null && this.seek(i, s),
      this.reversed(!1).paused(!1)
  }
  ,
  t.reverse = function(i, s) {
      return i != null && this.seek(i || this.totalDuration(), s),
      this.reversed(!0).paused(!1)
  }
  ,
  t.pause = function(i, s) {
      return i != null && this.seek(i, s),
      this.paused(!0)
  }
  ,
  t.resume = function() {
      return this.paused(!1)
  }
  ,
  t.reversed = function(i) {
      return arguments.length ? (!!i !== this.reversed() && this.timeScale(-this._rts || (i ? -L : 0)),
      this) : this._rts < 0
  }
  ,
  t.invalidate = function() {
      return this._initted = this._act = 0,
      this._zTime = -L,
      this
  }
  ,
  t.isActive = function() {
      var i = this.parent || this._dp, s = this._start, n;
      return !!(!i || this._ts && this._initted && i.isActive() && (n = i.rawTime(!0)) >= s && n < this.endTime(!0) - L)
  }
  ,
  t.eventCallback = function(i, s, n) {
      var a = this.vars;
      return arguments.length > 1 ? (s ? (a[i] = s,
      n && (a[i + "Params"] = n),
      i === "onUpdate" && (this._onUpdate = s)) : delete a[i],
      this) : a[i]
  }
  ,
  t.then = function(i) {
      var s = this;
      return new Promise(function(n) {
          var a = I(i) ? i : Dr
            , h = function() {
              var o = s.then;
              s.then = null,
              I(a) && (a = a(s)) && (a.then || a === s) && (s.then = o),
              n(a),
              s.then = o
          };
          s._initted && s.totalProgress() === 1 && s._ts >= 0 || !s._tTime && s._ts < 0 ? h() : s._prom = h
      }
      )
  }
  ,
  t.kill = function() {
      re(this)
  }
  ,
  r
}();
ot(de.prototype, {
  _time: 0,
  _start: 0,
  _end: 0,
  _tTime: 0,
  _tDur: 0,
  _dirty: 0,
  _repeat: 0,
  _yoyo: !1,
  parent: null,
  _initted: !1,
  _rDelay: 0,
  _ts: 1,
  _dp: 0,
  ratio: 0,
  _zTime: -L,
  _prom: 0,
  _ps: !1,
  _rts: 1
});
var j = function(r) {
  br(t, r);
  function t(i, s) {
      var n;
      return i === void 0 && (i = {}),
      n = r.call(this, i) || this,
      n.labels = {},
      n.smoothChildTiming = !!i.smoothChildTiming,
      n.autoRemoveChildren = !!i.autoRemoveChildren,
      n._sort = Z(i.sortChildren),
      R && dt(i.parent || R, yt(n), s),
      i.reversed && n.reverse(),
      i.paused && n.paused(!0),
      i.scrollTrigger && $r(yt(n), i.scrollTrigger),
      n
  }
  var e = t.prototype;
  return e.to = function(s, n, a) {
      return ae(0, arguments, this),
      this
  }
  ,
  e.from = function(s, n, a) {
      return ae(1, arguments, this),
      this
  }
  ,
  e.fromTo = function(s, n, a, h) {
      return ae(2, arguments, this),
      this
  }
  ,
  e.set = function(s, n, a) {
      return n.duration = 0,
      n.parent = this,
      ne(n).repeatDelay || (n.repeat = 0),
      n.immediateRender = !!n.immediateRender,
      new N(s,n,st(this, a),1),
      this
  }
  ,
  e.call = function(s, n, a) {
      return dt(this, N.delayedCall(0, s, n), a)
  }
  ,
  e.staggerTo = function(s, n, a, h, l, o, u) {
      return a.duration = n,
      a.stagger = a.stagger || h,
      a.onComplete = o,
      a.onCompleteParams = u,
      a.parent = this,
      new N(s,a,st(this, l)),
      this
  }
  ,
  e.staggerFrom = function(s, n, a, h, l, o, u) {
      return a.runBackwards = 1,
      ne(a).immediateRender = Z(a.immediateRender),
      this.staggerTo(s, n, a, h, l, o, u)
  }
  ,
  e.staggerFromTo = function(s, n, a, h, l, o, u, c) {
      return h.startAt = a,
      ne(h).immediateRender = Z(h.immediateRender),
      this.staggerTo(s, n, h, l, o, u, c)
  }
  ,
  e.render = function(s, n, a) {
      var h = this._time, l = this._dirty ? this.totalDuration() : this._tDur, o = this._dur, u = s <= 0 ? 0 : X(s), c = this._zTime < 0 != s < 0 && (this._initted || !o), d, p, g, f, m, _, y, x, b, v, w, T;
      if (this !== R && u > l && s >= 0 && (u = l),
      u !== this._tTime || a || c) {
          if (h !== this._time && o && (u += this._time - h,
          s += this._time - h),
          d = u,
          b = this._start,
          x = this._ts,
          _ = !x,
          c && (o || (h = this._zTime),
          (s || !n) && (this._zTime = s)),
          this._repeat) {
              if (w = this._yoyo,
              m = o + this._rDelay,
              this._repeat < -1 && s < 0)
                  return this.totalTime(m * 100 + s, n, a);
              if (d = X(u % m),
              u === l ? (f = this._repeat,
              d = o) : (f = ~~(u / m),
              f && f === u / m && (d = o,
              f--),
              d > o && (d = o)),
              v = Kt(this._tTime, m),
              !h && this._tTime && v !== f && this._tTime - v * m - this._dur <= 0 && (v = f),
              w && f & 1 && (d = o - d,
              T = 1),
              f !== v && !this._lock) {
                  var E = w && v & 1
                    , A = E === (w && f & 1);
                  if (f < v && (E = !E),
                  h = E ? 0 : o,
                  this._lock = 1,
                  this.render(h || (T ? 0 : X(f * m)), n, !o)._lock = 0,
                  this._tTime = u,
                  !n && this.parent && lt(this, "onRepeat"),
                  this.vars.repeatRefresh && !T && (this.invalidate()._lock = 1),
                  h && h !== this._time || _ !== !this._ts || this.vars.onRepeat && !this.parent && !this._act)
                      return this;
                  if (o = this._dur,
                  l = this._tDur,
                  A && (this._lock = 2,
                  h = E ? o : -1e-4,
                  this.render(h, !0),
                  this.vars.repeatRefresh && !T && this.invalidate()),
                  this._lock = 0,
                  !this._ts && !_)
                      return this;
                  Kr(this, T)
              }
          }
          if (this._hasPause && !this._forcing && this._lock < 2 && (y = Ca(this, X(h), X(d)),
          y && (u -= d - (d = y._start))),
          this._tTime = u,
          this._time = d,
          this._act = !x,
          this._initted || (this._onUpdate = this.vars.onUpdate,
          this._initted = 1,
          this._zTime = s,
          h = 0),
          !h && d && !n && !f && (lt(this, "onStart"),
          this._tTime !== u))
              return this;
          if (d >= h && s >= 0)
              for (p = this._first; p; ) {
                  if (g = p._next,
                  (p._act || d >= p._start) && p._ts && y !== p) {
                      if (p.parent !== this)
                          return this.render(s, n, a);
                      if (p.render(p._ts > 0 ? (d - p._start) * p._ts : (p._dirty ? p.totalDuration() : p._tDur) + (d - p._start) * p._ts, n, a),
                      d !== this._time || !this._ts && !_) {
                          y = 0,
                          g && (u += this._zTime = -L);
                          break
                      }
                  }
                  p = g
              }
          else {
              p = this._last;
              for (var S = s < 0 ? s : d; p; ) {
                  if (g = p._prev,
                  (p._act || S <= p._end) && p._ts && y !== p) {
                      if (p.parent !== this)
                          return this.render(s, n, a);
                      if (p.render(p._ts > 0 ? (S - p._start) * p._ts : (p._dirty ? p.totalDuration() : p._tDur) + (S - p._start) * p._ts, n, a || q && (p._initted || p._startAt)),
                      d !== this._time || !this._ts && !_) {
                          y = 0,
                          g && (u += this._zTime = S ? -L : L);
                          break
                      }
                  }
                  p = g
              }
          }
          if (y && !n && (this.pause(),
          y.render(d >= h ? 0 : -L)._zTime = d >= h ? 1 : -1,
          this._ts))
              return this._start = b,
              ke(this),
              this.render(s, n, a);
          this._onUpdate && !n && lt(this, "onUpdate", !0),
          (u === l && this._tTime >= this.totalDuration() || !u && h) && (b === this._start || Math.abs(x) !== Math.abs(this._ts)) && (this._lock || ((s || !o) && (u === l && this._ts > 0 || !u && this._ts < 0) && St(this, 1),
          !n && !(s < 0 && !h) && (u || h || !l) && (lt(this, u === l && s >= 0 ? "onComplete" : "onReverseComplete", !0),
          this._prom && !(u < l && this.timeScale() > 0) && this._prom())))
      }
      return this
  }
  ,
  e.add = function(s, n) {
      var a = this;
      if (bt(n) || (n = st(this, n, s)),
      !(s instanceof de)) {
          if (W(s))
              return s.forEach(function(h) {
                  return a.add(h, n)
              }),
              this;
          if (Y(s))
              return this.addLabel(s, n);
          if (I(s))
              s = N.delayedCall(0, s);
          else
              return this
      }
      return this !== s ? dt(this, s, n) : this
  }
  ,
  e.getChildren = function(s, n, a, h) {
      s === void 0 && (s = !0),
      n === void 0 && (n = !0),
      a === void 0 && (a = !0),
      h === void 0 && (h = -at);
      for (var l = [], o = this._first; o; )
          o._start >= h && (o instanceof N ? n && l.push(o) : (a && l.push(o),
          s && l.push.apply(l, o.getChildren(!0, n, a)))),
          o = o._next;
      return l
  }
  ,
  e.getById = function(s) {
      for (var n = this.getChildren(1, 1, 1), a = n.length; a--; )
          if (n[a].vars.id === s)
              return n[a]
  }
  ,
  e.remove = function(s) {
      return Y(s) ? this.removeLabel(s) : I(s) ? this.killTweensOf(s) : (Pe(this, s),
      s === this._recent && (this._recent = this._last),
      Bt(this))
  }
  ,
  e.totalTime = function(s, n) {
      return arguments.length ? (this._forcing = 1,
      !this._dp && this._ts && (this._start = X(et.time - (this._ts > 0 ? s / this._ts : (this.totalDuration() - s) / -this._ts))),
      r.prototype.totalTime.call(this, s, n),
      this._forcing = 0,
      this) : this._tTime
  }
  ,
  e.addLabel = function(s, n) {
      return this.labels[s] = st(this, n),
      this
  }
  ,
  e.removeLabel = function(s) {
      return delete this.labels[s],
      this
  }
  ,
  e.addPause = function(s, n, a) {
      var h = N.delayedCall(0, n || oe, a);
      return h.data = "isPause",
      this._hasPause = 1,
      dt(this, h, st(this, s))
  }
  ,
  e.removePause = function(s) {
      var n = this._first;
      for (s = st(this, s); n; )
          n._start === s && n.data === "isPause" && St(n),
          n = n._next
  }
  ,
  e.killTweensOf = function(s, n, a) {
      for (var h = this.getTweensOf(s, a), l = h.length; l--; )
          wt !== h[l] && h[l].kill(s, n);
      return this
  }
  ,
  e.getTweensOf = function(s, n) {
      for (var a = [], h = ht(s), l = this._first, o = bt(n), u; l; )
          l instanceof N ? va(l._targets, h) && (o ? (!wt || l._initted && l._ts) && l.globalTime(0) <= n && l.globalTime(l.totalDuration()) > n : !n || l.isActive()) && a.push(l) : (u = l.getTweensOf(h, n)).length && a.push.apply(a, u),
          l = l._next;
      return a
  }
  ,
  e.tweenTo = function(s, n) {
      n = n || {};
      var a = this, h = st(a, s), l = n, o = l.startAt, u = l.onStart, c = l.onStartParams, d = l.immediateRender, p, g = N.to(a, ot({
          ease: n.ease || "none",
          lazy: !1,
          immediateRender: !1,
          time: h,
          overwrite: "auto",
          duration: n.duration || Math.abs((h - (o && "time"in o ? o.time : a._time)) / a.timeScale()) || L,
          onStart: function() {
              if (a.pause(),
              !p) {
                  var m = n.duration || Math.abs((h - (o && "time"in o ? o.time : a._time)) / a.timeScale());
                  g._dur !== m && Qt(g, m, 0, 1).render(g._time, !0, !0),
                  p = 1
              }
              u && u.apply(g, c || [])
          }
      }, n));
      return d ? g.render(0) : g
  }
  ,
  e.tweenFromTo = function(s, n, a) {
      return this.tweenTo(n, ot({
          startAt: {
              time: st(this, s)
          }
      }, a))
  }
  ,
  e.recent = function() {
      return this._recent
  }
  ,
  e.nextLabel = function(s) {
      return s === void 0 && (s = this._time),
      sr(this, st(this, s))
  }
  ,
  e.previousLabel = function(s) {
      return s === void 0 && (s = this._time),
      sr(this, st(this, s), 1)
  }
  ,
  e.currentLabel = function(s) {
      return arguments.length ? this.seek(s, !0) : this.previousLabel(this._time + L)
  }
  ,
  e.shiftChildren = function(s, n, a) {
      a === void 0 && (a = 0);
      for (var h = this._first, l = this.labels, o; h; )
          h._start >= a && (h._start += s,
          h._end += s),
          h = h._next;
      if (n)
          for (o in l)
              l[o] >= a && (l[o] += s);
      return Bt(this)
  }
  ,
  e.invalidate = function(s) {
      var n = this._first;
      for (this._lock = 0; n; )
          n.invalidate(s),
          n = n._next;
      return r.prototype.invalidate.call(this, s)
  }
  ,
  e.clear = function(s) {
      s === void 0 && (s = !0);
      for (var n = this._first, a; n; )
          a = n._next,
          this.remove(n),
          n = a;
      return this._dp && (this._time = this._tTime = this._pTime = 0),
      s && (this.labels = {}),
      Bt(this)
  }
  ,
  e.totalDuration = function(s) {
      var n = 0, a = this, h = a._last, l = at, o, u, c;
      if (arguments.length)
          return a.timeScale((a._repeat < 0 ? a.duration() : a.totalDuration()) / (a.reversed() ? -s : s));
      if (a._dirty) {
          for (c = a.parent; h; )
              o = h._prev,
              h._dirty && h.totalDuration(),
              u = h._start,
              u > l && a._sort && h._ts && !a._lock ? (a._lock = 1,
              dt(a, h, u - h._delay, 1)._lock = 0) : l = u,
              u < 0 && h._ts && (n -= u,
              (!c && !a._dp || c && c.smoothChildTiming) && (a._start += u / a._ts,
              a._time -= u,
              a._tTime -= u),
              a.shiftChildren(-u, !1, -1 / 0),
              l = 0),
              h._end > n && h._ts && (n = h._end),
              h = o;
          Qt(a, a === R && a._time > n ? a._time : n, 1, 1),
          a._dirty = 0
      }
      return a._tDur
  }
  ,
  t.updateRoot = function(s) {
      if (R._ts && (kr(R, Me(s, R)),
      Or = et.frame),
      et.frame >= tr) {
          tr += it.autoSleep || 120;
          var n = R._first;
          if ((!n || !n._ts) && it.autoSleep && et._listeners.length < 2) {
              for (; n && !n._ts; )
                  n = n._next;
              n || et.sleep()
          }
      }
  }
  ,
  t
}(de);
ot(j.prototype, {
  _lock: 0,
  _hasPause: 0,
  _forcing: 0
});
var Xa = function(t, e, i, s, n, a, h) {
  var l = new Q(this._pt,t,e,0,1,ns,null,n), o = 0, u = 0, c, d, p, g, f, m, _, y;
  for (l.b = i,
  l.e = s,
  i += "",
  s += "",
  (_ = ~s.indexOf("random(")) && (s = ue(s)),
  a && (y = [i, s],
  a(y, t, e),
  i = y[0],
  s = y[1]),
  d = i.match(Be) || []; c = Be.exec(s); )
      g = c[0],
      f = s.substring(o, c.index),
      p ? p = (p + 1) % 5 : f.substr(-5) === "rgba(" && (p = 1),
      g !== d[u++] && (m = parseFloat(d[u - 1]) || 0,
      l._pt = {
          _next: l._pt,
          p: f || u === 1 ? f : ",",
          s: m,
          c: g.charAt(1) === "=" ? Wt(m, g) - m : parseFloat(g) - m,
          m: p && p < 4 ? Math.round : 0
      },
      o = Be.lastIndex);
  return l.c = o < s.length ? s.substring(o, s.length) : "",
  l.fp = h,
  (Er.test(s) || _) && (l.e = 0),
  this._pt = l,
  l
}, bi = function(t, e, i, s, n, a, h, l, o, u) {
  I(s) && (s = s(n || 0, t, a));
  var c = t[e], d = i !== "get" ? i : I(c) ? o ? t[e.indexOf("set") || !I(t["get" + e.substr(3)]) ? e : "get" + e.substr(3)](o) : t[e]() : c, p = I(c) ? o ? ja : rs : wi, g;
  if (Y(s) && (~s.indexOf("random(") && (s = ue(s)),
  s.charAt(1) === "=" && (g = Wt(d, s) + (G(d) || 0),
  (g || g === 0) && (s = g))),
  !u || d !== s || ai)
      return !isNaN(d * s) && s !== "" ? (g = new Q(this._pt,t,e,+d || 0,s - (d || 0),typeof c == "boolean" ? Ka : ss,0,p),
      o && (g.fp = o),
      h && g.modifier(h, this, t),
      this._pt = g) : (!c && !(e in t) && gi(e, s),
      Xa.call(this, t, e, d, s, p, l || it.stringFilter, o))
}, Ga = function(t, e, i, s, n) {
  if (I(t) && (t = he(t, n, e, i, s)),
  !gt(t) || t.style && t.nodeType || W(t) || wr(t))
      return Y(t) ? he(t, n, e, i, s) : t;
  var a = {}, h;
  for (h in t)
      a[h] = he(t[h], n, e, i, s);
  return a
}, ts = function(t, e, i, s, n, a) {
  var h, l, o, u;
  if (tt[t] && (h = new tt[t]).init(n, h.rawVars ? e[t] : Ga(e[t], s, n, a, i), i, s, a) !== !1 && (i._pt = l = new Q(i._pt,n,t,0,1,h.render,h,0,h.priority),
  i !== qt))
      for (o = i._ptLookup[i._targets.indexOf(n)],
      u = h._props.length; u--; )
          o[h._props[u]] = l;
  return h
}, wt, ai, vi = function r(t, e, i) {
  var s = t.vars, n = s.ease, a = s.startAt, h = s.immediateRender, l = s.lazy, o = s.onUpdate, u = s.onUpdateParams, c = s.callbackScope, d = s.runBackwards, p = s.yoyoEase, g = s.keyframes, f = s.autoRevert, m = t._dur, _ = t._startAt, y = t._targets, x = t.parent, b = x && x.data === "nested" ? x.vars.targets : y, v = t._overwrite === "auto" && !di, w = t.timeline, T, E, A, S, C, O, P, M, F, U, V, H, Pt;
  if (w && (!g || !n) && (n = "none"),
  t._ease = Vt(n, Zt.ease),
  t._yEase = p ? Zr(Vt(p === !0 ? n : p, Zt.ease)) : 0,
  p && t._yoyo && !t._repeat && (p = t._yEase,
  t._yEase = t._ease,
  t._ease = p),
  t._from = !w && !!s.runBackwards,
  !w || g && !s.stagger) {
      if (M = y[0] ? It(y[0]).harness : 0,
      H = M && s[M.prop],
      T = Te(s, mi),
      _ && (_._zTime < 0 && _.progress(1),
      e < 0 && d && h && !f ? _.render(-1, !0) : _.revert(d && m ? ye : xa),
      _._lazy = 0),
      a) {
          if (St(t._startAt = N.set(y, ot({
              data: "isStart",
              overwrite: !1,
              parent: x,
              immediateRender: !0,
              lazy: !_ && Z(l),
              startAt: null,
              delay: 0,
              onUpdate: o,
              onUpdateParams: u,
              callbackScope: c,
              stagger: 0
          }, a))),
          t._startAt._dp = 0,
          t._startAt._sat = t,
          e < 0 && (q || !h && !f) && t._startAt.revert(ye),
          h && m && e <= 0 && i <= 0) {
              e && (t._zTime = e);
              return
          }
      } else if (d && m && !_) {
          if (e && (h = !1),
          A = ot({
              overwrite: !1,
              data: "isFromStart",
              lazy: h && !_ && Z(l),
              immediateRender: h,
              stagger: 0,
              parent: x
          }, T),
          H && (A[M.prop] = H),
          St(t._startAt = N.set(y, A)),
          t._startAt._dp = 0,
          t._startAt._sat = t,
          e < 0 && (q ? t._startAt.revert(ye) : t._startAt.render(-1, !0)),
          t._zTime = e,
          !h)
              r(t._startAt, L, L);
          else if (!e)
              return
      }
      for (t._pt = t._ptCache = 0,
      l = m && Z(l) || l && !m,
      E = 0; E < y.length; E++) {
          if (C = y[E],
          P = C._gsap || xi(y)[E]._gsap,
          t._ptLookup[E] = U = {},
          ti[P.id] && Tt.length && Ee(),
          V = b === y ? E : b.indexOf(C),
          M && (F = new M).init(C, H || T, t, V, b) !== !1 && (t._pt = S = new Q(t._pt,C,F.name,0,1,F.render,F,0,F.priority),
          F._props.forEach(function(kt) {
              U[kt] = S
          }),
          F.priority && (O = 1)),
          !M || H)
              for (A in T)
                  tt[A] && (F = ts(A, T, t, V, C, b)) ? F.priority && (O = 1) : U[A] = S = bi.call(t, C, A, "get", T[A], V, b, 0, s.stringFilter);
          t._op && t._op[E] && t.kill(C, t._op[E]),
          v && t._pt && (wt = t,
          R.killTweensOf(C, U, t.globalTime(e)),
          Pt = !t.parent,
          wt = 0),
          t._pt && l && (ti[P.id] = 1)
      }
      O && as(t),
      t._onInit && t._onInit(t)
  }
  t._onUpdate = o,
  t._initted = (!t._op || t._pt) && !Pt,
  g && e <= 0 && w.render(at, !0, !0)
}, qa = function(t, e, i, s, n, a, h) {
  var l = (t._pt && t._ptCache || (t._ptCache = {}))[e], o, u, c, d;
  if (!l)
      for (l = t._ptCache[e] = [],
      c = t._ptLookup,
      d = t._targets.length; d--; ) {
          if (o = c[d][e],
          o && o.d && o.d._pt)
              for (o = o.d._pt; o && o.p !== e && o.fp !== e; )
                  o = o._next;
          if (!o)
              return ai = 1,
              t.vars[e] = "+=0",
              vi(t, h),
              ai = 0,
              1;
          l.push(o)
      }
  for (d = l.length; d--; )
      u = l[d],
      o = u._pt || u,
      o.s = (s || s === 0) && !n ? s : o.s + (s || 0) + a * o.c,
      o.c = i - o.s,
      u.e && (u.e = B(i) + G(u.e)),
      u.b && (u.b = o.s + G(u.b))
}, Wa = function(t, e) {
  var i = t[0] ? It(t[0]).harness : 0, s = i && i.aliases, n, a, h, l;
  if (!s)
      return e;
  n = Ut({}, e);
  for (a in s)
      if (a in n)
          for (l = s[a].split(","),
          h = l.length; h--; )
              n[l[h]] = n[a];
  return n
}, Ha = function(t, e, i, s) {
  var n = e.ease || s || "power1.inOut", a, h;
  if (W(e))
      h = i[t] || (i[t] = []),
      e.forEach(function(l, o) {
          return h.push({
              t: o / (e.length - 1) * 100,
              v: l,
              e: n
          })
      });
  else
      for (a in e)
          h = i[a] || (i[a] = []),
          a === "ease" || h.push({
              t: parseFloat(t),
              v: e[a],
              e: n
          })
}, he = function(t, e, i, s, n) {
  return I(t) ? t.call(e, i, s, n) : Y(t) && ~t.indexOf("random(") ? ue(t) : t
}, es = yi + "repeat,repeatDelay,yoyo,repeatRefresh,yoyoEase,autoRevert", is = {};
K(es + ",id,stagger,delay,duration,paused,scrollTrigger", function(r) {
  return is[r] = 1
});
var N = function(r) {
  br(t, r);
  function t(i, s, n, a) {
      var h;
      typeof s == "number" && (n.duration = s,
      s = n,
      n = null),
      h = r.call(this, a ? s : ne(s)) || this;
      var l = h.vars, o = l.duration, u = l.delay, c = l.immediateRender, d = l.stagger, p = l.overwrite, g = l.keyframes, f = l.defaults, m = l.scrollTrigger, _ = l.yoyoEase, y = s.parent || R, x = (W(i) || wr(i) ? bt(i[0]) : "length"in s) ? [i] : ht(i), b, v, w, T, E, A, S, C;
      if (h._targets = x.length ? xi(x) : Ae("GSAP target " + i + " not found. https://greensock.com", !it.nullTargetWarn) || [],
      h._ptLookup = [],
      h._overwrite = p,
      g || d || me(o) || me(u)) {
          if (s = h.vars,
          b = h.timeline = new j({
              data: "nested",
              defaults: f || {},
              targets: y && y.data === "nested" ? y.vars.targets : x
          }),
          b.kill(),
          b.parent = b._dp = yt(h),
          b._start = 0,
          d || me(o) || me(u)) {
              if (T = x.length,
              S = d && Vr(d),
              gt(d))
                  for (E in d)
                      ~es.indexOf(E) && (C || (C = {}),
                      C[E] = d[E]);
              for (v = 0; v < T; v++)
                  w = Te(s, is),
                  w.stagger = 0,
                  _ && (w.yoyoEase = _),
                  C && Ut(w, C),
                  A = x[v],
                  w.duration = +he(o, yt(h), v, A, x),
                  w.delay = (+he(u, yt(h), v, A, x) || 0) - h._delay,
                  !d && T === 1 && w.delay && (h._delay = u = w.delay,
                  h._start += u,
                  w.delay = 0),
                  b.to(A, w, S ? S(v, A, x) : 0),
                  b._ease = k.none;
              b.duration() ? o = u = 0 : h.timeline = 0
          } else if (g) {
              ne(ot(b.vars.defaults, {
                  ease: "none"
              })),
              b._ease = Vt(g.ease || s.ease || "none");
              var O = 0, P, M, F;
              if (W(g))
                  g.forEach(function(U) {
                      return b.to(x, U, ">")
                  }),
                  b.duration();
              else {
                  w = {};
                  for (E in g)
                      E === "ease" || E === "easeEach" || Ha(E, g[E], w, g.easeEach);
                  for (E in w)
                      for (P = w[E].sort(function(U, V) {
                          return U.t - V.t
                      }),
                      O = 0,
                      v = 0; v < P.length; v++)
                          M = P[v],
                          F = {
                              ease: M.e,
                              duration: (M.t - (v ? P[v - 1].t : 0)) / 100 * o
                          },
                          F[E] = M.v,
                          b.to(x, F, O),
                          O += F.duration;
                  b.duration() < o && b.to({}, {
                      duration: o - b.duration()
                  })
              }
          }
          o || h.duration(o = b.duration())
      } else
          h.timeline = 0;
      return p === !0 && !di && (wt = yt(h),
      R.killTweensOf(x),
      wt = 0),
      dt(y, yt(h), n),
      s.reversed && h.reverse(),
      s.paused && h.paused(!0),
      (c || !o && !g && h._start === X(y._time) && Z(c) && Ta(yt(h)) && y.data !== "nested") && (h._tTime = -L,
      h.render(Math.max(0, -u) || 0)),
      m && $r(yt(h), m),
      h
  }
  var e = t.prototype;
  return e.render = function(s, n, a) {
      var h = this._time, l = this._tDur, o = this._dur, u = s < 0, c = s > l - L && !u ? l : s < L ? 0 : s, d, p, g, f, m, _, y, x, b;
      if (!o)
          Sa(this, s, n, a);
      else if (c !== this._tTime || !s || a || !this._initted && this._tTime || this._startAt && this._zTime < 0 !== u) {
          if (d = c,
          x = this.timeline,
          this._repeat) {
              if (f = o + this._rDelay,
              this._repeat < -1 && u)
                  return this.totalTime(f * 100 + s, n, a);
              if (d = X(c % f),
              c === l ? (g = this._repeat,
              d = o) : (g = ~~(c / f),
              g && g === c / f && (d = o,
              g--),
              d > o && (d = o)),
              _ = this._yoyo && g & 1,
              _ && (b = this._yEase,
              d = o - d),
              m = Kt(this._tTime, f),
              d === h && !a && this._initted)
                  return this._tTime = c,
                  this;
              g !== m && (x && this._yEase && Kr(x, _),
              this.vars.repeatRefresh && !_ && !this._lock && (this._lock = a = 1,
              this.render(X(f * g), !0).invalidate()._lock = 0))
          }
          if (!this._initted) {
              if (zr(this, u ? s : d, a, n, c))
                  return this._tTime = 0,
                  this;
              if (h !== this._time)
                  return this;
              if (o !== this._dur)
                  return this.render(s, n, a)
          }
          if (this._tTime = c,
          this._time = d,
          !this._act && this._ts && (this._act = 1,
          this._lazy = 0),
          this.ratio = y = (b || this._ease)(d / o),
          this._from && (this.ratio = y = 1 - y),
          d && !h && !n && !g && (lt(this, "onStart"),
          this._tTime !== c))
              return this;
          for (p = this._pt; p; )
              p.r(y, p.d),
              p = p._next;
          x && x.render(s < 0 ? s : !d && _ ? -L : x._dur * x._ease(d / this._dur), n, a) || this._startAt && (this._zTime = s),
          this._onUpdate && !n && (u && ei(this, s, n, a),
          lt(this, "onUpdate")),
          this._repeat && g !== m && this.vars.onRepeat && !n && this.parent && lt(this, "onRepeat"),
          (c === this._tDur || !c) && this._tTime === c && (u && !this._onUpdate && ei(this, s, !0, !0),
          (s || !o) && (c === this._tDur && this._ts > 0 || !c && this._ts < 0) && St(this, 1),
          !n && !(u && !h) && (c || h || _) && (lt(this, c === l ? "onComplete" : "onReverseComplete", !0),
          this._prom && !(c < l && this.timeScale() > 0) && this._prom()))
      }
      return this
  }
  ,
  e.targets = function() {
      return this._targets
  }
  ,
  e.invalidate = function(s) {
      return (!s || !this.vars.runBackwards) && (this._startAt = 0),
      this._pt = this._op = this._onUpdate = this._lazy = this.ratio = 0,
      this._ptLookup = [],
      this.timeline && this.timeline.invalidate(s),
      r.prototype.invalidate.call(this, s)
  }
  ,
  e.resetTo = function(s, n, a, h) {
      fe || et.wake(),
      this._ts || this.play();
      var l = Math.min(this._dur, (this._dp._time - this._start) * this._ts), o;
      return this._initted || vi(this, l),
      o = this._ease(l / this._dur),
      qa(this, s, n, a, h, o, l) ? this.resetTo(s, n, a, h) : (Fe(this, 0),
      this.parent || Lr(this._dp, this, "_first", "_last", this._dp._sort ? "_start" : 0),
      this.render(0))
  }
  ,
  e.kill = function(s, n) {
      if (n === void 0 && (n = "all"),
      !s && (!n || n === "all"))
          return this._lazy = this._pt = 0,
          this.parent ? re(this) : this;
      if (this.timeline) {
          var a = this.timeline.totalDuration();
          return this.timeline.killTweensOf(s, n, wt && wt.vars.overwrite !== !0)._first || re(this),
          this.parent && a !== this.timeline.totalDuration() && Qt(this, this._dur * this.timeline._tDur / a, 0, 1),
          this
      }
      var h = this._targets, l = s ? ht(s) : h, o = this._ptLookup, u = this._pt, c, d, p, g, f, m, _;
      if ((!n || n === "all") && Aa(h, l))
          return n === "all" && (this._pt = 0),
          re(this);
      for (c = this._op = this._op || [],
      n !== "all" && (Y(n) && (f = {},
      K(n, function(y) {
          return f[y] = 1
      }),
      n = f),
      n = Wa(h, n)),
      _ = h.length; _--; )
          if (~l.indexOf(h[_])) {
              d = o[_],
              n === "all" ? (c[_] = n,
              g = d,
              p = {}) : (p = c[_] = c[_] || {},
              g = n);
              for (f in g)
                  m = d && d[f],
                  m && ((!("kill"in m.d) || m.d.kill(f) === !0) && Pe(this, m, "_pt"),
                  delete d[f]),
                  p !== "all" && (p[f] = 1)
          }
      return this._initted && !this._pt && u && re(this),
      this
  }
  ,
  t.to = function(s, n) {
      return new t(s,n,arguments[2])
  }
  ,
  t.from = function(s, n) {
      return ae(1, arguments)
  }
  ,
  t.delayedCall = function(s, n, a, h) {
      return new t(n,0,{
          immediateRender: !1,
          lazy: !1,
          overwrite: !1,
          delay: s,
          onComplete: n,
          onReverseComplete: n,
          onCompleteParams: a,
          onReverseCompleteParams: a,
          callbackScope: h
      })
  }
  ,
  t.fromTo = function(s, n, a) {
      return ae(2, arguments)
  }
  ,
  t.set = function(s, n) {
      return n.duration = 0,
      n.repeatDelay || (n.repeat = 0),
      new t(s,n)
  }
  ,
  t.killTweensOf = function(s, n, a) {
      return R.killTweensOf(s, n, a)
  }
  ,
  t
}(de);
ot(N.prototype, {
  _targets: [],
  _lazy: 0,
  _startAt: 0,
  _op: 0,
  _onInit: 0
});
K("staggerTo,staggerFrom,staggerFromTo", function(r) {
  N[r] = function() {
      var t = new j
        , e = ri.call(arguments, 0);
      return e.splice(r === "staggerFromTo" ? 5 : 4, 0, 0),
      t[r].apply(t, e)
  }
});
var wi = function(t, e, i) {
  return t[e] = i
}
, rs = function(t, e, i) {
  return t[e](i)
}
, ja = function(t, e, i, s) {
  return t[e](s.fp, i)
}
, Za = function(t, e, i) {
  return t.setAttribute(e, i)
}
, Ai = function(t, e) {
  return I(t[e]) ? rs : ci(t[e]) && t.setAttribute ? Za : wi
}
, ss = function(t, e) {
  return e.set(e.t, e.p, Math.round((e.s + e.c * t) * 1e6) / 1e6, e)
}
, Ka = function(t, e) {
  return e.set(e.t, e.p, !!(e.s + e.c * t), e)
}
, ns = function(t, e) {
  var i = e._pt
    , s = "";
  if (!t && e.b)
      s = e.b;
  else if (t === 1 && e.e)
      s = e.e;
  else {
      for (; i; )
          s = i.p + (i.m ? i.m(i.s + i.c * t) : Math.round((i.s + i.c * t) * 1e4) / 1e4) + s,
          i = i._next;
      s += e.c
  }
  e.set(e.t, e.p, s, e)
}
, Ei = function(t, e) {
  for (var i = e._pt; i; )
      i.r(t, i.d),
      i = i._next
}
, Qa = function(t, e, i, s) {
  for (var n = this._pt, a; n; )
      a = n._next,
      n.p === s && n.modifier(t, e, i),
      n = a
}
, Ja = function(t) {
  for (var e = this._pt, i, s; e; )
      s = e._next,
      e.p === t && !e.op || e.op === t ? Pe(this, e, "_pt") : e.dep || (i = 1),
      e = s;
  return !i
}
, th = function(t, e, i, s) {
  s.mSet(t, e, s.m.call(s.tween, i, s.mt), s)
}
, as = function(t) {
  for (var e = t._pt, i, s, n, a; e; ) {
      for (i = e._next,
      s = n; s && s.pr > e.pr; )
          s = s._next;
      (e._prev = s ? s._prev : a) ? e._prev._next = e : n = e,
      (e._next = s) ? s._prev = e : a = e,
      e = i
  }
  t._pt = n
}
, Q = function() {
  function r(e, i, s, n, a, h, l, o, u) {
      this.t = i,
      this.s = n,
      this.c = a,
      this.p = s,
      this.r = h || ss,
      this.d = l || this,
      this.set = o || wi,
      this.pr = u || 0,
      this._next = e,
      e && (e._prev = this)
  }
  var t = r.prototype;
  return t.modifier = function(i, s, n) {
      this.mSet = this.mSet || this.set,
      this.set = th,
      this.m = i,
      this.mt = n,
      this.tween = s
  }
  ,
  r
}();
K(yi + "parent,duration,ease,delay,overwrite,runBackwards,startAt,yoyo,immediateRender,repeat,repeatDelay,data,paused,reversed,lazy,callbackScope,stringFilter,id,yoyoEase,stagger,inherit,repeatRefresh,keyframes,autoRevert,scrollTrigger", function(r) {
  return mi[r] = 1
});
rt.TweenMax = rt.TweenLite = N;
rt.TimelineLite = rt.TimelineMax = j;
R = new j({
  sortChildren: !1,
  defaults: Zt,
  autoRemoveChildren: !0,
  id: "root",
  smoothChildTiming: !0
});
it.stringFilter = jr;
var Nt = []
, be = {}
, eh = []
, ar = 0
, ih = 0
, Xe = function(t) {
  return (be[t] || eh).map(function(e) {
      return e()
  })
}
, hi = function() {
  var t = Date.now()
    , e = [];
  t - ar > 2 && (Xe("matchMediaInit"),
  Nt.forEach(function(i) {
      var s = i.queries, n = i.conditions, a, h, l, o;
      for (h in s)
          a = nt.matchMedia(s[h]).matches,
          a && (l = 1),
          a !== n[h] && (n[h] = a,
          o = 1);
      o && (i.revert(),
      l && e.push(i))
  }),
  Xe("matchMediaRevert"),
  e.forEach(function(i) {
      return i.onMatch(i)
  }),
  ar = t,
  Xe("matchMedia"))
}
, hs = function() {
  function r(e, i) {
      this.selector = i && si(i),
      this.data = [],
      this._r = [],
      this.isReverted = !1,
      this.id = ih++,
      e && this.add(e)
  }
  var t = r.prototype;
  return t.add = function(i, s, n) {
      I(i) && (n = s,
      s = i,
      i = I);
      var a = this
        , h = function() {
          var o = z, u = a.selector, c;
          return o && o !== a && o.data.push(a),
          n && (a.selector = si(n)),
          z = a,
          c = s.apply(a, arguments),
          I(c) && a._r.push(c),
          z = o,
          a.selector = u,
          a.isReverted = !1,
          c
      };
      return a.last = h,
      i === I ? h(a) : i ? a[i] = h : h
  }
  ,
  t.ignore = function(i) {
      var s = z;
      z = null,
      i(this),
      z = s
  }
  ,
  t.getTweens = function() {
      var i = [];
      return this.data.forEach(function(s) {
          return s instanceof r ? i.push.apply(i, s.getTweens()) : s instanceof N && !(s.parent && s.parent.data === "nested") && i.push(s)
      }),
      i
  }
  ,
  t.clear = function() {
      this._r.length = this.data.length = 0
  }
  ,
  t.kill = function(i, s) {
      var n = this;
      if (i) {
          var a = this.getTweens();
          this.data.forEach(function(l) {
              l.data === "isFlip" && (l.revert(),
              l.getChildren(!0, !0, !1).forEach(function(o) {
                  return a.splice(a.indexOf(o), 1)
              }))
          }),
          a.map(function(l) {
              return {
                  g: l.globalTime(0),
                  t: l
              }
          }).sort(function(l, o) {
              return o.g - l.g || -1
          }).forEach(function(l) {
              return l.t.revert(i)
          }),
          this.data.forEach(function(l) {
              return l instanceof j ? l.data !== "nested" && l.kill() : !(l instanceof N) && l.revert && l.revert(i)
          }),
          this._r.forEach(function(l) {
              return l(i, n)
          }),
          this.isReverted = !0
      } else
          this.data.forEach(function(l) {
              return l.kill && l.kill()
          });
      if (this.clear(),
      s)
          for (var h = Nt.length; h--; )
              Nt[h].id === this.id && Nt.splice(h, 1)
  }
  ,
  t.revert = function(i) {
      this.kill(i || {})
  }
  ,
  r
}()
, rh = function() {
  function r(e) {
      this.contexts = [],
      this.scope = e
  }
  var t = r.prototype;
  return t.add = function(i, s, n) {
      gt(i) || (i = {
          matches: i
      });
      var a = new hs(0,n || this.scope), h = a.conditions = {}, l, o, u;
      z && !a.selector && (a.selector = z.selector),
      this.contexts.push(a),
      s = a.add("onMatch", s),
      a.queries = i;
      for (o in i)
          o === "all" ? u = 1 : (l = nt.matchMedia(i[o]),
          l && (Nt.indexOf(a) < 0 && Nt.push(a),
          (h[o] = l.matches) && (u = 1),
          l.addListener ? l.addListener(hi) : l.addEventListener("change", hi)));
      return u && s(a),
      this
  }
  ,
  t.revert = function(i) {
      this.kill(i || {})
  }
  ,
  t.kill = function(i) {
      this.contexts.forEach(function(s) {
          return s.kill(i, !0)
      })
  }
  ,
  r
}()
, Se = {
  registerPlugin: function() {
      for (var t = arguments.length, e = new Array(t), i = 0; i < t; i++)
          e[i] = arguments[i];
      e.forEach(function(s) {
          return qr(s)
      })
  },
  timeline: function(t) {
      return new j(t)
  },
  getTweensOf: function(t, e) {
      return R.getTweensOf(t, e)
  },
  getProperty: function(t, e, i, s) {
      Y(t) && (t = ht(t)[0]);
      var n = It(t || {}).get
        , a = i ? Dr : Fr;
      return i === "native" && (i = ""),
      t && (e ? a((tt[e] && tt[e].get || n)(t, e, i, s)) : function(h, l, o) {
          return a((tt[h] && tt[h].get || n)(t, h, l, o))
      }
      )
  },
  quickSetter: function(t, e, i) {
      if (t = ht(t),
      t.length > 1) {
          var s = t.map(function(u) {
              return J.quickSetter(u, e, i)
          })
            , n = s.length;
          return function(u) {
              for (var c = n; c--; )
                  s[c](u)
          }
      }
      t = t[0] || {};
      var a = tt[e]
        , h = It(t)
        , l = h.harness && (h.harness.aliases || {})[e] || e
        , o = a ? function(u) {
          var c = new a;
          qt._pt = 0,
          c.init(t, i ? u + i : u, qt, 0, [t]),
          c.render(1, c),
          qt._pt && Ei(1, qt)
      }
      : h.set(t, l);
      return a ? o : function(u) {
          return o(t, l, i ? u + i : u, h, 1)
      }
  },
  quickTo: function(t, e, i) {
      var s, n = J.to(t, Ut((s = {},
      s[e] = "+=0.1",
      s.paused = !0,
      s), i || {})), a = function(l, o, u) {
          return n.resetTo(e, l, o, u)
      };
      return a.tween = n,
      a
  },
  isTweening: function(t) {
      return R.getTweensOf(t, !0).length > 0
  },
  defaults: function(t) {
      return t && t.ease && (t.ease = Vt(t.ease, Zt.ease)),
      er(Zt, t || {})
  },
  config: function(t) {
      return er(it, t || {})
  },
  registerEffect: function(t) {
      var e = t.name
        , i = t.effect
        , s = t.plugins
        , n = t.defaults
        , a = t.extendTimeline;
      (s || "").split(",").forEach(function(h) {
          return h && !tt[h] && !rt[h] && Ae(e + " effect requires " + h + " plugin.")
      }),
      Ve[e] = function(h, l, o) {
          return i(ht(h), ot(l || {}, n), o)
      }
      ,
      a && (j.prototype[e] = function(h, l, o) {
          return this.add(Ve[e](h, gt(l) ? l : (o = l) && {}, this), o)
      }
      )
  },
  registerEase: function(t, e) {
      k[t] = Vt(e)
  },
  parseEase: function(t, e) {
      return arguments.length ? Vt(t, e) : k
  },
  getById: function(t) {
      return R.getById(t)
  },
  exportRoot: function(t, e) {
      t === void 0 && (t = {});
      var i = new j(t), s, n;
      for (i.smoothChildTiming = Z(t.smoothChildTiming),
      R.remove(i),
      i._dp = 0,
      i._time = i._tTime = R._time,
      s = R._first; s; )
          n = s._next,
          (e || !(!s._dur && s instanceof N && s.vars.onComplete === s._targets[0])) && dt(i, s, s._start - s._delay),
          s = n;
      return dt(R, i, 0),
      i
  },
  context: function(t, e) {
      return t ? new hs(t,e) : z
  },
  matchMedia: function(t) {
      return new rh(t)
  },
  matchMediaRefresh: function() {
      return Nt.forEach(function(t) {
          var e = t.conditions, i, s;
          for (s in e)
              e[s] && (e[s] = !1,
              i = 1);
          i && t.revert()
      }) || hi()
  },
  addEventListener: function(t, e) {
      var i = be[t] || (be[t] = []);
      ~i.indexOf(e) || i.push(e)
  },
  removeEventListener: function(t, e) {
      var i = be[t]
        , s = i && i.indexOf(e);
      s >= 0 && i.splice(s, 1)
  },
  utils: {
      wrap: Ra,
      wrapYoyo: $a,
      distribute: Vr,
      random: Ur,
      snap: Nr,
      normalize: La,
      getUnit: G,
      clamp: Pa,
      splitColor: Wr,
      toArray: ht,
      selector: si,
      mapRange: Xr,
      pipe: Fa,
      unitize: Da,
      interpolate: za,
      shuffle: Br
  },
  install: Sr,
  effects: Ve,
  ticker: et,
  updateRoot: j.updateRoot,
  plugins: tt,
  globalTimeline: R,
  core: {
      PropTween: Q,
      globals: Cr,
      Tween: N,
      Timeline: j,
      Animation: de,
      getCache: It,
      _removeLinkedListItem: Pe,
      reverting: function() {
          return q
      },
      context: function(t) {
          return t && z && (z.data.push(t),
          t._ctx = z),
          z
      },
      suppressOverwrites: function(t) {
          return di = t
      }
  }
};
K("to,from,fromTo,delayedCall,set,killTweensOf", function(r) {
  return Se[r] = N[r]
});
et.add(j.updateRoot);
qt = Se.to({}, {
  duration: 0
});
var sh = function(t, e) {
  for (var i = t._pt; i && i.p !== e && i.op !== e && i.fp !== e; )
      i = i._next;
  return i
}
, nh = function(t, e) {
  var i = t._targets, s, n, a;
  for (s in e)
      for (n = i.length; n--; )
          a = t._ptLookup[n][s],
          a && (a = a.d) && (a._pt && (a = sh(a, s)),
          a && a.modifier && a.modifier(e[s], t, i[n], s))
}
, Ge = function(t, e) {
  return {
      name: t,
      rawVars: 1,
      init: function(s, n, a) {
          a._onInit = function(h) {
              var l, o;
              if (Y(n) && (l = {},
              K(n, function(u) {
                  return l[u] = 1
              }),
              n = l),
              e) {
                  l = {};
                  for (o in n)
                      l[o] = e(n[o]);
                  n = l
              }
              nh(h, n)
          }
      }
  }
}
, J = Se.registerPlugin({
  name: "attr",
  init: function(t, e, i, s, n) {
      var a, h, l;
      this.tween = i;
      for (a in e)
          l = t.getAttribute(a) || "",
          h = this.add(t, "setAttribute", (l || 0) + "", e[a], s, n, 0, 0, a),
          h.op = a,
          h.b = l,
          this._props.push(a)
  },
  render: function(t, e) {
      for (var i = e._pt; i; )
          q ? i.set(i.t, i.p, i.b, i) : i.r(t, i.d),
          i = i._next
  }
}, {
  name: "endArray",
  init: function(t, e) {
      for (var i = e.length; i--; )
          this.add(t, i, t[i] || 0, e[i], 0, 0, 0, 0, 0, 1)
  }
}, Ge("roundProps", ni), Ge("modifiers"), Ge("snap", Nr)) || Se;
N.version = j.version = J.version = "3.12.1";
Mr = 1;
pi() && Jt();
k.Power0;
k.Power1;
k.Power2;
k.Power3;
k.Power4;
k.Linear;
k.Quad;
k.Cubic;
k.Quart;
k.Quint;
k.Strong;
k.Elastic;
k.Back;
k.SteppedEase;
k.Bounce;
k.Sine;
k.Expo;
k.Circ;
/*!
* CSSPlugin 3.12.1
* https://greensock.com
*
* Copyright 2008-2023, GreenSock. All rights reserved.
* Subject to the terms at https://greensock.com/standard-license or for
* Club GreenSock members, the agreement issued with that membership.
* @author: Jack Doyle, jack@greensock.com
*/
var hr, At, Ht, Ti, zt, lr, Mi, ah = function() {
  return typeof window < "u"
}, vt = {}, $t = 180 / Math.PI, jt = Math.PI / 180, Xt = Math.atan2, or = 1e8, Si = /([A-Z])/g, hh = /(left|right|width|margin|padding|x)/i, lh = /[\s,\(]\S/, ct = {
  autoAlpha: "opacity,visibility",
  scale: "scaleX,scaleY",
  alpha: "opacity"
}, li = function(t, e) {
  return e.set(e.t, e.p, Math.round((e.s + e.c * t) * 1e4) / 1e4 + e.u, e)
}, oh = function(t, e) {
  return e.set(e.t, e.p, t === 1 ? e.e : Math.round((e.s + e.c * t) * 1e4) / 1e4 + e.u, e)
}, uh = function(t, e) {
  return e.set(e.t, e.p, t ? Math.round((e.s + e.c * t) * 1e4) / 1e4 + e.u : e.b, e)
}, fh = function(t, e) {
  var i = e.s + e.c * t;
  e.set(e.t, e.p, ~~(i + (i < 0 ? -.5 : .5)) + e.u, e)
}, ls = function(t, e) {
  return e.set(e.t, e.p, t ? e.e : e.b, e)
}, os = function(t, e) {
  return e.set(e.t, e.p, t !== 1 ? e.b : e.e, e)
}, dh = function(t, e, i) {
  return t.style[e] = i
}, ch = function(t, e, i) {
  return t.style.setProperty(e, i)
}, ph = function(t, e, i) {
  return t._gsap[e] = i
}, _h = function(t, e, i) {
  return t._gsap.scaleX = t._gsap.scaleY = i
}, gh = function(t, e, i, s, n) {
  var a = t._gsap;
  a.scaleX = a.scaleY = i,
  a.renderTransform(n, a)
}, mh = function(t, e, i, s, n) {
  var a = t._gsap;
  a[e] = i,
  a.renderTransform(n, a)
}, $ = "transform", ft = $ + "Origin", yh = function r(t, e) {
  var i = this
    , s = this.target
    , n = s.style;
  if (t in vt && n) {
      if (this.tfm = this.tfm || {},
      t !== "transform")
          t = ct[t] || t,
          ~t.indexOf(",") ? t.split(",").forEach(function(a) {
              return i.tfm[a] = xt(s, a)
          }) : this.tfm[t] = s._gsap.x ? s._gsap[t] : xt(s, t);
      else
          return ct.transform.split(",").forEach(function(a) {
              return r.call(i, a, e)
          });
      if (this.props.indexOf($) >= 0)
          return;
      s._gsap.svg && (this.svgo = s.getAttribute("data-svg-origin"),
      this.props.push(ft, e, "")),
      t = $
  }
  (n || e) && this.props.push(t, e, n[t])
}, us = function(t) {
  t.translate && (t.removeProperty("translate"),
  t.removeProperty("scale"),
  t.removeProperty("rotate"))
}, xh = function() {
  var t = this.props, e = this.target, i = e.style, s = e._gsap, n, a;
  for (n = 0; n < t.length; n += 3)
      t[n + 1] ? e[t[n]] = t[n + 2] : t[n + 2] ? i[t[n]] = t[n + 2] : i.removeProperty(t[n].substr(0, 2) === "--" ? t[n] : t[n].replace(Si, "-$1").toLowerCase());
  if (this.tfm) {
      for (a in this.tfm)
          s[a] = this.tfm[a];
      s.svg && (s.renderTransform(),
      e.setAttribute("data-svg-origin", this.svgo || "")),
      n = Mi(),
      (!n || !n.isStart) && !i[$] && (us(i),
      s.uncache = 1)
  }
}, fs = function(t, e) {
  var i = {
      target: t,
      props: [],
      revert: xh,
      save: yh
  };
  return t._gsap || J.core.getCache(t),
  e && e.split(",").forEach(function(s) {
      return i.save(s)
  }),
  i
}, ds, oi = function(t, e) {
  var i = At.createElementNS ? At.createElementNS((e || "http://www.w3.org/1999/xhtml").replace(/^https/, "http"), t) : At.createElement(t);
  return i.style ? i : At.createElement(t)
}, _t = function r(t, e, i) {
  var s = getComputedStyle(t);
  return s[e] || s.getPropertyValue(e.replace(Si, "-$1").toLowerCase()) || s.getPropertyValue(e) || !i && r(t, te(e) || e, 1) || ""
}, ur = "O,Moz,ms,Ms,Webkit".split(","), te = function(t, e, i) {
  var s = e || zt
    , n = s.style
    , a = 5;
  if (t in n && !i)
      return t;
  for (t = t.charAt(0).toUpperCase() + t.substr(1); a-- && !(ur[a] + t in n); )
      ;
  return a < 0 ? null : (a === 3 ? "ms" : a >= 0 ? ur[a] : "") + t
}, ui = function() {
  ah() && window.document && (hr = window,
  At = hr.document,
  Ht = At.documentElement,
  zt = oi("div") || {
      style: {}
  },
  oi("div"),
  $ = te($),
  ft = $ + "Origin",
  zt.style.cssText = "border-width:0;line-height:0;position:absolute;padding:0",
  ds = !!te("perspective"),
  Mi = J.core.reverting,
  Ti = 1)
}, qe = function r(t) {
  var e = oi("svg", this.ownerSVGElement && this.ownerSVGElement.getAttribute("xmlns") || "http://www.w3.org/2000/svg"), i = this.parentNode, s = this.nextSibling, n = this.style.cssText, a;
  if (Ht.appendChild(e),
  e.appendChild(this),
  this.style.display = "block",
  t)
      try {
          a = this.getBBox(),
          this._gsapBBox = this.getBBox,
          this.getBBox = r
      } catch {}
  else
      this._gsapBBox && (a = this._gsapBBox());
  return i && (s ? i.insertBefore(this, s) : i.appendChild(this)),
  Ht.removeChild(e),
  this.style.cssText = n,
  a
}, fr = function(t, e) {
  for (var i = e.length; i--; )
      if (t.hasAttribute(e[i]))
          return t.getAttribute(e[i])
}, cs = function(t) {
  var e;
  try {
      e = t.getBBox()
  } catch {
      e = qe.call(t, !0)
  }
  return e && (e.width || e.height) || t.getBBox === qe || (e = qe.call(t, !0)),
  e && !e.width && !e.x && !e.y ? {
      x: +fr(t, ["x", "cx", "x1"]) || 0,
      y: +fr(t, ["y", "cy", "y1"]) || 0,
      width: 0,
      height: 0
  } : e
}, ps = function(t) {
  return !!(t.getCTM && (!t.parentNode || t.ownerSVGElement) && cs(t))
}, ce = function(t, e) {
  if (e) {
      var i = t.style;
      e in vt && e !== ft && (e = $),
      i.removeProperty ? ((e.substr(0, 2) === "ms" || e.substr(0, 6) === "webkit") && (e = "-" + e),
      i.removeProperty(e.replace(Si, "-$1").toLowerCase())) : i.removeAttribute(e)
  }
}, Et = function(t, e, i, s, n, a) {
  var h = new Q(t._pt,e,i,0,1,a ? os : ls);
  return t._pt = h,
  h.b = s,
  h.e = n,
  t._props.push(i),
  h
}, dr = {
  deg: 1,
  rad: 1,
  turn: 1
}, bh = {
  grid: 1,
  flex: 1
}, Ct = function r(t, e, i, s) {
  var n = parseFloat(i) || 0, a = (i + "").trim().substr((n + "").length) || "px", h = zt.style, l = hh.test(e), o = t.tagName.toLowerCase() === "svg", u = (o ? "client" : "offset") + (l ? "Width" : "Height"), c = 100, d = s === "px", p = s === "%", g, f, m, _;
  return s === a || !n || dr[s] || dr[a] ? n : (a !== "px" && !d && (n = r(t, e, i, "px")),
  _ = t.getCTM && ps(t),
  (p || a === "%") && (vt[e] || ~e.indexOf("adius")) ? (g = _ ? t.getBBox()[l ? "width" : "height"] : t[u],
  B(p ? n / g * c : n / 100 * g)) : (h[l ? "width" : "height"] = c + (d ? a : s),
  f = ~e.indexOf("adius") || s === "em" && t.appendChild && !o ? t : t.parentNode,
  _ && (f = (t.ownerSVGElement || {}).parentNode),
  (!f || f === At || !f.appendChild) && (f = At.body),
  m = f._gsap,
  m && p && m.width && l && m.time === et.time && !m.uncache ? B(n / m.width * c) : ((p || a === "%") && !bh[_t(f, "display")] && (h.position = _t(t, "position")),
  f === t && (h.position = "static"),
  f.appendChild(zt),
  g = zt[u],
  f.removeChild(zt),
  h.position = "absolute",
  l && p && (m = It(f),
  m.time = et.time,
  m.width = f[u]),
  B(d ? g * n / c : g && n ? c / g * n : 0))))
}, xt = function(t, e, i, s) {
  var n;
  return Ti || ui(),
  e in ct && e !== "transform" && (e = ct[e],
  ~e.indexOf(",") && (e = e.split(",")[0])),
  vt[e] && e !== "transform" ? (n = _e(t, s),
  n = e !== "transformOrigin" ? n[e] : n.svg ? n.origin : Oe(_t(t, ft)) + " " + n.zOrigin + "px") : (n = t.style[e],
  (!n || n === "auto" || s || ~(n + "").indexOf("calc(")) && (n = Ce[e] && Ce[e](t, e, i) || _t(t, e) || Pr(t, e) || (e === "opacity" ? 1 : 0))),
  i && !~(n + "").trim().indexOf(" ") ? Ct(t, e, n, i) + i : n
}, vh = function(t, e, i, s) {
  if (!i || i === "none") {
      var n = te(e, t, 1)
        , a = n && _t(t, n, 1);
      a && a !== i ? (e = n,
      i = a) : e === "borderColor" && (i = _t(t, "borderTopColor"))
  }
  var h = new Q(this._pt,t.style,e,0,1,ns), l = 0, o = 0, u, c, d, p, g, f, m, _, y, x, b, v;
  if (h.b = i,
  h.e = s,
  i += "",
  s += "",
  s === "auto" && (t.style[e] = s,
  s = _t(t, e) || s,
  t.style[e] = i),
  u = [i, s],
  jr(u),
  i = u[0],
  s = u[1],
  d = i.match(Gt) || [],
  v = s.match(Gt) || [],
  v.length) {
      for (; c = Gt.exec(s); )
          m = c[0],
          y = s.substring(l, c.index),
          g ? g = (g + 1) % 5 : (y.substr(-5) === "rgba(" || y.substr(-5) === "hsla(") && (g = 1),
          m !== (f = d[o++] || "") && (p = parseFloat(f) || 0,
          b = f.substr((p + "").length),
          m.charAt(1) === "=" && (m = Wt(p, m) + b),
          _ = parseFloat(m),
          x = m.substr((_ + "").length),
          l = Gt.lastIndex - x.length,
          x || (x = x || it.units[e] || b,
          l === s.length && (s += x,
          h.e += x)),
          b !== x && (p = Ct(t, e, f, x) || 0),
          h._pt = {
              _next: h._pt,
              p: y || o === 1 ? y : ",",
              s: p,
              c: _ - p,
              m: g && g < 4 || e === "zIndex" ? Math.round : 0
          });
      h.c = l < s.length ? s.substring(l, s.length) : ""
  } else
      h.r = e === "display" && s === "none" ? os : ls;
  return Er.test(s) && (h.e = 0),
  this._pt = h,
  h
}, cr = {
  top: "0%",
  bottom: "100%",
  left: "0%",
  right: "100%",
  center: "50%"
}, wh = function(t) {
  var e = t.split(" ")
    , i = e[0]
    , s = e[1] || "50%";
  return (i === "top" || i === "bottom" || s === "left" || s === "right") && (t = i,
  i = s,
  s = t),
  e[0] = cr[i] || i,
  e[1] = cr[s] || s,
  e.join(" ")
}, Ah = function(t, e) {
  if (e.tween && e.tween._time === e.tween._dur) {
      var i = e.t, s = i.style, n = e.u, a = i._gsap, h, l, o;
      if (n === "all" || n === !0)
          s.cssText = "",
          l = 1;
      else
          for (n = n.split(","),
          o = n.length; --o > -1; )
              h = n[o],
              vt[h] && (l = 1,
              h = h === "transformOrigin" ? ft : $),
              ce(i, h);
      l && (ce(i, $),
      a && (a.svg && i.removeAttribute("transform"),
      _e(i, 1),
      a.uncache = 1,
      us(s)))
  }
}, Ce = {
  clearProps: function(t, e, i, s, n) {
      if (n.data !== "isFromStart") {
          var a = t._pt = new Q(t._pt,e,i,0,0,Ah);
          return a.u = s,
          a.pr = -10,
          a.tween = n,
          t._props.push(i),
          1
      }
  }
}, pe = [1, 0, 0, 1, 0, 0], _s = {}, gs = function(t) {
  return t === "matrix(1, 0, 0, 1, 0, 0)" || t === "none" || !t
}, pr = function(t) {
  var e = _t(t, $);
  return gs(e) ? pe : e.substr(7).match(Ar).map(B)
}, Ci = function(t, e) {
  var i = t._gsap || It(t), s = t.style, n = pr(t), a, h, l, o;
  return i.svg && t.getAttribute("transform") ? (l = t.transform.baseVal.consolidate().matrix,
  n = [l.a, l.b, l.c, l.d, l.e, l.f],
  n.join(",") === "1,0,0,1,0,0" ? pe : n) : (n === pe && !t.offsetParent && t !== Ht && !i.svg && (l = s.display,
  s.display = "block",
  a = t.parentNode,
  (!a || !t.offsetParent) && (o = 1,
  h = t.nextElementSibling,
  Ht.appendChild(t)),
  n = pr(t),
  l ? s.display = l : ce(t, "display"),
  o && (h ? a.insertBefore(t, h) : a ? a.appendChild(t) : Ht.removeChild(t))),
  e && n.length > 6 ? [n[0], n[1], n[4], n[5], n[12], n[13]] : n)
}, fi = function(t, e, i, s, n, a) {
  var h = t._gsap, l = n || Ci(t, !0), o = h.xOrigin || 0, u = h.yOrigin || 0, c = h.xOffset || 0, d = h.yOffset || 0, p = l[0], g = l[1], f = l[2], m = l[3], _ = l[4], y = l[5], x = e.split(" "), b = parseFloat(x[0]) || 0, v = parseFloat(x[1]) || 0, w, T, E, A;
  i ? l !== pe && (T = p * m - g * f) && (E = b * (m / T) + v * (-f / T) + (f * y - m * _) / T,
  A = b * (-g / T) + v * (p / T) - (p * y - g * _) / T,
  b = E,
  v = A) : (w = cs(t),
  b = w.x + (~x[0].indexOf("%") ? b / 100 * w.width : b),
  v = w.y + (~(x[1] || x[0]).indexOf("%") ? v / 100 * w.height : v)),
  s || s !== !1 && h.smooth ? (_ = b - o,
  y = v - u,
  h.xOffset = c + (_ * p + y * f) - _,
  h.yOffset = d + (_ * g + y * m) - y) : h.xOffset = h.yOffset = 0,
  h.xOrigin = b,
  h.yOrigin = v,
  h.smooth = !!s,
  h.origin = e,
  h.originIsAbsolute = !!i,
  t.style[ft] = "0px 0px",
  a && (Et(a, h, "xOrigin", o, b),
  Et(a, h, "yOrigin", u, v),
  Et(a, h, "xOffset", c, h.xOffset),
  Et(a, h, "yOffset", d, h.yOffset)),
  t.setAttribute("data-svg-origin", b + " " + v)
}, _e = function(t, e) {
  var i = t._gsap || new Jr(t);
  if ("x"in i && !e && !i.uncache)
      return i;
  var s = t.style, n = i.scaleX < 0, a = "px", h = "deg", l = getComputedStyle(t), o = _t(t, ft) || "0", u, c, d, p, g, f, m, _, y, x, b, v, w, T, E, A, S, C, O, P, M, F, U, V, H, Pt, kt, ee, Ft, Oi, mt, Dt;
  return u = c = d = f = m = _ = y = x = b = 0,
  p = g = 1,
  i.svg = !!(t.getCTM && ps(t)),
  l.translate && ((l.translate !== "none" || l.scale !== "none" || l.rotate !== "none") && (s[$] = (l.translate !== "none" ? "translate3d(" + (l.translate + " 0 0").split(" ").slice(0, 3).join(", ") + ") " : "") + (l.rotate !== "none" ? "rotate(" + l.rotate + ") " : "") + (l.scale !== "none" ? "scale(" + l.scale.split(" ").join(",") + ") " : "") + (l[$] !== "none" ? l[$] : "")),
  s.scale = s.rotate = s.translate = "none"),
  T = Ci(t, i.svg),
  i.svg && (i.uncache ? (H = t.getBBox(),
  o = i.xOrigin - H.x + "px " + (i.yOrigin - H.y) + "px",
  V = "") : V = !e && t.getAttribute("data-svg-origin"),
  fi(t, V || o, !!V || i.originIsAbsolute, i.smooth !== !1, T)),
  v = i.xOrigin || 0,
  w = i.yOrigin || 0,
  T !== pe && (C = T[0],
  O = T[1],
  P = T[2],
  M = T[3],
  u = F = T[4],
  c = U = T[5],
  T.length === 6 ? (p = Math.sqrt(C * C + O * O),
  g = Math.sqrt(M * M + P * P),
  f = C || O ? Xt(O, C) * $t : 0,
  y = P || M ? Xt(P, M) * $t + f : 0,
  y && (g *= Math.abs(Math.cos(y * jt))),
  i.svg && (u -= v - (v * C + w * P),
  c -= w - (v * O + w * M))) : (Dt = T[6],
  Oi = T[7],
  kt = T[8],
  ee = T[9],
  Ft = T[10],
  mt = T[11],
  u = T[12],
  c = T[13],
  d = T[14],
  E = Xt(Dt, Ft),
  m = E * $t,
  E && (A = Math.cos(-E),
  S = Math.sin(-E),
  V = F * A + kt * S,
  H = U * A + ee * S,
  Pt = Dt * A + Ft * S,
  kt = F * -S + kt * A,
  ee = U * -S + ee * A,
  Ft = Dt * -S + Ft * A,
  mt = Oi * -S + mt * A,
  F = V,
  U = H,
  Dt = Pt),
  E = Xt(-P, Ft),
  _ = E * $t,
  E && (A = Math.cos(-E),
  S = Math.sin(-E),
  V = C * A - kt * S,
  H = O * A - ee * S,
  Pt = P * A - Ft * S,
  mt = M * S + mt * A,
  C = V,
  O = H,
  P = Pt),
  E = Xt(O, C),
  f = E * $t,
  E && (A = Math.cos(E),
  S = Math.sin(E),
  V = C * A + O * S,
  H = F * A + U * S,
  O = O * A - C * S,
  U = U * A - F * S,
  C = V,
  F = H),
  m && Math.abs(m) + Math.abs(f) > 359.9 && (m = f = 0,
  _ = 180 - _),
  p = B(Math.sqrt(C * C + O * O + P * P)),
  g = B(Math.sqrt(U * U + Dt * Dt)),
  E = Xt(F, U),
  y = Math.abs(E) > 2e-4 ? E * $t : 0,
  b = mt ? 1 / (mt < 0 ? -mt : mt) : 0),
  i.svg && (V = t.getAttribute("transform"),
  i.forceCSS = t.setAttribute("transform", "") || !gs(_t(t, $)),
  V && t.setAttribute("transform", V))),
  Math.abs(y) > 90 && Math.abs(y) < 270 && (n ? (p *= -1,
  y += f <= 0 ? 180 : -180,
  f += f <= 0 ? 180 : -180) : (g *= -1,
  y += y <= 0 ? 180 : -180)),
  e = e || i.uncache,
  i.x = u - ((i.xPercent = u && (!e && i.xPercent || (Math.round(t.offsetWidth / 2) === Math.round(-u) ? -50 : 0))) ? t.offsetWidth * i.xPercent / 100 : 0) + a,
  i.y = c - ((i.yPercent = c && (!e && i.yPercent || (Math.round(t.offsetHeight / 2) === Math.round(-c) ? -50 : 0))) ? t.offsetHeight * i.yPercent / 100 : 0) + a,
  i.z = d + a,
  i.scaleX = B(p),
  i.scaleY = B(g),
  i.rotation = B(f) + h,
  i.rotationX = B(m) + h,
  i.rotationY = B(_) + h,
  i.skewX = y + h,
  i.skewY = x + h,
  i.transformPerspective = b + a,
  (i.zOrigin = parseFloat(o.split(" ")[2]) || 0) && (s[ft] = Oe(o)),
  i.xOffset = i.yOffset = 0,
  i.force3D = it.force3D,
  i.renderTransform = i.svg ? Th : ds ? ms : Eh,
  i.uncache = 0,
  i
}, Oe = function(t) {
  return (t = t.split(" "))[0] + " " + t[1]
}, We = function(t, e, i) {
  var s = G(e);
  return B(parseFloat(e) + parseFloat(Ct(t, "x", i + "px", s))) + s
}, Eh = function(t, e) {
  e.z = "0px",
  e.rotationY = e.rotationX = "0deg",
  e.force3D = 0,
  ms(t, e)
}, Lt = "0deg", ie = "0px", Rt = ") ", ms = function(t, e) {
  var i = e || this
    , s = i.xPercent
    , n = i.yPercent
    , a = i.x
    , h = i.y
    , l = i.z
    , o = i.rotation
    , u = i.rotationY
    , c = i.rotationX
    , d = i.skewX
    , p = i.skewY
    , g = i.scaleX
    , f = i.scaleY
    , m = i.transformPerspective
    , _ = i.force3D
    , y = i.target
    , x = i.zOrigin
    , b = ""
    , v = _ === "auto" && t && t !== 1 || _ === !0;
  if (x && (c !== Lt || u !== Lt)) {
      var w = parseFloat(u) * jt, T = Math.sin(w), E = Math.cos(w), A;
      w = parseFloat(c) * jt,
      A = Math.cos(w),
      a = We(y, a, T * A * -x),
      h = We(y, h, -Math.sin(w) * -x),
      l = We(y, l, E * A * -x + x)
  }
  m !== ie && (b += "perspective(" + m + Rt),
  (s || n) && (b += "translate(" + s + "%, " + n + "%) "),
  (v || a !== ie || h !== ie || l !== ie) && (b += l !== ie || v ? "translate3d(" + a + ", " + h + ", " + l + ") " : "translate(" + a + ", " + h + Rt),
  o !== Lt && (b += "rotate(" + o + Rt),
  u !== Lt && (b += "rotateY(" + u + Rt),
  c !== Lt && (b += "rotateX(" + c + Rt),
  (d !== Lt || p !== Lt) && (b += "skew(" + d + ", " + p + Rt),
  (g !== 1 || f !== 1) && (b += "scale(" + g + ", " + f + Rt),
  y.style[$] = b || "translate(0, 0)"
}, Th = function(t, e) {
  var i = e || this, s = i.xPercent, n = i.yPercent, a = i.x, h = i.y, l = i.rotation, o = i.skewX, u = i.skewY, c = i.scaleX, d = i.scaleY, p = i.target, g = i.xOrigin, f = i.yOrigin, m = i.xOffset, _ = i.yOffset, y = i.forceCSS, x = parseFloat(a), b = parseFloat(h), v, w, T, E, A;
  l = parseFloat(l),
  o = parseFloat(o),
  u = parseFloat(u),
  u && (u = parseFloat(u),
  o += u,
  l += u),
  l || o ? (l *= jt,
  o *= jt,
  v = Math.cos(l) * c,
  w = Math.sin(l) * c,
  T = Math.sin(l - o) * -d,
  E = Math.cos(l - o) * d,
  o && (u *= jt,
  A = Math.tan(o - u),
  A = Math.sqrt(1 + A * A),
  T *= A,
  E *= A,
  u && (A = Math.tan(u),
  A = Math.sqrt(1 + A * A),
  v *= A,
  w *= A)),
  v = B(v),
  w = B(w),
  T = B(T),
  E = B(E)) : (v = c,
  E = d,
  w = T = 0),
  (x && !~(a + "").indexOf("px") || b && !~(h + "").indexOf("px")) && (x = Ct(p, "x", a, "px"),
  b = Ct(p, "y", h, "px")),
  (g || f || m || _) && (x = B(x + g - (g * v + f * T) + m),
  b = B(b + f - (g * w + f * E) + _)),
  (s || n) && (A = p.getBBox(),
  x = B(x + s / 100 * A.width),
  b = B(b + n / 100 * A.height)),
  A = "matrix(" + v + "," + w + "," + T + "," + E + "," + x + "," + b + ")",
  p.setAttribute("transform", A),
  y && (p.style[$] = A)
}, Mh = function(t, e, i, s, n) {
  var a = 360, h = Y(n), l = parseFloat(n) * (h && ~n.indexOf("rad") ? $t : 1), o = l - s, u = s + o + "deg", c, d;
  return h && (c = n.split("_")[1],
  c === "short" && (o %= a,
  o !== o % (a / 2) && (o += o < 0 ? a : -a)),
  c === "cw" && o < 0 ? o = (o + a * or) % a - ~~(o / a) * a : c === "ccw" && o > 0 && (o = (o - a * or) % a - ~~(o / a) * a)),
  t._pt = d = new Q(t._pt,e,i,s,o,oh),
  d.e = u,
  d.u = "deg",
  t._props.push(i),
  d
}, _r = function(t, e) {
  for (var i in e)
      t[i] = e[i];
  return t
}, Sh = function(t, e, i) {
  var s = _r({}, i._gsap), n = "perspective,force3D,transformOrigin,svgOrigin", a = i.style, h, l, o, u, c, d, p, g;
  s.svg ? (o = i.getAttribute("transform"),
  i.setAttribute("transform", ""),
  a[$] = e,
  h = _e(i, 1),
  ce(i, $),
  i.setAttribute("transform", o)) : (o = getComputedStyle(i)[$],
  a[$] = e,
  h = _e(i, 1),
  a[$] = o);
  for (l in vt)
      o = s[l],
      u = h[l],
      o !== u && n.indexOf(l) < 0 && (p = G(o),
      g = G(u),
      c = p !== g ? Ct(i, l, o, g) : parseFloat(o),
      d = parseFloat(u),
      t._pt = new Q(t._pt,h,l,c,d - c,li),
      t._pt.u = g || 0,
      t._props.push(l));
  _r(h, s)
};
K("padding,margin,Width,Radius", function(r, t) {
  var e = "Top"
    , i = "Right"
    , s = "Bottom"
    , n = "Left"
    , a = (t < 3 ? [e, i, s, n] : [e + n, e + i, s + i, s + n]).map(function(h) {
      return t < 2 ? r + h : "border" + h + r
  });
  Ce[t > 1 ? "border" + r : r] = function(h, l, o, u, c) {
      var d, p;
      if (arguments.length < 4)
          return d = a.map(function(g) {
              return xt(h, g, o)
          }),
          p = d.join(" "),
          p.split(d[0]).length === 5 ? d[0] : p;
      d = (u + "").split(" "),
      p = {},
      a.forEach(function(g, f) {
          return p[g] = d[f] = d[f] || d[(f - 1) / 2 | 0]
      }),
      h.init(l, p, c)
  }
});
var ys = {
  name: "css",
  register: ui,
  targetTest: function(t) {
      return t.style && t.nodeType
  },
  init: function(t, e, i, s, n) {
      var a = this._props, h = t.style, l = i.vars.startAt, o, u, c, d, p, g, f, m, _, y, x, b, v, w, T, E;
      Ti || ui(),
      this.styles = this.styles || fs(t),
      E = this.styles.props,
      this.tween = i;
      for (f in e)
          if (f !== "autoRound" && (u = e[f],
          !(tt[f] && ts(f, e, i, s, t, n)))) {
              if (p = typeof u,
              g = Ce[f],
              p === "function" && (u = u.call(i, s, t, n),
              p = typeof u),
              p === "string" && ~u.indexOf("random(") && (u = ue(u)),
              g)
                  g(this, t, f, u, i) && (T = 1);
              else if (f.substr(0, 2) === "--")
                  o = (getComputedStyle(t).getPropertyValue(f) + "").trim(),
                  u += "",
                  Mt.lastIndex = 0,
                  Mt.test(o) || (m = G(o),
                  _ = G(u)),
                  _ ? m !== _ && (o = Ct(t, f, o, _) + _) : m && (u += m),
                  this.add(h, "setProperty", o, u, s, n, 0, 0, f),
                  a.push(f),
                  E.push(f, 0, h[f]);
              else if (p !== "undefined") {
                  if (l && f in l ? (o = typeof l[f] == "function" ? l[f].call(i, s, t, n) : l[f],
                  Y(o) && ~o.indexOf("random(") && (o = ue(o)),
                  G(o + "") || (o += it.units[f] || G(xt(t, f)) || ""),
                  (o + "").charAt(1) === "=" && (o = xt(t, f))) : o = xt(t, f),
                  d = parseFloat(o),
                  y = p === "string" && u.charAt(1) === "=" && u.substr(0, 2),
                  y && (u = u.substr(2)),
                  c = parseFloat(u),
                  f in ct && (f === "autoAlpha" && (d === 1 && xt(t, "visibility") === "hidden" && c && (d = 0),
                  E.push("visibility", 0, h.visibility),
                  Et(this, h, "visibility", d ? "inherit" : "hidden", c ? "inherit" : "hidden", !c)),
                  f !== "scale" && f !== "transform" && (f = ct[f],
                  ~f.indexOf(",") && (f = f.split(",")[0]))),
                  x = f in vt,
                  x) {
                      if (this.styles.save(f),
                      b || (v = t._gsap,
                      v.renderTransform && !e.parseTransform || _e(t, e.parseTransform),
                      w = e.smoothOrigin !== !1 && v.smooth,
                      b = this._pt = new Q(this._pt,h,$,0,1,v.renderTransform,v,0,-1),
                      b.dep = 1),
                      f === "scale")
                          this._pt = new Q(this._pt,v,"scaleY",v.scaleY,(y ? Wt(v.scaleY, y + c) : c) - v.scaleY || 0,li),
                          this._pt.u = 0,
                          a.push("scaleY", f),
                          f += "X";
                      else if (f === "transformOrigin") {
                          E.push(ft, 0, h[ft]),
                          u = wh(u),
                          v.svg ? fi(t, u, 0, w, 0, this) : (_ = parseFloat(u.split(" ")[2]) || 0,
                          _ !== v.zOrigin && Et(this, v, "zOrigin", v.zOrigin, _),
                          Et(this, h, f, Oe(o), Oe(u)));
                          continue
                      } else if (f === "svgOrigin") {
                          fi(t, u, 1, w, 0, this);
                          continue
                      } else if (f in _s) {
                          Mh(this, v, f, d, y ? Wt(d, y + u) : u);
                          continue
                      } else if (f === "smoothOrigin") {
                          Et(this, v, "smooth", v.smooth, u);
                          continue
                      } else if (f === "force3D") {
                          v[f] = u;
                          continue
                      } else if (f === "transform") {
                          Sh(this, u, t);
                          continue
                      }
                  } else
                      f in h || (f = te(f) || f);
                  if (x || (c || c === 0) && (d || d === 0) && !lh.test(u) && f in h)
                      m = (o + "").substr((d + "").length),
                      c || (c = 0),
                      _ = G(u) || (f in it.units ? it.units[f] : m),
                      m !== _ && (d = Ct(t, f, o, _)),
                      this._pt = new Q(this._pt,x ? v : h,f,d,(y ? Wt(d, y + c) : c) - d,!x && (_ === "px" || f === "zIndex") && e.autoRound !== !1 ? fh : li),
                      this._pt.u = _ || 0,
                      m !== _ && _ !== "%" && (this._pt.b = o,
                      this._pt.r = uh);
                  else if (f in h)
                      vh.call(this, t, f, o, y ? y + u : u);
                  else if (f in t)
                      this.add(t, f, o || t[f], y ? y + u : u, s, n);
                  else if (f !== "parseTransform") {
                      gi(f, u);
                      continue
                  }
                  x || (f in h ? E.push(f, 0, h[f]) : E.push(f, 1, o || t[f])),
                  a.push(f)
              }
          }
      T && as(this)
  },
  render: function(t, e) {
      if (e.tween._time || !Mi())
          for (var i = e._pt; i; )
              i.r(t, i.d),
              i = i._next;
      else
          e.styles.revert()
  },
  get: xt,
  aliases: ct,
  getSetter: function(t, e, i) {
      var s = ct[e];
      return s && s.indexOf(",") < 0 && (e = s),
      e in vt && e !== ft && (t._gsap.x || xt(t, "x")) ? i && lr === i ? e === "scale" ? _h : ph : (lr = i || {}) && (e === "scale" ? gh : mh) : t.style && !ci(t.style[e]) ? dh : ~e.indexOf("-") ? ch : Ai(t, e)
  },
  core: {
      _removeProperty: ce,
      _getMatrix: Ci
  }
};
J.utils.checkPrefix = te;
J.core.getStyleSaver = fs;
(function(r, t, e, i) {
  var s = K(r + "," + t + "," + e, function(n) {
      vt[n] = 1
  });
  K(t, function(n) {
      it.units[n] = "deg",
      _s[n] = 1
  }),
  ct[s[13]] = r + "," + t,
  K(i, function(n) {
      var a = n.split(":");
      ct[a[1]] = s[a[0]]
  })
}
)("x,y,z,scale,scaleX,scaleY,xPercent,yPercent", "rotation,rotationX,rotationY,skewX,skewY", "transform,transformOrigin,svgOrigin,force3D,smoothOrigin,transformPerspective", "0:translateX,1:translateY,2:translateZ,8:rotate,8:rotationZ,8:rotateZ,9:rotateX,10:rotateY");
K("x,y,z,top,right,bottom,left,width,height,fontSize,padding,margin,perspective", function(r) {
  it.units[r] = "px"
});
J.registerPlugin(ys);
var Ch = J.registerPlugin(ys) || J;
Ch.core.Tween;
export {Dh as C, gr as G, kh as M, Oh as P, Ph as R, Fh as T, xr as V, Lh as a, Ch as g};
