/* Bootstrap */

// import "bootstrap";
// import { Modal } from 'bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css'
import { Modal } from 'bootstrap';
import { gsap } from "gsap";


/* Custom SCSS */
// import "./index.scss";
// import './style-login.scss';
// import './style-admin.scss';
// import './style-buttons.scss';

/* Swiper */

//
// import Swiper, { Navigation, Pagination, Scrollbar } from "swiper";
//import "swiper/css";
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

// Swiper.use([Scrollbar]);
// init Swiper:

// 
// swiperSlider();

/* Fancybox */

// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox.css";

// var Isotope = require('isotope-layout');

import btinkeeng from "./js/btinkeeng";
btinkeeng();

import animation from "./js/animation";
if ($(".fluid-background").length) {
    animation();
}



//import texthighlight from "./js/texthighlight.js";
//texthighlight();

import navbar from "./js/navbar";
navbar();

import footer from "./js/footer";
footer();

import sidebar from "./js/sidebar";
sidebar();

import search from "./js/search";
search();

// import woocommerce from "./js/woocommerce";
// woocommerce();

import scrollTop from "./js/scrollTop";
scrollTop();
