import { gsap } from "gsap";
import { Swiper } from "swiper/bundle";


const btinkeeng = () => {
  $(() => {
    var lastScroll = 0;

    if($(".enable-white").length>0) {
      $("header").removeClass("black").addClass("white");
    }

    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $.fn.isInViewportCenter = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      // Calcola il centro della viewport
      var viewportCenter = viewportTop + $(window).height() / 2;

      // Controlla se l'elemento è centrato rispetto alla viewport
      return (elementTop <= viewportCenter && elementBottom >= viewportCenter);
    };

    $(window).scroll(function () {
    	$(".header-scroll").removeClass("shift");
       /*setTimeout(function () {
        //gives 100ms to finish scrolling before doing a check
        var scroll = $(window).scrollTop();
		
		
        if(scroll<100) {
        	$(".header-scroll").removeClass("shift");
        }
        else if (scroll > lastScroll) {
          $(".header-scroll").addClass("shift");
        } else if (scroll < lastScroll) {
          $(".header-scroll").removeClass("shift");
        }
        lastScroll = scroll;
      }, 100);*/

      $('.card-container').each(function () {

        if ($(this).isInViewportCenter()) {

          $(this).closest(".bg-section").addClass('animation');
        } else {
          $(this).closest(".bg-section").removeClass('animation');
        }
      });

      $(".card-flip").each(function () {

        if ($(this).isInViewportCenter()) {
          $(this).addClass('flipped');
        }
        else {
          $(this).removeClass('flipped');
        }
      });

    });

    $(".open-menu").click(function (event) {
      $("#menu-open").addClass("open");

    });

    $(".close-menu").click(function (event) {
      $("#menu-open").removeClass("open");

    });

    $(".cover-shadow img").each(function () {
      $(this).closest("section").removeClass("cover-shadow");
      $(this).wrap('<div class="image-wrapper-shadow"></div>');
    });

    $(".cover-white img").each(function () {
      $(this).closest("section").removeClass("cover-white");
      $(this).wrap('<div class="image-wrapper-white"></div>');
    });


    $(".hero .img_background").each(function () {
      var clone = $(this).clone();
      $(clone).addClass("img_background d-block d-md-none");
      $(clone).attr("src", $(clone).attr("src").replace(".jpeg", "_mob.jpeg"));
      $(this).addClass("d-none d-md-block");
      $(this).closest("section").prepend(clone);
    });

    var swiperNews = new Swiper(".mySwiperNews", {
      slidesPerView: "auto",
      spaceBetween: 30,

    });

    var swiperProject = new Swiper(".mySwiperProject", {
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      grid: {
        rows: 2,
        fill: "row",
      },
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          grid: {
            rows: 1,
          },
        },

        768: {
          slidesPerView: "auto",
          grid: {
            rows: 2,
          },
        },
      },
    });


    $(document).ready(function () {
      const $list = document.querySelector('.tickerwrapper .list');
      if ($list != undefined) {
        // Cloniamo la lista per il movimento infinito
        const $clonedList = $list.cloneNode(true);
        $($clonedList).addClass("cloned");

        // Aggiungiamo la lista clonata dopo la lista originale
        $list.parentNode.appendChild($clonedList);

        // Calcoliamo la larghezza della lista
        const listWidth = $list.offsetWidth;

        // Creiamo la timeline GSAP
        let infinite = gsap.timeline({
          repeat: -1,  // Ripete all'infinito
          paused: false // L'animazione parte subito
        });


        const time = 40; // Durata dell'animazione

        infinite
          .fromTo($list, { x: 0 }, { x: -listWidth, ease: "none", duration: time }) // Sposta la lista a sinistra
          .fromTo($clonedList, { x: listWidth }, { x: 0, ease: "none", duration: time }, 0) // Sposta la lista clonata a destra
          .set($list, { x: listWidth })  // Posiziona la lista originale alla fine della lista clonata
          .to($clonedList, { x: -listWidth, ease: "none", duration: time }, time) // Continua a spostare la lista clonata
          .to($list, { x: 0, ease: "none", duration: time }, time); // Continua a spostare la lista originale

        // Avvia la timeline
        infinite.play();

      }
    });

    //Pause/Play		
    /*
    $tickerWrapper.on("mouseenter", function () {
      infinite.pause();
    }).on("mouseleave", function () {
      infinite.play();
    });
    */

    function animateCounter($el, start, end, duration) {
      let startTime = null;

      function updateCounter(timestamp) {
        if (!startTime) startTime = timestamp;
        let progress = Math.min((timestamp - startTime) / duration, 1);
        $el.text(Math.floor(progress * (end - start) + start));

        if (progress < 1) {
          requestAnimationFrame(updateCounter);
        }
      }

      requestAnimationFrame(updateCounter);
    }

    $(".counter").each(function () {
      let $counter = $(this);
      let targetNumber = parseInt($counter.text(), 10);

      let observer = new IntersectionObserver(
        function (entries, observer) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              animateCounter($counter, 0, targetNumber, 2000);
              observer.unobserve(entry.target); // Evita che si riattivi
            }
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(this);
    });



    $(".show-iso a").each(function () {
      $(this).click(function () {
        $(".iso-container").hide();
        var data = $(this).attr("href");
        data = data.replace("#", '');
        $("." + data).show();
        
        setTimeout(function() {var scroll = $("." + data).offset().top;$('html, body').animate({scrollTop: scroll}, 300);}, 50);
        
      });
    });
  });

  if ($("#project-section").length > 0) {
    $("#project-section").find(".card-image").each(function () {
      $(this).click(function () {
        var nome = $("#project-section").data("nome");
        var ruolo = $("#project-section").data("ruolo");
        var immagine = $("#project-section").data('immagine');
        var background = $("#project-section").data("background");
        var titolo = $("#project-section").data("titolo");
        var link = $("#project-section").data("link");
        var bottone = $("#project-section").data("bottone");
        var card = $("#project-section").data("card");
        var descrizione = $("#project-section").data("descrizione");


        $("#project-section").find("#responsabile").html($(this).data("nome") + "<br>" + $(this).data("ruolo"));
        $("#project-section").data("nome", $(this).data("nome"));
        $("#project-section").data("ruolo", $(this).data("ruolo"));
        $("#project-section").find("#immagine_responsabile").attr("src", $(this).data('immagine'));
        $("#project-section").data("immagine", $(this).data("immagine"));
        $("#project-section").css("background-image", "url(" + $(this).data("background") + ")")
        $("#project-section").data("background", $(this).data("background"));
        $("#project-section").find("#titolo").html($(this).data("titolo").replace("-br-","<br>"));
        $("#project-section").data("titolo", $(this).data("titolo"));
        $("#project-section").find("#link").attr("src", $(this).data("link"));
        $("#project-section").data("link", $(this).data("link"));
        $("#project-section").find("#bottone").html($(this).data("bottone"));
        $("#project-section").data("bottone", $(this).data("bottone"));
        $("#project-section").find("#descrizione").html($(this).data("descrizione"));
        $("#project-section").data("descrizione", $(this).data("descrizione"));
        $("#project-section").data("card", $(this).data("card"));


        $(this).data("nome", nome);
        $(this).data("ruolo", ruolo);
        $(this).data('immagine', immagine);
        $(this).data("background", background);
        $(this).data("titolo", titolo);
        $(this).data("link", link);
        $(this).data("bottone", bottone);
        $(this).data("card", card);
        $(this).data("descrizione", descrizione);
        $(this).css("background-image", "url(" + card + ")");
        $(this).find("h4").html(titolo.replace("-br-","<br>"));

      });
    });
  }


  var swiperWork = new Swiper(".mySwiperWork", {
    slidesPerView: "auto",
    spaceBetween: 10,

  });


  var swiper = new Swiper(".mySwiper", {
    slidesPerView: "auto",
    spaceBetween: 30,

  });

  /*
  $(".custom-select").select2({
    placeholder: "Tutte le Unità",
    allowClear: true,
    closeOnSelect: false,
    templateResult: formatCheckbox,
    templateSelection: formatTag,
  });

  function formatCheckbox(option) {
    if (!option.id) return option.text;

    let selectedValues = $("#multi-select").val() || [];
    let isChecked = selectedValues.includes(option.id);
    let checkbox = $('<input type="checkbox" class="select2-checkbox">').prop(
      "checked",
      isChecked
    );

    let label = $(
      '<span class="select2-checkbox-label">' + option.text + "</span>"
    );

    label.on("click", function (e) {
      e.stopPropagation();
      checkbox.prop("checked", !checkbox.prop("checked")).trigger("change");
    });

    let wrapper = $("<span></span>").append(checkbox).append(label);

    if (option.id === "all") {
      wrapper.addClass("all-option"); // Aggiungiamo una classe per differenziare "Tutte le voci"
    }

    return wrapper;
  }

  function formatTag(selection) {
    let selectedValues = $("#multi-select").val() || [];
    if (selectedValues.includes("all")) {
      return $('<span class="selected-tag">Tutte le voci</span>');
    }
    return $('<span class="selected-tag">' + selection.text + "</span>");
  }

  $("#multi-select").on("select2:select", function (e) {
    let selectedValue = e.params.data.id;
    let allOptions = $("#multi-select")
      .find("option:not(:first)")
      .map(function () {
        return this.value;
      })
      .get();

    if (selectedValue === "all") {
      $("#multi-select").val(["all"].concat(allOptions)).trigger("change");

      // Seleziona tutte le checkbox, compresa "Tutte le voci"
      $(".select2-checkbox").prop("checked", true);
    } else {
      let selected = $("#multi-select").val();
      if (selected.includes("all")) {
        selected = selected.filter((value) => value !== "all");
        $("#multi-select").val(selected).trigger("change");
      }
    }
  });

  $("#multi-select").on("select2:unselect", function (e) {
    let unselectedValue = e.params.data.id;
    let selectedValues = $("#multi-select").val() || [];

    if (unselectedValue === "all") {
      $("#multi-select").val([]).trigger("change");
      $(".select2-checkbox").prop("checked", false);
    } else {
      let allOptions = $("#multi-select")
        .find("option:not(:first)")
        .map(function () {
          return this.value;
        })
        .get();
      if (!selectedValues.every((value) => allOptions.includes(value))) {
        $("#multi-select")
          .val(selectedValues.filter((value) => value !== "all"))
          .trigger("change");
        $("#multi-select")
          .find("option[value='all']")
          .prop("selected", false);

        // Deseleziona anche la checkbox di "Tutte le voci"
        $(".all-option .select2-checkbox").prop("checked", false);
      }
    }
  });
  */

  $(".file-input").each(function () {
    $(this).change(function () {
      var filename = $(this).val().replace(/C:\\fakepath\\/i, '');
      $(this).closest(".custom-file").find(".file-name").text(filename);
    });
  });
  $(document).ready(function () {
    $('a[href="#box-candidatura-spontanea"]').click(function (e) {
      e.preventDefault();
      $("#innoviamo-insieme").hide();
      $("#candidatura-spontanea").show();
    });


    $('a[href="#box-richiedi-informazioni"]').click(function (e) {
      e.preventDefault();
      $("#candidatura-spontanea").hide();
      $("#innoviamo-insieme").show();
    });
  });
  
  //onclick="$(this).hide();$(this).next().show();setTimeout(function() {var scroll = $('#sintesi{{ candidatura.id }}').offset().top;$('html, body').animate({scrollTop: scroll}, 300);}, 50);"
  //onclick="$(this).hide();$(this).prev().show();setTimeout(function() {var scroll = $('#accordionJob{{ candidatura.id }}').offset().top;$('html, body').animate({scrollTop: scroll}, 300);}, 50);"
  //onclick="$(this).prev().prev().hide();$(this).prev().show();setTimeout(function() {var scroll = $('#form{{ candidatura.id }}').offset().top-300;$('html, body').animate({scrollTop: scroll}, 300);}, 50);"
  //onclick="$(this).prev().show();setTimeout(function() {var scroll = $('#form{{ candidatura.id }}').offset().top-300;$('html, body').animate({scrollTop: scroll}, 300);}, 50);"
  
  $(".learnmorebig").click(function() {
  	$(this).closest(".container").next().toggle();
  	$(this).hide();
  	$(this).next().show();
  	var collapse = $(this).closest(".container").next();
  	setTimeout(function() {var scroll = $(collapse).find('.sintesi').offset().top-50;
  	$('html, body').animate({scrollTop: scroll}, 300);}, 50);
  });
  
  $(".learnlessbig").click(function() {
	$(this).closest(".container").next().toggle();
  	$(this).hide();
  	$(this).prev().show();
  	var collapse = $(this).closest(".container").next();
  	setTimeout(function() {var scroll = $(collapse).closest(".result-box").offset().top-100;
  	$('html, body').animate({scrollTop: scroll}, 300);}, 50);
  });
  
  $(".applybig").click(function() {
  	$(this).prev().prev().hide();
  	$(this).prev().show();
  	var collapse = $(this).closest(".container").next();
  	if($(collapse).css('display') == 'none') {
  		$(collapse).show();
  	}
  	var that = this;
  	setTimeout(function() {var scroll = $(collapse).find('.form-container').offset().top-150;$('html, body').animate({scrollTop: scroll}, 300);}, 50);
  });
   $(".applysmall").click(function() {
  	var collapse = $(this).closest(".container").next();
  	if($(collapse).css('display') == 'none') {
  		$(collapse).show();
  	}
  	var that = this;
  	setTimeout(function() {var scroll = $(collapse).find('.form-container').offset().top-300;$('html, body').animate({scrollTop: scroll}, 300);}, 50);
  });
  
  $(".learnlesssmall").click(function() {
	  var container = $(this).closest(".collapse").prev();
	  setTimeout(function() {var scroll = $(container).offset().top-50;
	$('html, body').animate({scrollTop: scroll}, 300);}, 50);
  	$(this).closest(".collapse").prev().find(".learnlessbig").hide();
  	$(this).closest(".collapse").prev().find(".learnmorebig").show();
  	$(this).closest(".collapse").toggle();
  	
  });
  
  $(".copyToClipboard").click(function() {
  	  var link = window.location.href+"#"+$(this).data("link");
  	  navigator.clipboard.writeText(link);
	  alert($(this).data("text"));
  });
  
  $(document).ready(function() {
    var urlHash = window.location.hash; // Ottieni l'hash dalla URL
    if (urlHash.indexOf('#accordionJob') !== -1) { // Controlla se l'hash contiene "#accordionJob"
        $(""+urlHash).find(".collapse").toggle();
    }
});

$(document).ready(function() {
    // Osserva l'aggiunta della classe 'wpcf7-not-valid' all'elemento con la classe 'wpcf7-form-control'
    $('input.wpcf7-form-control').on('classChange', function() {

        // Se l'input ha la classe 'wpcf7-not-valid', aggiungi un bordo rosso al genitore
        if ($(this).hasClass('wpcf7-not-valid')) {
            $(this).closest('.form-group').css('border', '1px solid red');
        } else {
            // Rimuovi il bordo se la classe non è presente
            $(this).closest('.form-group').css('border', '');
        }
    });
    
    $('textarea.wpcf7-form-control').on('classChange', function() {

        // Se l'input ha la classe 'wpcf7-not-valid', aggiungi un bordo rosso al genitore
        if ($(this).hasClass('wpcf7-not-valid')) {
            $(this).closest('.form-group').css('border', '1px solid red');
        } else {
            // Rimuovi il bordo se la classe non è presente
            $(this).closest('.form-group').css('border', '');
        }
    });

    // Usa il MutationObserver per monitorare i cambiamenti della classe in tempo reale
    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if ($(mutation.target).hasClass('wpcf7-not-valid')) {
                $(mutation.target).closest('.form-group').css('border', '1px solid red');
            } else {
                $(mutation.target).closest('.form-group').css('border', '');
            }
        });
    });

    // Impostiamo l'osservatore per monitorare i cambiamenti nelle classi degli input
    $('input.wpcf7-form-control').each(function() {
        observer.observe(this, { attributes: true });
    });
    
    $('textarea.wpcf7-form-control').each(function() {
        observer.observe(this, { attributes: true });
    });
});
  

};

export default btinkeeng;
